import { AppointmentTypeString, EmrApi, EmrLunaApi, ShipmentResult } from '@shared/types'
import { createContext, useContext } from 'react'
import { UseQueryResult } from 'react-query'
import { Visit } from '../../api/types'

export type JourneyItemType =
  | 'appointment'
  | 'case-review-note'
  | 'non-visit-event'
  | 'patient-status'
  | 'prescription'
  | 'task'
  | 'drug-screen'
  | 'ineligible-note'
  | 'discharge-note'
  | 'uds-cup'
  | 'visit-hold'

export type JourneyItemContent<T extends JourneyItemType = JourneyItemType> =
  T extends 'appointment'
    ? Visit
    : T extends 'non-visit-event'
    ? EmrApi['GET /patient/:patientId/nonVisitEvents']['res'][number]
    : T extends 'case-review-note'
    ? EmrApi['GET /caseReviewNote/patient/:patientId']['res'][number]
    : T extends 'task'
    ? EmrApi['GET /patient/:patientId/issues']['res'][number]
    : T extends 'prescription'
    ? EmrApi['GET /patient/:patientId/prescriptions']['res'][number]
    : T extends 'ineligible-note'
    ? EmrApi['GET /ineligibleNotes']['res'][number]
    : T extends 'discharge-note'
    ? EmrApi['GET /dischargeNotes']['res'][number]
    : T extends 'drug-screen'
    ? EmrApi['GET /patient/:patientId/drugScreens']['res'][number]
    : T extends 'uds-cup'
    ? ShipmentResult
    : T extends 'visit-hold'
    ? EmrLunaApi['GET /scheduling/patients/:patientId/holds']['res']['data'][number]
    : EmrApi['GET /patient/:patientId/historicalChanges']['res'][number]

export const ACTIONS_MODALS = [
  'add-non-visit-event',
  'add-prior-authorization',
  'schedule-visit',
  'add-ineligible-note',
  'add-reengagement-note',
  'record-drug-screen',
  'upload-file',
  'delay-subscription',
  'insurance-allowlist-remove',
  'insurance-allowlist-add',
  'open-patient-app-iframe',
  'set-pharmacy',
  'create-dosespot-patient',
  'add-to-insurance-queue',
  'time-tracking',
  'add-drug-screen',
  'order-uds-cup',
  'create-payment-plan',
  'care-pathway-modal',
  'send-patient-referral-link',
  'create-call-me-now-welcome-call',
  'export-visit-notes',
  'delay-scheduling',
  'schedule-visit-hold',
  'level-of-care',
] as const

export type ActionModal = (typeof ACTIONS_MODALS)[number]

export type SchedulingFlowVisitTypes =
  | 'Follow-Up Visit'
  | 'Check-In Call'
  | 'UDS Visit'
  | ['Check-In Call', 'UDS Visit']
  | null

export type SchedulingFlowModalType = {
  type: 'scheduling-flow'
  visitType: SchedulingFlowVisitTypes
}

export type CalendarModalType = {
  type: 'schedule-visit-calendar'
  props?: {
    visitTypesAllowed?: AppointmentTypeString[]
  }
}

export type ModalStateType =
  | { type: ActionModal; props?: undefined }
  | SchedulingFlowModalType
  | CalendarModalType
  | null

export type PatientContextType = {
  setModal: (modalState: ModalStateType) => void
  modal: ModalStateType
  primaryClinicianQuery: UseQueryResult<EmrApi['GET /employee/:employeeId']['res']> | null
  patientQuery: UseQueryResult<EmrApi['GET /patient/:patientId']['res']> | null
  patientImagesQuery: UseQueryResult<
    EmrApi['GET /patient/:patientId/identification-images']['res']
  > | null
  daysSinceLastVisitWithPcQuery: UseQueryResult<
    EmrApi['GET /patient/:patientId/days-since-last-visit/:employeeId']['res']
  > | null
  problemsListQuery: UseQueryResult<EmrApi['GET /patient/:patientId/problemsList']['res']> | null
  // `patientID` is the legacy casing for the patient ID.
  patientID: string
  // `patientId` is the correct casing for the patient ID.
  patientId: string
}

const initialContext: PatientContextType = {
  modal: null,
  setModal: () => void 0,
  patientQuery: null,
  patientImagesQuery: null,
  problemsListQuery: null,
  daysSinceLastVisitWithPcQuery: null,
  primaryClinicianQuery: null,
  patientID: '',
  patientId: '',
}

export const PatientContext = createContext<PatientContextType>(initialContext)

export const usePatient = () => {
  return useContext(PatientContext)
}
