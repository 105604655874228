import {
  Center,
  FolderIcon,
  Group,
  LayoutIcon,
  Loader,
  Modal,
  PrimaryButton,
  SecondaryButton,
  StarIcon,
  Tabs,
  useMantineTheme,
} from '@shared/components'
import { PrescriptionFavorite, PrescriptionResponse } from '@shared/types'
import { sortBy } from '@shared/utils'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { emrApi, prescriptionFavoritesApi } from '../../../../api'
import { useAuth } from '../../../../context/auth'
import { buildSearchString } from '../../../../utils/prescriptionUtils'
import FavoritesTabContent from './FavoritesTabContent'
import OpheliaTemplatesTabContent from './OpheliaTemplatesTabContent'
import PatientRecentsTabContent, { PrescriptionRecent } from './PatientRecentsTabContent'

export type QuickApplyPopoverContentProps = {
  patientId: string
  onTemplateSelected: (template: PrescriptionFavorite) => void
  editFavoriteOnClick: (template: PrescriptionFavorite) => void
}

const convertPrescriptionResponseToPrescriptionRecent = (
  prescription: PrescriptionResponse,
): PrescriptionRecent => {
  let formattedMedicationName = prescription.medication_name
  if (
    prescription.medication_dose_form &&
    prescription.medication_strength &&
    prescription.medication_route
  ) {
    formattedMedicationName = buildSearchString({
      displayName: prescription.medication_name,
      strength: prescription.medication_strength,
      route: prescription.medication_route,
    })
  }
  return {
    oid: String(prescription.prescription_id),
    timestamps: prescription.timestamps || {
      createdAt: Date.now(),
      updatedAt: Date.now(),
    },
    medicationName: formattedMedicationName,
    favoriteName: prescription.medication_name,
    doseForm: prescription.medication_dose_form ?? 'unit',
    strength: prescription.medication_strength,
    quantity: prescription.medication_quantity
      ? Number(prescription.medication_quantity)
      : undefined,
    daysSupply: prescription.medication_days_supply
      ? Number(prescription.medication_days_supply)
      : undefined,
    refills: prescription.refills ? Number(prescription.refills) : undefined,
    directions: prescription.directions,
    pharmacyNotes: prescription.pharmacyNotes,
    substitutionAllowed: prescription.substitutionAllowed,
    type: 'clinician',
    writtenDate: prescription.writtenDate ? new Date(prescription.writtenDate) : undefined,
    // @dosespotV2Migration
    dispensableDrugId: prescription.dispensableDrugId,
  }
}

const QuickApplyPopoverContent = ({
  patientId,
  onTemplateSelected,
  editFavoriteOnClick,
}: QuickApplyPopoverContentProps) => {
  const { currentUser } = useAuth()
  const {
    other: { sizes },
  } = useMantineTheme()
  const clinicianFavorites = useQuery(
    ['prescriptionFavoritesApi.get', 'clinician', currentUser.oid],
    () => prescriptionFavoritesApi.get({ employeeId: currentUser.oid, type: 'clinician' }),
  )
  const opheliaTemplates = useQuery(['prescriptionFavoritesApi.getByType', 'ophelia'], () =>
    prescriptionFavoritesApi.get({ type: 'ophelia' }),
  )

  type TabTitle = 'recents' | 'favorites' | 'templates'
  const [activeTab, setActiveTab] = useState<TabTitle | null>()

  const opheliaTemplatesData = opheliaTemplates.data || []

  const patientRecents = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/prescriptions', {
      params: { patientId },
      query: { doseSpotOnly: 'true' },
    }),
  )

  const deletePrescriptionFavorite = useMutation(prescriptionFavoritesApi.delete)

  const [favoriteToDelete, setFavoriteToDelete] = useState<string>('')

  const onCloseDeleteModal = () => {
    setFavoriteToDelete('')
  }

  const onDeleteConfirmation = async () => {
    await deletePrescriptionFavorite.mutateAsync(favoriteToDelete)
    void clinicianFavorites.refetch()
    onCloseDeleteModal()
  }

  const onClickTab = (value: TabTitle) => {
    setActiveTab(value === activeTab ? null : value)
  }

  return (
    <>
      <Modal
        opened={Boolean(favoriteToDelete)}
        onClose={onCloseDeleteModal}
        title='Delete favorite'
        footer={
          <Group position='right'>
            <SecondaryButton onClick={onCloseDeleteModal}>No, don&apos;t delete</SecondaryButton>
            <PrimaryButton onClick={onDeleteConfirmation}>Yes, delete</PrimaryButton>
          </Group>
        }
      >
        By deleting this favorite, you will no longer be able to access it from your
        &apos;Favorites&apos; menu. Are you sure you want to proceed?
      </Modal>
      <Tabs value={activeTab} onTabChange={(value: TabTitle) => onClickTab(value)}>
        <Tabs.List grow>
          <Tabs.Tab value='recents' icon={<FolderIcon strokeWidth={sizes.border.md} />}>
            Patient recents
          </Tabs.Tab>
          <Tabs.Tab value='favorites' icon={<LayoutIcon strokeWidth={sizes.border.md} />}>
            Favorites
          </Tabs.Tab>
          <Tabs.Tab value='templates' icon={<StarIcon strokeWidth={sizes.border.md} />}>
            Templates
          </Tabs.Tab>
        </Tabs.List>
        {activeTab === 'recents' && (
          <Tabs.Panel value='recents' pt='xs'>
            {patientRecents.isLoading || patientRecents.isFetching ? (
              <Center
                sx={theme => ({
                  paddingTop: theme.spacing.lg,
                  paddingBottom: theme.spacing.lg,
                  border: theme.other.colors.actions[0],
                })}
              >
                <Loader />
              </Center>
            ) : (
              <PatientRecentsTabContent
                recentPrescriptionsList={
                  patientRecents.data
                    ? patientRecents.data
                        .map((item: PrescriptionResponse) =>
                          convertPrescriptionResponseToPrescriptionRecent(item),
                        )
                        .sort(sortBy({ key: 'writtenDate', order: 'DESC' }))
                    : []
                }
                recentOnClick={recent => {
                  onTemplateSelected(recent)
                  setActiveTab(null)
                }}
              />
            )}
          </Tabs.Panel>
        )}
        {activeTab === 'favorites' && (
          <Tabs.Panel value='favorites' pt='xs'>
            {clinicianFavorites.isLoading ? (
              <Center
                sx={theme => ({
                  paddingTop: theme.spacing.lg,
                  paddingBottom: theme.spacing.lg,
                  border: theme.other.colors.actions[0],
                })}
              >
                <Loader />
              </Center>
            ) : (
              <FavoritesTabContent
                favoritesList={clinicianFavorites.data}
                favoriteOnClick={fav => {
                  onTemplateSelected(fav)
                  setActiveTab(null)
                }}
                deleteOnClick={fav => {
                  setFavoriteToDelete(fav.oid)
                }}
                editOnClick={editFavoriteOnClick}
              />
            )}
          </Tabs.Panel>
        )}
        {activeTab === 'templates' && (
          <Tabs.Panel value='templates' pt='xs'>
            {opheliaTemplates.isLoading ? (
              <Center
                sx={theme => ({
                  paddingTop: theme.spacing.lg,
                  paddingBottom: theme.spacing.lg,
                })}
              >
                <Loader />
              </Center>
            ) : (
              <OpheliaTemplatesTabContent
                opheliaTemplatesList={opheliaTemplatesData}
                templateOnClick={template => {
                  onTemplateSelected(template)
                  setActiveTab(null)
                }}
              />
            )}
          </Tabs.Panel>
        )}
      </Tabs>
    </>
  )
}

export default QuickApplyPopoverContent
