import { Payer } from '@shared/types'
import { sortBy, toTime } from '@shared/utils'
import cn from 'classnames'
import { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form-latest'
import { useMutation, useQuery } from 'react-query'
import { insuranceApi } from '../../../api'
import ALargeLoadingSpinner from '../../../components/atoms/ALargeLoadingSpinner'
import ATypeahead from '../../../components/atoms/ATypeahead'
import ODSPrimaryButton from '../../../components/ods/ODSPrimaryButton'
import { Page } from '../../../components/templates/TDefault'
import { useAuthForAdminPages, useLunaQuery } from '../../../utils/hooks'
import BillingHeaderContent from '../BillingHeaderContent'

type PayerId = string
type Payers = Record<PayerId, boolean>

type FormData = {
  payers: Payers
}

// A pretty, sensible format to show users in the typeahead
const formatEligiblePayerName = (payer: Payer) => `${payer.name} (${payer.payerId})`

// "Healthy Blue (00661)" -> "00661"
const getEligibleIdFromFormattedName = (payer: string): string => {
  const id = payer
    .split(/\(([^)]+)\)/)
    .filter(Boolean)
    .pop() as string

  return id || 'CANNOT PARSE ID'
}

const BillingInsuranceMatching = () => {
  useAuthForAdminPages()

  const { register, handleSubmit, reset } = useForm<FormData>()
  const [selectedEligiblePayer, setSelectedEligiblePayer] = useState('')

  const eligiblePayers = useLunaQuery('GET /insurance-payers', {
    staleTime: toTime('5 min').ms(),
  })

  const eligiblePayerNames = eligiblePayers.data?.data?.map(formatEligiblePayerName)

  const verifiablePayers = useQuery(
    ['insuranceApi.listVerifiablePayers'],
    insuranceApi.listVerifiablePayers,
    {
      onSuccess: data => {
        const eligibleId = getEligibleIdFromFormattedName(selectedEligiblePayer)
        const initialValue = {} as Record<PayerId, boolean>
        const initialPayersFormData = data.reduce((acc, verifiablePayer) => {
          const hasSelectedPayer = Boolean(verifiablePayer.eligibleIds?.includes(eligibleId))
          acc[verifiablePayer.oid] = hasSelectedPayer
          return acc
        }, initialValue)
        reset({ payers: initialPayersFormData })
      },
    },
  )
  const verifiablePayersData = (verifiablePayers.data || []).sort(sortBy({ key: 'displayName' }))

  const updateVerifiablePayer = useMutation(insuranceApi.updateVerifiablePayer, {
    onSuccess: () => {
      setSelectedEligiblePayer('')
      void verifiablePayers.refetch()
    },
  })

  const onSubmit = handleSubmit(async formData => {
    const eligibleId = getEligibleIdFromFormattedName(selectedEligiblePayer)

    const verifiablePayersToUpdate = verifiablePayersData
      .filter(verifiablePayer => {
        const prevState = Boolean(verifiablePayer.eligibleIds?.includes(eligibleId))
        const currState = formData.payers[verifiablePayer.oid]
        return prevState !== currState
      })
      .map(verifiablePayer => {
        const eligibleIds = verifiablePayer.eligibleIds || []
        return updateVerifiablePayer.mutateAsync({
          id: verifiablePayer.oid,
          eligibleIds: eligibleIds.includes(eligibleId)
            ? eligibleIds.filter(id => id !== eligibleId)
            : eligibleIds.concat(eligibleId),
        })
      })

    await Promise.all(verifiablePayersToUpdate)
  })

  return (
    <Page
      title='Billing'
      headerContent={<BillingHeaderContent selectedPage='Insurance matching' />}
      pushHeaderContent={false}
    >
      {eligiblePayers.isLoading || verifiablePayers.isLoading ? (
        <div className='flex-grow-0 flex-shrink flex'>
          <div className='overflow-x-auto scrolling-touch min-w-full'>
            <div className='flex w-full'>
              <div className='flex items-center justify-center h-96 w-full'>
                <ALargeLoadingSpinner />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Fragment>
          <div className='max-w-5xl mx-auto'>
            <form onSubmit={onSubmit}>
              <div className='relative pt-20 mb-4'>
                <div className='relative flex items-center justify-between'>
                  <span className='text-lg font-medium text-gray-900 flex-shrink-0'>
                    Match Payers
                  </span>
                  <div className='w-full flex items-center mx-3' aria-hidden='true'>
                    <div className='border-t border-gray-300 w-full' />
                  </div>
                </div>
                <p className='text-gray-800'>Search for an Eligible payer</p>
              </div>
              <div className='flex flex-col'>
                <ATypeahead
                  /*
                   * When the key changes, it forces the component to re-render.
                   * This is how we force the typeahead to clear itself after a successful submission.
                   */
                  key={`typeahead-${selectedEligiblePayer}`}
                  name='payer'
                  register={() => ({})}
                  defaultValue={selectedEligiblePayer}
                  selectedValue={selectedEligiblePayer}
                  values={eligiblePayerNames}
                  setSelectedValue={(payerName: string) => {
                    void verifiablePayers.refetch()
                    setSelectedEligiblePayer(payerName)
                  }}
                  className='w-full'
                  disabled={updateVerifiablePayer.isLoading}
                />
                <p className='font-medium text-gray-800 py-4'>
                  Check the matching Verifiable payer(s)
                </p>
                <div className={cn('grid', 'gap-x-4', 'grid-cols-3')} key={selectedEligiblePayer}>
                  {verifiablePayersData.map(verifiablePayer => {
                    const { eligibleIds = [], oid } = verifiablePayer
                    const isDisabled =
                      !selectedEligiblePayer ||
                      updateVerifiablePayer.isLoading ||
                      verifiablePayers.isLoading

                    return (
                      <div
                        key={`${selectedEligiblePayer}-${oid}`}
                        className={cn('flex', 'items-start', 'mb-1', { 'opacity-75': isDisabled })}
                      >
                        <div className='flex items-center h-5'>
                          <input
                            {...register(`payers.${oid}`)}
                            id={oid}
                            disabled={isDisabled}
                            type='checkbox'
                            defaultChecked={
                              !isDisabled && eligibleIds?.includes(selectedEligiblePayer)
                            }
                            className='focus:ring-indigo-600 h-4 w-4 text-indigo-600 border-gray-300 rounded'
                          />
                        </div>
                        <div className={cn('ml-3', 'leading-5')}>
                          <label htmlFor={oid} className={cn('text-gray-700')}>
                            {verifiablePayer.displayName} ({verifiablePayer.state})
                          </label>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className='pt-6'>
                  <ODSPrimaryButton
                    type='submit'
                    message='Match Payers'
                    className='inline-flex justify-center flex-shrink-0'
                    disabled={!selectedEligiblePayer}
                    loading={updateVerifiablePayer.isLoading}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className='max-w-5xl mx-auto'>
            <div className='relative mt-8'>
              <div className='relative flex items-center justify-between'>
                <span className='pr-3 text-lg font-medium text-gray-900 flex-shrink-0'>
                  Matched Payers
                </span>
                <div className='w-full flex items-center' aria-hidden='true'>
                  <div className='border-t border-gray-300 w-full' />
                </div>
              </div>
              <div className='relative flex items-center w-2/3'>
                <p className='text-gray-800'>
                  All Verifiable payers with matched Eligible payer IDs
                </p>
              </div>
            </div>
            <ul
              className={cn(
                'relative',
                'z-0',
                'divide-y',
                'divide-gray-200',
                'border-b',
                'border-gray-200',
                'bg-white',
                'rounded-md',
                'shadow-md',
                'my-6',
              )}
            >
              {verifiablePayersData.map(verifiablePayer => {
                const eligibleIds = (verifiablePayer.eligibleIds || []).sort(
                  sortBy({ order: 'ASC' }),
                )
                return (
                  <li
                    key={verifiablePayer.oid}
                    className={cn('relative', 'pl-4', 'pr-6', 'py-5', 'sm:py-6', 'sm:pl-6')}
                  >
                    <p className={cn('text-sm', 'text-indigo-800', 'font-semibold')}>
                      {verifiablePayer.displayName} ({verifiablePayer.state})
                    </p>
                    {eligibleIds.length === 0 ? (
                      <p className='text-gray-800 text-xs mt-1'>No matches.</p>
                    ) : (
                      <ul>
                        {eligibleIds.map(id => (
                          <li key={id}>{id}</li>
                        ))}
                      </ul>
                    )}
                  </li>
                )
              })}
            </ul>
          </div>
        </Fragment>
      )}
    </Page>
  )
}

export default BillingInsuranceMatching
