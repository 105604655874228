import { SmartPhrase } from '@shared/components'
import { EmrLunaApi } from '@shared/types'
import capitalize from 'lodash/capitalize'

export type SmsStatus =
  EmrLunaApi['GET /communications/sms']['res']['data']['messages'][number]['status']
export type PhoneCallStatus =
  EmrLunaApi['GET /communications/calls']['res']['data']['calls'][number]['status']
export type CommunicationsStatus = SmsStatus | PhoneCallStatus

export const getTwilioUrl = (url: string) => {
  return `https://api.twilio.com${url.replace('.json', '')}`
}

export const getTwilioStatusText = (status: CommunicationsStatus) => {
  return capitalize(status.replaceAll('-', ' '))
}

export const twilioShortcuts: SmartPhrase[] = [
  {
    id: '#195_or_less',
    label: '$195 or less',
    text: `Hey ________, this is ________ with Ophelia. Our policy for patients with outstanding balances who are looking to re-enroll is to successfully collect the amount owed before we get you booked. Your total amount due is $(_FULL AMT_). This payment can be made in your patient portal by following this link→ https://my.ophelia.com/welcome/signin. Let me know if you have any questions.`,
  },
  {
    id: '#195_to_390',
    label: 'Between $195 and $390',
    text: `Hey _________, this is _______ with Ophelia. Our policy for patients with outstanding balances who are looking to re-enroll is to successfully collect a payment of $195 before we get you booked. The remainder of your balance will be collected in a payment plan once you are back in treatment. After you make the payment of $195, the remainder amount due will be $(_AMT -$195_). This payment can be made in your patient portal with this link→ https://my.ophelia.com/welcome/signin. Let me know if you have any questions!`,
  },
  {
    id: '#390_and_over',
    label: '$390 and over',
    text: `Hey _______, this is ______ with Ophelia. Our policy for patients with outstanding balances who are looking to re-enroll is to successfully collect half of the amount owed before we get you booked, and the other half of the balance will be collected in a payment plan once you are back in treatment. Your total amount due is $(_TOTAL AMT). A payment of $(_HALF AMT_) will need to be collected before we get you booked again. Whenever you're ready and able to make a payment, please let us know so that we can run the charge over the phone with you or through text. Let me know if you have any questions!`,
  },
  {
    id: '#911',
    label: '911',
    text: `If this is an emergency, please call 911 or go to your nearest emergency room.`,
  },
  {
    id: '#about_ophelia',
    label: 'About Ophelia',
    text: `Ophelia is a telehealth option for treating opioid-use disorder with medication, typically Suboxone, to help with cravings and withdrawals. Before you are prescribed medication, we have you meet with one of our clinicians who will be with you throughout treatment to help with the recovery process. If you'd like more information, please schedule a welcome call with us here: https://my.ophelia.com/welcome`,
  },
  {
    id: '#abp_discussion',
    label: 'ABP discussion',
    text: `The patient agreed to pay off their ABP upon reentering treatment. The patient understands that a Financial Counselor will reach out to coordinate signature an ABP agreement once they reenter treatment.`,
  },
  {
    id: '#account_recovery',
    label: 'Account recovery',
    text: `Hi! If you are, or have been, a patient with us and you'd like to recover your Ophelia account, you can use the following link. Please log in using the email associated with your personal Ophelia account, as well as update your phone number. Let us know if you have any questions! https://my.ophelia.com/account-recovery-request`,
  },
  {
    id: '#account_recovery_link',
    label: 'Account recovery link',
    text: `https://my.ophelia.com/account-recovery-request`,
  },
  {
    id: '#asynchronous_uds',
    label: 'Asynchronous Urine Drug Screen',
    text: `Your clinician requests that you please conduct a Urine Drug Screen today. You may send in your results any time before 8 pm. Here are the instructions:
    1. Remove the lid to the cup and put as much urine in the cup as you can. Place the lid back on the cup.
    2. Immediately take a picture of the black strip showing the temperature. It should read between 90-100°F.
    3. After 5 minutes take pictures of all four sides of the cup.
    4. Text the 5 photos to us at this number. Please let us know if you have any questions!`,
  },
  {
    id: '#billing_date',
    label: 'Billing date',
    text: `Your next billing date is [insert billing date] in the amount of [insert amount]. If you need to update your payment information, you can do so in the Patient Portal: https://my.ophelia.com/portal/.`,
  },
  {
    id: '#blocking_spam',
    label: 'Blocking spam',
    text: `Hey there, if you didn't receive a call from Ophelia can you please try adding Ophelia's phone number to your contacts? Some phones have a spam blocker set up which may be preventing our calls from getting through. Please let us know if you need any help or have any questions!`,
  },
  {
    id: '#bridge_script_policy',
    label: 'Bridge script policy',
    text: `Remember, a bridge prescription is used in rare circumstances if, for whatever reason, you run out of medication before your next appointment with your provider. You will only receive medication for the number of days until we can schedule you to meet with your provider (always less than 7 days) and your prescription will be fulfilled within 24 hours of request. It's your responsibility to keep track of your medication and refill needs.`,
  },
  {
    id: '#bridge_script_sent',
    label: 'Bridge script sent',
    text: `Your bridge script was approved by your clinician and will be sent into your pharmacy by the end of the day. I recommend calling the pharmacy before you head over to ensure that your prescription has been filled and is available for pick-up. Please keep in mind that bridge scripts must be kept infrequent, so please discuss this with your clinician at your next visit.`,
  },
  {
    id: '#ccm_late_show',
    label: 'CCM late show',
    text: `Your appointment has started, and I am in Zoom. Please let me know if you are having issues with the link. I will be on for 10 more minutes and then will need to reschedule your visit.`,
  },
  {
    id: '#ccm_late_uds',
    label: 'CCM late UDS',
    text: `Your urine drug screen appointment has started, and I am in Zoom. I will wait for 5 minutes before we will need to reschedule your appointment.`,
  },
  {
    id: '#clinician_callout',
    label: 'Clinician callout',
    text: `Your clinician will not be in the office today due to a last minute emergency, I'm very sorry for the inconvenience. Please do not attend your appointment, someone will be in touch to reschedule your visit shortly. In order to make sure you have enough medication until we can connect you with your clinician, please count what you have now and be ready to let us know. Our on call provider is able to provide you medication in the interim.`,
  },
  {
    id: '#confirm_deny',
    label: 'Confirm deny',
    text: `Thanks for reaching out! Unfortunately, we don't have this phone number on file, and we can't confirm or deny whether someone is in treatment with us to anyone but the patient. To confirm identity, patients can message their full name, date of birth, and home address, or confirm their information over the phone.`,
  },
  {
    id: '#crisis_escalation',
    label: 'Crisis escalation',
    text: 'We understand accessing treatment can be difficult, however, Ophelia is not a crisis line. If you are in a crisis please contact the crisis hotline by texting 988 and/or call 911. If this is a medical emergency, please seek immediate medical attention at your local emergency room and/or call 911.',
  },
  {
    id: '#edit_timezone',
    label: 'Edit timezone',
    text: `You are able to edit your timezone on file through your patient portal!
    1. Go to https://my.ophelia.com/welcome/signin
    2. Click on "account" under your name in the top left corner
    3. Scroll down for the timezone dropdown where you can make the edit Thank you!`,
  },
  {
    id: '#enrollment_follow_up',
    label: 'Enrollment follow-up',
    text: `Hey ______, this is _______ with Ophelia Health. We recently spoke on the phone and I was wondering if you wanted me to book another appointment for you. I'd be happy to answer any questions you might have, or talk more if you need help with anything else. Would love to get you started! Thank you! Take care.`,
  },
  {
    id: '#external_roi_review',
    label: 'External ROI review',
    text: `Hi there, this is the Ophelia Care Coordination team. Thank you for sending over your Release of Information. We are writing to acknowledge that we received this document and to inform that it is currently under review by our Compliance department. We will be back in touch within 24 hours of your request.`,
  },
  {
    id: '#f*ck_rehab',
    label: 'F*ck rehab',
    text: `Thank you for reaching out. We understand that people might have strong feelings about our ads, but we think it is important to be honest about the problems with rehab without MAT support. In reality, 2 out of 3 rehabs in America dont prescribe life-saving medication and studies show that once released from rehab, 90% of people who do not receive medication relapse within 3 months. We cannot pretend that rehab works for most people, because it doesn't. I would encourage you to check out our website here to learn more. https://ophelia.com/rehab-relapse`,
  },
  {
    id: '#family_engagement',
    label: 'Family engagement',
    text: `Hi! Due to our privacy policy, we cannot give out any information about a patient without a signed release of information from them. You can advise them to contact us and we are happy to proceed from there! Please let us know if you have any other questions!`,
  },
  {
    id: '#fax',
    label: 'Fax',
    text: `Here is our secure Fax number: (267) 780-7032`,
  },
  {
    id: '#fc_escalation',
    label: 'Financial counselor escalation',
    text: 'Thanks for reaching out about this. We are going to pass your inquiry along to our Financial Counselor who will follow-up with you directly as soon as they are available!',
  },
  {
    id: '#financial_agreement_message',
    label: 'Financial agreement message',
    text: `Hi _____ this is _____ with Ophelia! Thank you so much for entering your insurance information. We've verified your coverage and determined your plan is in-network, so all we need is for you to sign your Financial Agreement form. You can do this by logging in to your patient portal using the link below and completing the outstanding task at: https://my.ophelia.com/welcome/signin

    Please note: all patients whether or not they have insurance have to put a credit or debit card on file, but you are not charged anything at that time. If your insurance plan has any copays or deductibles, then we'll send you an invoice before charging the card. You can always call your insurance company directly to verify your benefits. Please let us know if you have any other questions!`,
  },
  {
    id: '#first_steps',
    label: 'First steps',
    text: `The first step is to go to https://ophelia.com and click "Am I a Candidate". This will allow you to begin setting up a profile with Ophelia and schedule a free Welcome Call with an Enrollment Coordinator. Once you have that call with the Enrollment Team, they can help you get scheduled for your first visit with one of our clinicians. Let me know if you have any questions. Thank you!`,
  },
  {
    id: '#formulary',
    label: 'Formulary',
    text: `We mainly prescribe buprenorphine-naloxone, either the brand name Suboxone or generic versions. We only prescribe Subutex to those with confirmed naloxone allergies (tested through a clinic or primary care doctor with documentation), or pregnant women. Our clinicians also prescribe comfort meds to help with anxiety, nausea, and insomnia during induction.`,
  },
  {
    id: '#getting_started',
    label: 'Getting started',
    text: `Hi! This is _____ with Ophelia.
    Here is some more information about us to get you started:

    Ophelia is a Telehealth option for treating opioid-use disorder with medication, typically Suboxone to help with cravings and withdrawals. Before you are prescribed medication, we have you meet with one of our clinicians who will be with you throughout treatment to help with your recovery process. By doing things virtually, we can bypass a lot of the traditional barriers with addiction treatment.

    The cost of Ophelia Health is $195 per month, but that is one flat fee that covers all your visits and Care Coordination support for the whole month, sort of like a monthly subscription fee. We are currently only in-network with several health insurance companies, but we are working hard to be connected with more in the near future. You will only be charged if you decide to enroll in Ophelia after your first visit with your clinician, so if during that visit you decide you don't want to sign up, you won't be charged anything. The cost of medication is typically covered by insurance.

    Please schedule a free Welcome Call for more information here:
    https://my.ophelia.com/welcome

    Please let me know if you have any questions!"`,
  },
  {
    id: '#good_rx',
    label: 'Good Rx',
    text: `Are you familiar with the prescription assistance program called Good Rx? A lot of our patients find that this program helps reduce the cost of their medication significantly: https://www.goodrx.com/buprenorphine-naloxone`,
  },
  {
    id: '#google_voice',
    label: 'Google voice',
    text: `All patients in treatment with Ophelia need to have a unique phone number associated with their profile. We suggest using Google Voice for setting up a unique number. It's free to use Google Voice, all you will need is a Google account/email and a smartphone. Once set up is complete, you can receive calls and text messages to the Google Voice App.`,
  },
  {
    id: '#how_can_i_help',
    label: 'How can I help you?',
    text: `Hey! This is ______ with Ophelia, how can I help you today?`,
  },
  {
    id: '#human_response',
    label: 'Human response',
    text: `Hey, this is _____ with Ophelia. Did you have any specific questions or anything I can help you with? If you are looking for more information about our program, please visit our website at https://ophelia.com and click “Am I a Candidate” to get started!`,
  },
  {
    id: '#id_upload',
    label: 'ID upload',
    text: `Would you please text us in photos of the front and back of your photo ID? We can get them uploaded to your profile for you! Thank you!`,
  },
  {
    id: '#inactive_insurance',
    label: 'Inactive insurance',
    text: `Hey _________, this is _______ with Ophelia. I just reviewed your insurance coverage, and unfortunately your plan is currently Inactive. Are your benefits possibly activating on an upcoming date? Do you have any other insurance plans?If not, I encourage you to contact your insurance provider as soon as you can and possibly get re-enrolled. The phone number for member/customer services will be on your card, likely the back. Please let us know what you find out! Thanks.`,
  },
  {
    id: '#induction_checkin',
    label: 'Induction check-in',
    text: `That's great news, we're so glad to hear that you're feeling well. Please never hesitate to reach out between visits, our Care Coordination team is here to support you in any way we can!`,
  },
  {
    id: '#insurance_info',
    label: 'Insurance info',
    text: `Here is a link where you can check out all of the insurance plans that we accept: https://ophelia.com/insurance-and-pricing Please let us know if you have any questions!`,
  },
  {
    id: '#insurance_question',
    label: 'Insurance question',
    text: `We are in-network with a number of insurance plans, but it is difficult to answer with confidence until we can look at your insurance cards. Please text us a picture of the front and back of your card, and confirm which state you are located in. Thanks!`,
  },
  {
    id: '#insurance_upload',
    label: 'Insurance upload',
    text: `https://my.ophelia.com/portal/insurance`,
  },
  {
    id: '#lcc_escalation',
    label: 'Lead Care Coordinator Escalation',
    text: `I'm going to escalate this to one of our Lead Care Coordinators who will reach out to you directly to follow-up when able!`,
  },
  {
    id: '#kratom',
    label: 'Kratom',
    text: `You can ask your clinician more about Kratom use, and get specific answers during your initial visit with us. The best way to find out about your eligibility with us is to book an Initial Visit and speak with the clinician. At the end of the visit, if it is determined that we would not be able to treat you, you will not be charged anything for that visit. Please reach out if you have any questions or if you need help getting signed up for an Initial Visit.`,
  },
  {
    id: '#LA1_8mg_film_1mg_start',
    label: 'LA1 8mg film 1mg start',
    text: `Day 1: Cut 1 film (8mg) into halves four times to make 8 pieces, 1mg each. Dissolve 1mg piece sublingually (under tongue). Wait two hours. If there are no worsening symptoms, take another 1mg Wait two more hours. If there are no worsening symptoms, take another 1mg. Continue to dose 1-2mg every 1-2 hours until the first film is finished, for a total daily dose of 8mg on Day 1. Continue to use adjunctive medications to prevent/alleviate any residual withdrawal symptoms.
    Day 2:  Dissolve 1/2 film (4mg) in the morning Take 1/2 film every 4 hours for persistent withdrawal symptoms and cravings as tolerated for a max daily dose 24 mg (3 films)
    Day 3-7: Dissolve 3 films daily for a total daily dose of 24mg. If withdrawal symptoms persist, may take additional 1/2 - 1 film daily for max daily dose 32mg`,
  },
  {
    id: '#LA2_8mg_film_0_5mg_start',
    label: 'LA2 8mg film 0.5mg start',
    text: `Day 1:  Cut 1 film (8mg) into halves four times to make 8 pieces, 1 mg each. Cut one of these pieces into half again to make two 0.5mg pieces. Take a dose of clonidine, clonazepam, and ondansetron 1 hour before the first dose of buprenorphine. Then, dissolve a 0.5mg piece sublingually (under tongue). Wait two hours, if no worsening symptoms, take another 0.5mg. Wait two hours. If there are no worsening symptoms, take a 1mg piece. Wait two more hours. If there are no worsening symptoms, take another 1mg piece. Continue to dose 1-2mg every 1-2 hours until the first film is finished, for a total daily dose of 8mg on Day 1. May take another set of adjunctive medications 6 hours after initial dose.
    Day 2: Dissolve 1/2 film (4mg) in the morning. Take 1/2 film every 4 hours for persistent withdrawal symptoms and cravings as tolerated for a max daily dose of 24 mg (3 films)
    Day 3-7: Dissolve 3 films daily for a total daily dose of 24mg If withdrawal symptoms persist, may take additional 1/2 - 1 film daily for max daily dose 32mg`,
  },
  {
    id: '#LA3_tablet_induction',
    label: 'LA3 tablet induction',
    text: `Day 1: (2mg tablets, cut in half) Take 1mg (half tablet) sublingually (under tongue). Wait two hours. If there are no worsening symptoms, take another 1mg (half tablet). Wait two more hours. If there are no worsening symptoms, take another 1mg. Continue to dose 1-2mg every 1-2 hours for a total daily dose of 8mg on Day 1. (Max 4 tablets on Day 1). Continue to use adjunctive medications to prevent/alleviate any residual withdrawal symptoms.
    Day 2: (Switch to 8mg tablets) Take 1 (8mg) tablet sublingually in AM. Take 1/2 tab every 4 hours for persistent withdrawal symptoms and cravings as tolerated for a max daily dose 24 mg (3 tabs).
    Day 3-7: Dissolve 3 tabs daily for a total daily dose of 24mg. If withdrawal symptoms persist, may take additional 1/2 - 1 tab daily for max daily dose 32mg`,
  },
  {
    id: '#medicaid_ny',
    label: 'Medicaid NY',
    text: `Hey _________, this is ________ with Ophelia. These are the medicaid plans we are in-network with for our patients in New York: Fidelis, Empire BCBS Healthplus, and CDPHP, Healthfirst and MVP. You can also get a complete list of insurances we accept on our website at https://ophelia.com/insurance-and-pricing. Let us know if you have any questions or need help with anything else!`,
  },
  {
    id: '#medicaid_pa',
    label: 'Medicaid PA',
    text: `Hey _________, this is ________ with Ophelia. These are the Medicaid plans we are in-network with for our patients in Pennsylvania: Amerihealth Caritas, Geisinger, Keystone First (excluding Keystone East), United Healthcare, and Highmark Wholecare. You can also get a complete list of the insurances we accept on our website at https://ophelia.com/insurance-and-pricing. Let us know if you have any questions or need help with anything else!`,
  },
  {
    id: '#medication_recheck',
    label: 'Medication re-check',
    text: `I've just checked when the prescription was picked up and it looks like you shouldn't run out of medication until [DATE]. Can you please double check how much medication you have?`,
  },
  {
    id: '#medication_status',
    label: 'Medication status',
    text: `We will reach out to the clinical team about your medication and they will follow-up with you directly when able. Before we transfer you to them, could you please count your medication and let us know exactly how much you have remaining?`,
  },
  {
    id: '#missed_call_enrollment',
    label: 'Missed call enrollment',
    text: `Hey, this is _______ with Ophelia! I see that we missed a call from you. If you are looking for more information about our program, please visit our website at https://ophelia.com and click “Am I a Candidate” to get started! If you are a patient already, we do not have this number on file. To confirm identity, patients can message their full name, date of birth, and home address here, or confirm their information over the phone. Please let me know if you have any questions!`,
  },
  {
    id: '#narcan',
    label: 'Narcan',
    text: `We recommend checking out https://nextdistro.org/naloxone, where you can get Narcan delivered to your front door for free! Please let us know if you have any questions.`,
  },
  {
    id: '#next_appointment',
    label: 'Next appointment',
    text: `Your next visit is [insert visit date and time]. In the future, you can always check on your upcoming visits in the Patient Portal: https://my.ophelia.com/portal/appointments`,
  },
  {
    id: '#next_available',
    label: 'Next available',
    text: `Hey ________, the appointment you scheduled is the next available at this time. We will definitely make sure you are on our standby list and reach out if we have any appointments opened up sooner. Sorry for the wait, but we are so happy you are getting started with us! Please let us know if there is anything else we can help you with. Thank you!`,
  },
  {
    id: '#no_id',
    label: 'No ID',
    text: `If you do not have a photo ID, a document that verifies your name (e.g. piece of mail) and a secondary identifiable piece of information (e.g. address) will suffice. You should show this documentation to the PC at the onset of your intake visit!`,
  },
  {
    id: '#no_insurance_card',
    label: 'No insurance card',
    text: `Hi _______! We recommend that you search for your insurance's website online and register an account for yourself there. You should then be able to view your card online virtually! You can take a screenshot of it, and text us the picture or upload it your patient portal here: https://my.ophelia.com/welcome/signin. Please let us know if you have any questions!`,
  },
  {
    id: '#no_show',
    label: 'No show',
    text: `Are you still available for your visit? We weren't able to reach you via telephone just now, your clinician is waiting for you!`,
  },
  {
    id: '#no_show_welcome_call',
    label: 'No show welcome call',
    text: `Hey _______, this is ________ with Ophelia. Is this still a good time to chat?`,
  },
  {
    id: '#not_in_network',
    label: 'Not in-network',
    text: `Hi! Unfortunately, we are not in-network with that insurance plan yet, so the cost of treatment with Ophelia would be $195 per month. Please let us know if you have any other questions!`,
  },
  {
    id: '#nr_reengagement',
    label: 'NR reengagement',
    text: `Thanks for reaching out! Would love to get you back on the calendar, but can we talk about what happened here and our attendance policy? Just want to make sure we're aligned for your next appointment.`,
  },
  {
    id: '#oon_response',
    label: 'OON response',
    text: `Thank you for submitting the photos of your insurance cards. Unfortunately we are not in-network with your insurance at this time. The insurance would likely cover the cost of medications at the pharmacy, but the $195 monthly fee for Ophelia would be out of pocket for your treatment.`,
  },
  {
    id: '#other_resources',
    label: 'Other resources',
    text: `Here are some other helpful resources you can check out!
    SAMHSA's Buprenorphine Practitioner Locator
    https://www.samhsa.gov/medication-assisted-treatment/find-treatment/treatment-practitioner-locator
    A tool for locating practitioners authorized to treat opioid dependence with buprenorphine by state.

    SAMHSA's National Helpline
    Phone: 1-800-662-HELP (4357)
    TTY: 1-800-487-4889
    Also known as the Treatment Referral Routing Service, this Helpline provides 24-hour free and confidential treatment referral and information about mental and/or substance use disorders, prevention, and recovery in English and Spanish.

    SAMHSA's National Helpline also now offers free, confidential, 24/7, 365-day-a-year treatment referral and information services via text message. Text your 5-digit ZIP Code to 435748 (HELP4U) to access the service. Message and data rates may apply.

    Aunt Bertha
    https://www.findhelp.org/
    A search engine for free or reduced cost services, including Addiction & Recovery services.
    Please let me know if you have any questions!`,
  },
  {
    id: '#pa_required',
    label: 'Prior authorization required',
    text: `We were notified by [insert source] that your prescription requires a prior authorization from your insurance company in order to be covered. We will work on submitting this as soon as possible, but we wanted to let you know that it can take anywhere from 24-48 hours to be reviewed, sometimes even longer depending on the company. We'll let you know as soon as we hear back from them with a determination. In the meantime, you do have the option to pay for a partial script out of pocket. If you are able to do so, we suggest using the prescription assistance program GoodRx at your pharmacy to help cut down on the out of pocket cost: https://www.goodrx.com/buprenorphine-naloxone. Please let us know if you have any questions!`,
  },
  {
    id: '#password_reset',
    label: 'Password reset',
    text: `To reset your password, you can visit https://my.ophelia.com/welcome/signin. Proceed with logging in with your phone number like usual, but when you encounter the password page, press the "Forgot your password?" button.`,
  },
  {
    id: '#payment_card_on_file',
    label: 'Payment card on file',
    text: `Hey ____! We require a valid credit or debit card just to reserve your initial visit, if you are paying for treatment out of pocket, or if you have a co-payment down the line with your insurance. The only other thing you would be charged for is a $20 no show fee if you miss your appointment. If you do not have a card you can purchase a prepaid card at a local retailer! It only needs to have a minimum balance of 50 cents. You can read more about this in the financial agreement consent form in your Ophelia portal dashboard. Let us know if you have any other questions!`,
  },
  {
    id: '#pdmp_explanation',
    label: 'Prescription drug monitoring program explanation',
    text: `No one from Ophelia would ever tell another doctor, family member, or anyone else that we are treating someone, unless our patient signed a Release Of Information. There is however something called the PDMP, prescription drug monitoring program, that is a program set up for doctors to access. When someone is prescribed Suboxone, they will appear on the PDMP saying they are prescribed Suboxone. It's a registry for all controlled medications, designed to prevent people from getting multiple prescriptions from different doctors. So if a doctor specifically looked up someone on the PDMP, they would see that they were being prescribed Suboxone.`,
  },
  {
    id: '#pharmacy_transfer',
    label: 'Pharmacy transfer',
    text: `No problem, we'd be happy to send your medication to a different pharmacy. Please send over the name, address and telephone number of your preferred pharmacy and the clinical team will follow-up with you once it's sent!`,
  },
  {
    id: '#portal_link',
    label: 'Portal link',
    text: `https://my.ophelia.com/portal/`,
  },
  {
    id: '#prepaid_payment_cards',
    label: 'Prepaid payment cards',
    text: `Hi! All patients, whether or not they have insurance, will need to put a credit or debit card on file within 24 hours after scheduling in order to keep the appointment and for potential out of pocket payments in the future. You will not be charged anything before your initial visit and there will be clear communication if there is a charge once you're in treatment! Some patients purchase a prepaid Visa debit card from a local grocery or drug store. Please let me know if you have any other questions!`,
  },
  {
    id: '#prescription_amount',
    label: 'Prescription amount',
    text: `At first you will receive a weekly amount. As you get further along, you will eventually start to meet once every two weeks, and then once a month for more long-term care.`,
  },
  {
    id: '#prescription_ready?',
    label: 'Prescription ready?',
    text: `I recommend calling the pharmacy before you head over to ensure that your prescription has been filled and is available for pick-up - we can't really confirm that here. Please reach back out if you run into any issues once there!`,
  },
  {
    id: '#price',
    label: 'Price',
    text: `The cost of Ophelia is $195 per month, but that is one flat fee that covers all of your visits and care support for the whole month. The cost of medication is paid to the pharmacy and is not included with Ophelia, but is typically covered by insurances. Let me know If you have any other questions!`,
  },
  {
    id: '#quest',
    label: 'Quest',
    text: `Your provider requested that you visit the nearest Lab to have your urine checked. Attached to this message is the Quest request form. https://www.questdiagnostics.com/psc This website provides information to find a Quest near you, schedule an appointment, or plan a walk-in. Please let us know if you have any questions/concerns. Make sure to bring your insurance information and the request slip.`,
  },
  {
    id: 'labcorp',
    label: 'LabCorp',
    text: "Hey ________! Your provider requested that you visit the nearest Lab to have your urine checked. We have input your information in the LabCorp system, so all you need to do is show up to a lab location and they can pull up the order with your name and date of birth. https://www.labcorp.com This website provides information to find a LabCorp location near you, schedule an appointment, or plan a walk-in. Please don't forget to bring your insurance information!",
  },
  {
    id: '#referral_link',
    label: 'Referral link',
    text: `If you would like to refer a prospective patient to Ophelia, please go to our website at https://ophelia.com/ Next, click "Am I a Candidate" at the top right corner, then choose "Refer a Patient" and follow the steps from there.`,
  },
  {
    id: '#reschedule_follow_up',
    label: 'Reschedule follow-up',
    text: `This is the Ophelia Care Coordination team. We'd really like to get you back on the calendar for a follow-up visit to prevent you from running out of medication. Please reach out so that we can help schedule a visit as soon as possible!`,
  },
  {
    id: '#reschedule_welcome_call',
    label: 'Reschedule welcome call',
    text: `Hey ________! No worries!

    You can reschedule your Welcome Call with us here: https://my.ophelia.com/welcome

    Please let me know if you have any questions!`,
  },
  {
    id: '#reschedule_fee',
    label: 'Reschedule fee',
    text: `We do appreciate you letting us know that you needed to reschedule your visit however our rescheduling policy requires 24 hour notice. Any visit that is rescheduled less than 24 hours in advance is considered a no-show and is subject to the associated $20 no-show fee. Please keep this in mind moving forward and inform us of your rescheduling needs as early as possible!`,
  },
  {
    id: '#reset_pin',
    label: 'Reset PIN',
    text: `To reset your PIN, please click the "forgot your PIN" icon on our sign in page! Please let us know if you have any questions!`,
  },
  {
    id: '#roi',
    label: 'ROI',
    text: `https://my.ophelia.com/portal/documents/release-of-information`,
  },
  {
    id: '#roi_declination',
    label: 'ROI declination',
    text: `We received a request for all or part of your medical record from [fill in blank]. The form they submitted does not meet privacy requirements, so we can't send the records to them. If you would like your records sent to [fill in blank], please complete our Ophelia-specific ROI form here: https://my.ophelia.com/portal/documents/release-of-information and we will send the records for you.`,
  },
  {
    id: '#roi_follow-up',
    label: 'ROI follow-up',
    text: `I'm reaching back out to follow-up on the status of your Release of Information. Your clinician is asking that you please complete this before your next visit on. Please let me know if you have any questions or need assistance completing the form!`,
  },
  {
    id: '#roi_portal_directions',
    label: 'ROI portal directions',
    text: `If you need to sign a release of information, you can do so in the Patient Portal under the “Documents” tab: https://my.ophelia.com/welcome/signin`,
  },
  {
    id: '#SA1_8mg_film_2mg_start_4_8mg_MDD',
    label: 'SA1 8mg film 2mg start 4-8mg MDD',
    text: `Cut one film in half two times to make eight equal squares (2mg each).
    Day 1: Take 1/4 film (2mg) sublingually (under tongue). Wait 1 hour. If no worsening symptoms, may take an additional 1/4 film (2mg) for a total daily dose of 4mg.
    Day 2: Take the remaining two 1/4 film pieces (4mg) in AM Wait 4-6 hours, if withdrawal or cravings persist take an additional 1/4 film (2mg) Wait another 4-6 hours and if cravings or withdrawal symptoms persist, you may take an additional 1/4 film (2mg) for a total daily dose of up to 8mg.
    Day 3-7: Take 1/2  - 1 film in AM for a total daily dose up to 8mg.`,
  },
  {
    id: '#SA2_8mg_film_2mg_start_12_16mg_MDD',
    label: 'SA2 8mg film 2mg start 12-16mg MDD',
    text: `Cut one film in half two times to make eight equal squares (2mg each).
    Day 1: Take 1/4 film (2mg) sublingually (under tongue). Wait 1 hour. If tolerated, may take an additional  1/4 film (2mg). Wait another hour and if withdrawal symptoms persist take 1/2 film (4mg) for a total daily dose of 8mg.
    Day 2-7: Take 1 film (8mg) in AM, if withdrawal symptoms persist, may take 1/2 - 1 film in PM for a total daily dose up to 16mg.`,
  },
  {
    id: '#schedule_follow-up',
    label: 'Schedule follow-up',
    text: `I see that you don't have a follow up appointment on the calendar. What time works best for you to meet next with your clinician?`,
  },
  {
    id: '#secondary_insurance_inquiry',
    label: 'Secondary insurance inquiry',
    text: `Hey ________ this is ________ with Ophelia! I'm currently reviewing your Medicare insurance plan. Do you happen to have a secondary insurance plan as well? Some folks do, some don't – it's not a must! I just want to make sure I have all the info for your review. Please let me know if you have any questions. Thank you!`,
  },
  {
    id: '#secondary_insurance_explained_by_billing_team',
    label: 'Secondary insurance explained by billing team',
    text: `Hi! It's the Billing Dept at Ophelia. Our system posts invoices as soon as your primary insurance processes the claims, even though we are sending that balance to your secondary plan. We've voided these invoices. In the future, please send us a message asking to "bill Medicaid" and we'll take care of it right away. Thanks!`,
  },
  {
    id: '#self-reschedule',
    label: 'Self reschedule',
    text: `You can reschedule your appointment within the portal here: https://my.ophelia.com/portal/task/schedule-follow-up-visit`,
  },
  {
    id: '#shared_account',
    label: 'Shared account',
    text: `Unfortunately, your name is not associated with this phone number. In order to continue, please confirm your phone number and email, or contact us through your original Ophelia Account if applicable. Keep in mind that your contact information must be yours and cannot be used by another person or another patient. We look forward to speaking with you soon!`,
  },
  {
    id: '#spam_blocker',
    label: 'Spam blocker',
    text: `Hey there _______, if you didn't receive a call from Ophelia, can you please try adding Ophelia's phone number to your contacts? Some phones have a spam blocker set up and may be preventing our calls from getting through. Please let us know if you need any help or have any questions!`,
  },
  {
    id: '#subscription_explanation',
    label: 'Subscription explanation',
    text: `I apologize if our monthly subscription was unclear or perhaps miscommunicated. Right now, we are only in-network with a select few insurance companies and unfortunately your particular plan is not one of them just yet. Our monthly subscription is $195 but we can work with you on a payment plan if you would like!`,
  },
  {
    id: '#subutex_policy',
    label: 'Subutex policy',
    text: `We only prescribe Subutex to those with confirmed naloxone allergies (tested through a clinic or PCP with provided documentation), or pregnant people (with associated documentation). Otherwise, we only prescribe formulations of buprenorphine/naloxone, such as Suboxone. In order for our clinicians to prescribe Subutex to you, you will need to fill out a Release of Information Form to allow our Clinicians to confirm the needed information with your previous providers. Our clinicians will need this documentation before the Initial Visit occurs and any prescriptions are written. Please let us know if  you have any questions. Thank you!`,
  },
  {
    id: '#subutex_follow_up',
    label: 'Subutex follow-up',
    text: `"Hey ________, I just wanted to follow up with you about what we discussed regarding Ophelia's Subutex policy. We only prescribe Subutex to those with confirmed naloxone allergies (tested through a clinic or PCP with provided documentation), or pregnant people. Otherwise, we only prescribe formulations of buprenorphine/naloxone, such as Suboxone. In order for our clinicians to prescribe Subutex to you, you will need to fill out a Release of Information Form with the link below. This will allow our Clinicians to confirm the needed information with your previous provider. Our clinicians will need this documentation before the Initial Visit occurs and any prescriptions are written. Please let us know if you have any questions. Thank you! To fill out a Release of Information, please go to the Patient Portal under the “Documents” tab: https://my.ophelia.com/welcome/signin"`,
  },
  {
    id: '#support_group_signup',
    label: 'Support group sign-up',
    text: `I'm glad you're interested in Support Groups! We get new groups rolling every few weeks, so sign up here to get on our list, and we'll reach out when the next group is opening up! https://ophelia.jotform.com/210685432790054`,
  },
  {
    id: '#tn_constipation',
    label: 'Triage nurse constipation',
    text: `We are so sorry you are having such bad constipation. We recommend that you start with diet modification, fibrous foods, increasing water intake and, docusate (COLACE) 100-300 mg daily. If this does not help, we recommend either senna 1-2 tabs at bedtime OR Miralax 1 capful dissolved in 8 oz. water daily! Please reach out if you have any further concerns.`,
  },
  {
    id: '#tn_transfer',
    label: 'Triage nurse transfer',
    text: `I am going to relay this to your clinical team and someone will reach out to you directly when available!`,
  },
  {
    id: '#uds',
    label: 'Urine drug screen',
    text: `We ship urine drug screens straight to your home in discreet packaging! This way, you don't have to go to a diagnostic center and can complete your screens from the privacy of your home. After the first visit we ship UDS cups to the patient's home (2 cups per shipment), so they can get there in time for the next appointment. Clinicians will typically ask for drug tests to be completed during the first few visits while the patient stabilizes on their medication, then they typically get spaced out from there on depending on the clinician and patient.`,
  },
  {
    id: '#uds_how_to',
    label: 'Urine drug screen how to',
    text: `Great question-- when you receive your Urine Drug Screen cup package, please keep it unopened until your next visit. During that visit, your clinician will ask that you show the untampered package, excuse yourself to the restroom to conduct the screen and then return to the visit to review the results with them.`,
  },
  {
    id: '#visit_change',
    label: 'Visit change',
    text: `We're sorry for the inconvenience but due to a scheduling conflict we had to move your next visit to [insert date and time]. If this doesn't work for you, please let us know ASAP and we'll work together on finding a better time!`,
  },
  {
    id: '#welcome_back_call',
    label: 'Welcome back call',
    text: `Hey _______! You have been approved to re-enroll by our team! The next step is for us to get on the phone with you and go over some information about moving forward with Ophelia, as well as scheduling your next visit back with us. Please schedule a Welcome Call with us using this link: https://my.ophelia.com/welcome`,
  },
  {
    id: '#welcome_call_explained',
    label: 'Welcome call explained',
    text: `Hey _________, the welcome call that you scheduled is a free call to give you some information about the Ophelia program. After the Welcome Call, if you are interested, we will help you get schedule for you Initial Visit with the Clinician. After the Initial Visit with the Clinician, if you being treatment with Ophelia, your prescription will be called into your local pharmacy! Please let us know if you have any other questions! Thank you!`,
  },
  {
    id: '#welcome_call_link',
    label: 'Welcome call link',
    text: `https://my.ophelia.com/welcome`,
  },
  {
    id: '#zoom_download',
    label: 'Zoom download',
    text: `https://zoom.us/download`,
  },
  {
    id: '#zoom_test',
    label: 'Zoom test',
    text: `Once you download the app, test your zoom with this link: https://zoom.us/test`,
  },
  {
    id: '#zoom_tips',
    label: 'Zoom tips',
    text: `Below is a walkthrough on how to use Zoom on a phone and on a computer! Please review this before our meeting, especially if you haven't used Zoom before!

    Phone:
    1. Click on the speaker symbol in the upper left corner to make sure there isn't an “X” next to it
    2. Click on the lower left button that says “join audio” and make sure to click “cellular data"
    3. Click on “start video” at the bottom left of the screen
    4. Join 10 minutes BEFORE your next appointment in case any problems come up!

    Computer:
    1. Click on the blue “Join with Computer Audio” button in the center of the screen
    2. Click on “start video” at the bottom left of the screen
    3. Join 10 minutes BEFORE your appointment in case any problems come up!`,
  },
  {
    id: '#ineligible_patient',
    label: 'Ineligible patient',
    text: `Hello ______ , this is ______ with Ophelia. We have reviewed your chart and unfortunately we are not able to allow re-enrollment into Ophelia. The clinical team feels it would be safer and more beneficial for you to seek treatment from an in-person type setting. We encourage you to look into other resources for your future treatment needs.`,
  },
  {
    id: '#intake_cancellation_logging',
    label: 'Intake cancellation logging',
    text: `Hi ______! I see that your appointment was canceled because __________. Would you mind telling us why you weren't able to ______, or if we could do anything different in the future to help out? Let us know if you'd like to reschedule, or if you have any questions!`,
  },
  {
    id: '#fine_tuning',
    label: 'Fine tuning',
    text: `Hi _____! This is _____ with Ophelia. Due to a scheduling change, we had to move your upcoming ______ visit on _______ to ____.
    Please let us know if you have any questions!`,
  },
  {
    id: '#sublocade_recommendation',
    label: 'Sublocade recommendation',
    text: `This is a great site for finding Sublocade providers: https://www.sublocade.com/find-a-treatment-provider`,
  },
  {
    id: '#winback',
    label: 'Winback',
    text: `Hey ______, this is ____ with Ophelia Health. We spoke back in ____ and scheduled you for an appointment to discuss Suboxone treatment, which I see has since been canceled. I was just checking in to see if you had any questions about the program, or if you wanted to get back on the schedule with us! Feel free to give us a text when you're ready!`,
  },
  {
    id: '#prescription_timing',
    label: 'Prescription timing',
    text: `Hi ______! Our clinicians are in back to back visits throughout the day. At times, your medication will be sent immediately after your visit but for the most part all prescriptions are sent in by 8:00PM. In the meantime, you can keep an eye on your prescription status via the patient portal: https://my.ophelia.com/portal. If your pharmacy still has not received your medication by 8:00PM, please feel free to reach back out at that time and we will check on the status for you!`,
  },
  {
    id: '#bridge_script_status',
    label: 'Bridge script status',
    text: `Your request was passed along to the clinical team for review and if approved, it will be sent by 8:00PM.`,
  },
  {
    id: '#attendance_discussion',
    label: 'Attendance discussion',
    text: `Hi ___, it looks like your second no-show within two weeks, so we'll have to run our $20 no show fee this evening. We also have to go over our attendance policy before getting you scheduled: If you cannot make it to your next appointment, you will need to let us know more than 24 hours in advance. Any visits rescheduled with less than 24 hour notice will count as a no-show and include a $20.00 fee. Please be aware that if you continue to have attendance issues, it will be at your clinician's discretion whether you are able to continue with treatment with us. Before I get you rescheduled, I will need a text confirmation that you understand.`,
  },
  {
    id: '#fortuna_oon_medicaid',
    label: 'Fortuna (OON Medicaid)',
    text: `Hey ___, here is Fortuna Health's contact information as discussed: Customer support line: 888-575-9303 (available 8am-8pm ET)
    * Email support: help@fortunahealth.com
    * Live chat support: available 24/7 on platform once you create an account
  Fortuna Health will be able to assist you in changing your health plan if you decide to do so!`,
  },
  {
    id: '#fortuna_no_insurance',
    label: 'Fortuna (No Insurance)',
    text: `Hey ___, here is Fortuna Health's website as discussed: https://app.fortunahealth.com/eligibility?partner_code=OPHELIA
    Fortuna Health will be able to assist you in changing your health plan if you decide to do so!`,
  },
  {
    id: '#bridge_rx',
    label: 'Bridge RX',
    text: 'Hi ___, this is the nursing team from Ophelia. Your prescription will be sent to the pharmacy today by 8pm. You can check your patient portal for updates. Let us know if you run into any issues or have any questions. Please remember it’s important to keep your scheduled follow-up visits.',
  },
  {
    id: '#non_bup_refill',
    label: 'Non bup refill/medication request',
    text: 'Your script was approved by your clinician and will be sent to your pharmacy by the end of the day. Please keep an eye out on your patient portal for updates. I recommend calling the pharmacy before you head over to ensure that your prescription has been filled and is available for pick-up',
  },
  {
    id: '#early_fill_1',
    label: 'Early fill 1',
    text: "Given that you shouldn't be due for a refill until at least [Date] , your clinician will require an accurate count of your remaining medication and details of any circumstances if you're running low. This information will assist us in advocating for you with the pharmacy if an early refill is necessary. Pharmacies typically adhere strictly to dispensing controlled substances based on your last pickup date. If you suspect that some medication may have been stolen, you will likely need to file a police report and provide the report number to the pharmacy for them to consider an early refill. Additionally, if the pharmacy agrees to dispense your medication early, insurance is unlikely to cover it, so you would need to pay out of pocket for the prescription. Thank you.",
  },
  {
    id: '#early_fill_2',
    label: 'Early fill 2',
    text: "Hi [Patient Name], Pharmacies typically adhere strictly to dispensing controlled substances based on your last pick-up date. Our records show that your last prescription was dispensed on or after [Date] for [15/30] days. As a result, you shouldn't be due for a refill until at least [Due Date]. However, if you're running short on medication, your clinician will need to know the circumstances. This information will enable us to coordinate with the pharmacy on your behalf if an early refill is necessary. Please inform us as soon as possible. Thank you for your cooperation.",
  },
  {
    id: '#pregnant',
    label: 'Pregnant',
    text: "We understand that you may be pregnant. Please don't worry; we will continue your Suboxone treatment during pregnancy, and you can safely remain on your current dose. If you have any concerns or would like to discuss this further, please let us know, and we can schedule an appointment with your provider at your convenience. Additionally, it's important to schedule an appointment with an OB/GYN and begin taking prenatal vitamins. Remember, we are here to support you every step of the way.",
  },
  {
    id: '#late_fill',
    label: 'Late fill',
    text: "Hi [Patient Name]. Our records show that you last filled 30 days of Suboxone on [Date], so you should be out of medication by now. Can you please provide us with an update on how you've been taking your Suboxone, or if you've run out? The clinician will need this information before we can send a prescription. Thank you for your cooperation.",
  },
  {
    id: '#patient_reported_relapse',
    label: 'Patient reported relapse',
    text: "Thank you for reaching out. We're here to help you get back on track with your Suboxone treatment. Could you please let us know what led to the relapse and the last time you used? Additionally, it's important for us to know how much Suboxone you have left. Please let me know and I can further help from there. Thank you",
  },
  {
    id: '#assessing_for_relapse',
    label: 'Assessing for relapse',
    text: 'Hey [Name], before we send more Suboxone, we need to ask you a couple of questions. Have you taken any opiates since your last dose of Suboxone? Your safety is our priority, so please let us know. Thank you for your cooperation.',
  },
  {
    id: '#zocdoc',
    label: 'Zocdoc',
    text: 'Check out zocdoc.com. Here you can search for medical providers based on your area and health insurance. It will automatically show you results where you are in-network!',
  },
  {
    id: '#roi_request',
    label: 'ROI request',
    text: 'Hi [Name], your clinician is requesting that you please fill out a release of information for [ROI type]. To sign a release of information, you can do so in the Patient Portal at https://my.ophelia.com/portal/documents/release-of-information. If you have any questions or need assistance, please reach out. Thank you!',
  },
  {
    id: '#discharge_requesting_more_info',
    label: 'Discharge requesting more information',
    text: `Hey [Name], I see that you sent us a message that said "cancel membership immediately." We'd be happy to help proceed with your request but it would be really helpful for us to gather some additional information. Would you like to set up a phone call to further discuss? If not, could you please let us know the reason why you would like to discontinue treatment with Ophelia?`,
  },
  {
    id: '#discharge_visit_and_resources',
    label: 'Discharge visit and resources',
    text: `Hi [Name] thank you for giving us a heads up that you need to cancel services with us, we're sad to see you go! Before we get started on this discharge process, would you like to schedule a discharge visit with your clinician? Also, do you need any resources for alternative treatment options? Let us know either way, thank you!`,
  },
  {
    id: '#discontinuation',
    label: 'Discontinuation',
    text: `Hi [Name]! Thank you for letting us know that you need to discontinue services with us, we appreciate the heads up! I wanted to let you know that your subscription with us has been paused, and you will get a letter of discontinuation in the next few days. Please let us know if there's anything else we can do for you, and don't hesitate to reach out if you need us in the future. We wish you the best!`,
  },
  {
    id: '#discharge_clinical_reason',
    label: 'Discharge clinical reason',
    text: `Hey [Name]! I'm sorry to hear you've been having a hard time with your medication. I am passing your message along to someone from our clinical team so they can touch base with you about this.`,
  },
  {
    id: '#ins_benefits',
    label: 'INS Benefits',
    text: `Cash per Month: $195
    Insurance Benefits:
    Deductible: $____
    Copay: $____ per visit
    Coinsurance: ____
    Estimated Insurance First Month:  $____ due to deductible*
    *If you choose to use insurance, you have to meet your deductible before your copay applies.`,
  },
  {
    id: '#visit_holds_explanation',
    label: 'Visit holds explanation',
    text: 'Hi [Patient]! We are trying a new way of scheduling, where you will schedule your next appointment yourself! You’ll receive a text to schedule your next appointment about a week before you are due for your next visit. You do not need to log in to the Ophelia portal to schedule, the text will automatically bring you to a scheduling page with appointment options to choose from! If you don’t get a chance to schedule within 24 hours of receiving the text, our system will automatically book an appointment for you within your preferred availability. If at any point your scheduled time doesn’t work for you, feel free to reschedule in the portal. This way, even if you forget, we’ll make sure you have a visit scheduled!',
  },
  {
    id: '#task_completion_requirement',
    label: 'Task completion requirement',
    text: `
    Hi! In order to schedule another initial visit with us, you’ll need to complete your required tasks first. It shouldn’t take more than 15 minutes to complete, and once you have completed them, give us a call or a text to reschedule! You can find the link to the portal below to get started. As always, please let us know if you have any questions!

    https://my.ophelia.com/portal/
    `,
  },
  {
    id: '#standby_welcome_call',
    label: 'Standby Welcome Call',
    text: `
    Hey _____, this is _____ with Ophelia! I see that you requested a sooner welcome call, is now a good time to chat?
    `,
  },
  {
    id: '#returning_welcome_call',
    label: 'Returning welcome call',
    text: 'Hey ____! In order to discuss re-enrollment with Ophelia, please sign in here and schedule a free returning welcome call: http://my.ophelia.com/welcome/signin',
  },
  {
    id: '#mco_winback',
    label: 'MCO winback',
    text: 'Hi! This is ___ with Ophelia checking in! Do you have any updates about switching your insurance plan? If so, we would love to coordinate with you to get on the calendar and see a clinician! Let us know if you need any assistance, or if you have any questions.',
  },
]
