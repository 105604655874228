import { CheckCircleIcon, Group, Indicator, Pill, Text } from '@shared/components'
import { Employee, hasGroupRole, isAdmin, isClinician } from '@shared/types'
import { toTime } from '@shared/utils'
import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form-latest'
import { useMutation, useQuery } from 'react-query'
import { employeesApi, emrApi } from '../../api'
import ALargeLoadingSpinner from '../../components/atoms/ALargeLoadingSpinner'
import ODSPrimaryButton from '../../components/ods/ODSPrimaryButton'
import ODSSecondaryButton from '../../components/ods/ODSSecondaryButton'
import { Page } from '../../components/templates/TDefault'
import { useAuth } from '../../context/auth'
import { useAlertsCount, useFlags, useLunaQuery, useTypedParams } from '../../utils/hooks'
import { anonymousEmployeeImageUrl } from '../../utils/utils'
import MAlertsDrawer from '../core/action_items/MAlertsDrawer'
import ODoseSpotModal from '../core/action_items/ODoseSpotModal'
import MEmployeeProfileTab from './MEmployeeProfileTab'
import MEmployeeProviderTab from './MEmployeeProviderTab'
import OEmployeeSidebar from './OEmployeeSidebar'
import OInactiveEmployeeModal from './OInactiveEmployeeModal'

const PEmployees = () => {
  const { currentUser } = useAuth()
  const { register, handleSubmit, reset } = useForm<Employee>()

  const [editMode, setEditMode] = useState(false)
  const [tabs, setTabs] = useState(['Profile'])
  const [showInactiveEmployeeModal, setShowInactiveEmployeeModal] = useState(false)
  const [showDoseSpotModal, setShowDoseSpotModal] = useState(false)
  const [isAlertsDrawerOpened, setAlertsDrawerOpened] = React.useState<boolean>(false)

  const {
    pathParams: { employeeID = '' },
    queryParams: { tab },
    setQueryParams,
  } = useTypedParams('/employees/:employeeID')

  const [, actionItemsCount, , , refreshAlertsCount] = useAlertsCount({ employeeId: employeeID })

  const tabSelected = tab || 'Profile'

  const { techTeam } = useFlags()

  const [employeeQueryKey, employeeQueryFunction] = emrApi.getQuery('GET /employee/:employeeId', {
    params: {
      employeeId: employeeID,
    },
  })

  const employee = useQuery(employeeQueryKey, employeeQueryFunction, {
    enabled: Boolean(employeeID),
    onSuccess: employee => {
      reset(employee)

      if (isClinician(employee)) {
        setTabs(['Profile', 'Provider'])
      } else {
        setTabs(['Profile'])
      }
    },
  })

  const employeeDSNotificationCount = useQuery(
    ['employeesApi.getDSNotificationCount'],
    employeesApi.getDSNotificationCount,
    {
      enabled: Boolean(
        employee.data && isClinician(employee.data) && employee.data.email === currentUser.email,
      ),
    },
  )
  const hasDSNotifications = Number(employeeDSNotificationCount.data?.count) > 0

  const updateEmployee = useMutation(employeesApi.update, {
    onSuccess: () => {
      setEditMode(false)
      void employee.refetch()
    },
  })

  const onSubmit: SubmitHandler<Employee> = data => {
    data.calendarId = Number(data.calendarId)
    updateEmployee.mutate({ employeeId: employeeID, ...data })
  }

  const { data: configs } = useQuery(...emrApi.getQuery('GET /configs'))

  const employeeIsConsultationCallQueue = employee.data?.role === 'consultationCallQueue'

  const { data: waiting } = useLunaQuery('GET /consultation-queue/waiting', {
    enabled:
      employeeIsConsultationCallQueue &&
      hasGroupRole(
        currentUser,
        'engineer',
        'admin',
        'enrollmentCoordinator',
        'leadEnrollmentCoordinator',
      ),
    refetchOnWindowFocus: true,
    refetchInterval: toTime('10 sec').ms(),
    refetchIntervalInBackground: false,
    keepPreviousData: true,
    refetchOnReconnect: true,
    cacheTime: 0,
    staleTime: 0,
  })

  const employeeIsOnCallEngineer = employee.data?.oid === configs?.onCallEngineer

  return (
    <Page title='Employees'>
      {showInactiveEmployeeModal && (
        <OInactiveEmployeeModal
          closeModal={() => setShowInactiveEmployeeModal(false)}
          employeeID={employeeID || ''}
        />
      )}
      {showDoseSpotModal && <ODoseSpotModal closeModal={() => setShowDoseSpotModal(false)} />}
      <div className='relative h-screen flex overflow-hidden bg-white'>
        <div className='flex flex-col min-w-0 flex-1 overflow-hidden'>
          <div className='flex-1 relative z-0 flex overflow-hidden'>
            <OEmployeeSidebar disabled={editMode} />
            <main className='flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last'>
              {employee.data && (
                <article>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                      <div className='max-w-5xl mx-auto px-4 sm:px-6 lg:px-8'>
                        <div className='mt-4 sm:mt-6 sm:flex sm:items-end sm:space-x-5'>
                          <div className='flex'>
                            <img
                              className='h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32'
                              src={
                                employee.data.profilePhotoURL
                                  ? employee.data.profilePhotoURL
                                  : anonymousEmployeeImageUrl
                              }
                              alt='employee profile'
                            />
                          </div>
                          <div className='mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1'>
                            <div className='2xl:block mt-6 min-w-0 flex-1 flex items-center flex-column space-x-3'>
                              <h1 className='text-2xl font-bold text-gray-900 truncate'>
                                {employee.data.name}
                              </h1>
                              {employee.data?.isPsychConsultant && (
                                <Pill status='success' variant='filled'>
                                  Psych consultant
                                </Pill>
                              )}
                              {employeeIsOnCallEngineer && (
                                <Pill status='none' variant='filled'>
                                  On-call engineer
                                </Pill>
                              )}
                              {employeeIsConsultationCallQueue && (
                                <Pill status='success' variant='filled'>
                                  {`Waiting in queue: ${
                                    waiting?.data?.waiting ? waiting?.data?.waiting : '...'
                                  }`}
                                </Pill>
                              )}
                            </div>
                          </div>
                          {/* TODO: if this is ever changed, the corresponding BE check should be changed in employees.ts*/}
                          {(techTeam || hasGroupRole(currentUser, 'admin', 'hqEmployee')) && (
                            <div className='mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4'>
                              {editMode ? (
                                <>
                                  <ODSSecondaryButton
                                    type='button'
                                    message='Cancel'
                                    disabled={updateEmployee.isLoading}
                                    onClick={() => setEditMode(false)}
                                  />
                                  <ODSPrimaryButton
                                    loading={employee.isLoading || updateEmployee.isLoading}
                                    message='Submit'
                                  />
                                </>
                              ) : (
                                <Group>
                                  {hasGroupRole(currentUser, 'engineer') && (
                                    <Indicator
                                      color={({ error }) => error[0]}
                                      label={
                                        <Text color={({ text }) => text[3]} size='xs'>
                                          {actionItemsCount}
                                        </Text>
                                      }
                                      onClick={() => setAlertsDrawerOpened(!isAlertsDrawerOpened)}
                                      disabled={actionItemsCount === 0}
                                    >
                                      <CheckCircleIcon color={({ background }) => background[5]} />
                                    </Indicator>
                                  )}
                                  <ODSPrimaryButton
                                    type='button'
                                    message='Edit'
                                    onClick={event => {
                                      event.preventDefault()
                                      setEditMode(true)
                                    }}
                                  />
                                  {isClinician(currentUser) && currentUser?.oid === employeeID && (
                                    <div>
                                      <ODSSecondaryButton
                                        type='button'
                                        message={`Open DoseSpot ${
                                          hasDSNotifications
                                            ? `(${employeeDSNotificationCount.data?.count})`
                                            : ''
                                        }`}
                                        onClick={() => setShowDoseSpotModal(true)}
                                        className={`${
                                          hasDSNotifications ? 'border-daybreak-text3' : ''
                                        }`}
                                      />
                                    </div>
                                  )}
                                </Group>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <MAlertsDrawer
                      currentUser={employee.data}
                      isAlertsDrawerOpened={isAlertsDrawerOpened}
                      setAlertsDrawerOpened={setAlertsDrawerOpened}
                      refreshAlertsCount={refreshAlertsCount}
                    />
                    <div className='mt-6 sm:mt-2 2xl:mt-5'>
                      <div className='border-b border-gray-200'>
                        <div className='max-w-5xl mx-auto px-4 sm:px-6 lg:px-8'>
                          <nav className='-mb-px flex space-x-8' aria-label='Tabs'>
                            {tabs.map(key => (
                              <span
                                key={key}
                                onClick={() => {
                                  if (editMode) {
                                    return
                                  }
                                  setQueryParams({ tab: key })
                                }}
                                className={`${
                                  tabSelected === key
                                    ? 'border-daybreak-actions0 text-gray-900'
                                    : 'border-transparent text-gray-500'
                                } ${
                                  editMode
                                    ? 'cursor-default hover:text-gray-500'
                                    : 'hover:border-gray-300 cursor-pointer hover:text-gray-700'
                                } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm no-underline`}
                              >
                                {key}
                              </span>
                            ))}
                          </nav>
                        </div>
                      </div>
                    </div>
                    <div className='mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8'>
                      {employee?.data && tabSelected === 'Profile' && (
                        <MEmployeeProfileTab
                          employee={employee.data}
                          editMode={editMode}
                          register={register}
                          key='Profile'
                        />
                      )}
                      {isClinician(employee.data) && tabSelected === 'Provider' && (
                        <MEmployeeProviderTab
                          employee={employee.data}
                          editMode={editMode}
                          register={register}
                          key='Provider'
                        />
                      )}
                    </div>
                    {editMode && isAdmin(currentUser) && (
                      <div className='flex mr-8'>
                        <div className='ml-auto'>
                          <ODSSecondaryButton
                            type='button'
                            message='Mark Inactive'
                            onClick={() => setShowInactiveEmployeeModal(true)}
                            className='mt-12 mb-4'
                          />
                        </div>
                      </div>
                    )}
                  </form>
                </article>
              )}
              {employee.isLoading && <ALargeLoadingSpinner />}
            </main>
          </div>
        </div>
      </div>
    </Page>
  )
}

export default PEmployees
