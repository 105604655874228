import { useForm } from '@mantine/form'
import {
  Anchor,
  DateTimePicker,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Group,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  INPUT_SIZES,
  PrimaryButton,
  SaveIcon,
  Select,
  Stack,
  Text,
  Tooltip,
  showNotification,
  skipIfOtherField,
  validateWith,
} from '@shared/components'
import {
  EMRTaskPriority,
  EMRTaskType,
  EMRTaskTypeMap,
  NewEMRTaskBody,
  OUTAGE_FEATURE_MAP,
  OUTAGE_TASKS,
  OUTAGE_TASK_PRIORITIES,
  OutageFeature,
  OutageTaskPriority,
  TaskType,
  getEmrTechIssueDescriptionTemplate,
  getPodOptions,
  hasGroupRole,
} from '@shared/types'
import { dayjs, name } from '@shared/utils'

import { useMemo } from 'react'
import { useMutation } from 'react-query'
import { emrApi } from '../../../api'
import { EmployeeMentionNoteInput } from '../../../components/forms/EmployeeMentionNoteInput'
import { SelectPatient } from '../../../components/forms/SelectPatient'
import { useAuth } from '../../../context/auth'
import { isRequired } from '../../../utils/formValidation'
import { useOnCallEngineer } from '../../../utils/hooks'
import { usePatientGlobal } from '../../../utils/hooks/use-patient-global'
import { useTasksCache } from '../../../utils/hooks/use-tasks-cache'

/*
 * This is a workaround to ensure INPUT_SIZES is available to
 * the DateTimePicker component internally. Without this, a page
 * crash occurs.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _INPUT_SIZES = INPUT_SIZES

export type CreateTaskForm = {
  description: string
  patientId: string | null
  priority: EMRTaskPriority | OutageTaskPriority | null
  type: EMRTaskType | null
  outageFeature: OutageFeature | null
  outageStartTime: string
  searchValue: string
}

export const AddEmrBugIssueDrawerContent = ({
  onBack,
  onClose,
}: {
  onBack: (() => void) | undefined
  onClose: () => void
}) => {
  const { currentUser } = useAuth()
  const { patientId, patientQuery } = usePatientGlobal()

  const form = useForm<CreateTaskForm>({
    initialValues: {
      description: getEmrTechIssueDescriptionTemplate(location.href),
      patientId: patientId || null,
      priority: 5,
      type: 'emr_tech_issue',
      outageFeature: null,
      outageStartTime: dayjs().toISOString(),
      searchValue: '',
    },
    validate: {
      description: validateWith(isRequired),
      type: validateWith(isRequired),
      priority: validateWith(isRequired),
      outageFeature: validateWith(skipIfOtherField('type', 'not', 'outage'), isRequired),
      outageStartTime: validateWith(skipIfOtherField('type', 'not', 'outage'), isRequired),
    },
  })

  const tasksCache = useTasksCache()

  const createTaskMutation = useMutation(emrApi.getMutation('POST /tasks/v2'), {
    onSettled: () => {
      tasksCache.invalidate()
    },
  })

  const {
    onCallEngineerQuery: { data: onCallEngineer, isLoading: isOnCallEngineerLoading },
  } = useOnCallEngineer()

  const getIssueTypeOptions = () => {
    return EMRTaskTypeMap.filter(taskType => {
      const isEngineer = hasGroupRole(currentUser, 'admin', 'engineer')
      const allowedTypes = isEngineer
        ? ['emr_tech_issue', 'emr_feature_request', 'outage']
        : ['emr_tech_issue', 'emr_feature_request']
      return allowedTypes.includes(taskType.value)
    })
  }

  const handleIssueTypeChange = (value: EMRTaskType) => {
    const emrTask = EMRTaskTypeMap.find(type => type.value === value)

    if (emrTask) {
      form.setFieldValue('type', emrTask.value)
      form.setFieldValue('priority', emrTask.priority)
      if (emrTask.value === 'emr_feature_request') {
        form.setFieldValue('description', '')
      } else {
        form.setFieldValue('description', getEmrTechIssueDescriptionTemplate(location.href))
      }
    }
  }

  const onSubmit = async () => {
    const formHasErrors = form.validate().hasErrors
    if (formHasErrors || !onCallEngineer?.oid) {
      return
    }

    let createdTaskId = null
    if (form.values.type === 'outage') {
      if (!form.values.outageFeature) {
        // Satisfy the typechecker
        return
      }

      const emrTask: NewEMRTaskBody<'outage'> = {
        type: 'outage',
        priority: form.values.priority as OutageTaskPriority,
        description: form.values.description,
        patientId: null,
        employeeId: onCallEngineer?.oid,
        pod: 'engineer',
        details: {
          feature: form.values.outageFeature,
          startTime: form.values.outageStartTime,
        },
      }

      createdTaskId = (
        await createTaskMutation.mutateAsync({
          data: emrTask,
        })
      ).emrTaskId
    } else {
      createdTaskId = (
        await createTaskMutation.mutateAsync({
          data: {
            type: form.values.type as TaskType,
            priority: form.values.priority as EMRTaskPriority,
            description: form.values.description,
            employeeId: onCallEngineer?.oid,
            patientId: form.values.patientId,
            pod: 'engineer',
          },
        })
      ).emrTaskId
    }

    onClose()

    showNotification({
      message: (
        <Text>
          Successfully sent issue to engineering oncall.{' '}
          {createdTaskId && (
            <Anchor href={`/issues/open?taskId=${createdTaskId}`}>Review here</Anchor>
          )}
        </Text>
      ),
      variant: 'success',
    })
  }

  const isOutage = form.values.type === 'outage'

  return (
    <>
      <DrawerHeader onBack={onBack} onClose={onClose}>
        EMR tech issue
      </DrawerHeader>
      <DrawerContent trapFocus={false}>
        <Stack p='md'>
          <Text>By submitting the EMR tech issue, you&apos;ll alert the engineering oncall</Text>

          <SelectPatient
            patientId={form.values.patientId || undefined}
            label='Which patient is having the issue? (optional)'
            placeholder='Search by patient name...'
            explanation='Search by full first name and/or last name'
            onSearchValueChange={value => form.setFieldValue('searchValue', value)}
            initialSearchValue={
              patientQuery.data
                ? name({
                    first: patientQuery.data?.personalData.firstName,
                    last: patientQuery.data?.personalData.lastName,
                  }).lastCommaFirst()
                : form.values.searchValue
            }
            {...form.getInputProps('patientId')}
          />

          <Select
            label='Issue type'
            w='100%'
            // eslint-disable-next-line react-hooks/exhaustive-deps
            data={useMemo(() => getIssueTypeOptions(), [])}
            {...form.getInputProps('type')}
            onChange={handleIssueTypeChange}
          />

          <EmployeeMentionNoteInput
            label='Issue description'
            placeholder='Provide a brief overview'
            {...form.getInputProps('description')}
          />

          {isOutage && (
            <>
              <Select
                label='Set priority'
                data={[...OUTAGE_TASK_PRIORITIES].reverse().map(priority => ({
                  label: OUTAGE_TASKS[priority].label,
                  value: String(priority),
                }))}
                {...form.getInputProps('priority')}
                value={String(form.values.priority)}
                onChange={value => {
                  // Select needs to use a string, but the form expects a number
                  form.setValues({ priority: Number(value) as EMRTaskPriority })
                }}
              />
              <Select
                label='Feature affected'
                placeholder='Select feature affected'
                data={OUTAGE_TASKS[form.values.priority as OutageTaskPriority].features.map(
                  feature => ({
                    label: OUTAGE_FEATURE_MAP[feature],
                    value: feature,
                  }),
                )}
                {...form.getInputProps('outageFeature')}
              />
              <DateTimePicker
                label='Start time'
                placeholder='Select start time'
                explanation='When did this outage begin?'
                {...form.getInputProps('outageStartTime')}
              />
            </>
          )}
          <Tooltip
            label='Only issues for the engineering team can be created here'
            position='bottom-start'
          >
            <Select w='100%' label='Assign to' data={getPodOptions()} disabled value='engineer' />
          </Tooltip>
        </Stack>
      </DrawerContent>
      <DrawerFooter>
        <Group position='right'>
          <PrimaryButton
            onClick={onSubmit}
            leftIcon={<SaveIcon />}
            loading={createTaskMutation.isLoading}
            disabled={isOnCallEngineerLoading}
          >
            Submit tech issue
          </PrimaryButton>
        </Group>
      </DrawerFooter>
    </>
  )
}
