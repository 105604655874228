import { useForm } from '@mantine/form'
import {
  Alert,
  BookmarkIcon,
  Checkbox,
  FlagIcon,
  Grid,
  Group,
  isAnySelected,
  isLength,
  Select,
  showNotification,
  skipIfOtherField,
  Stack,
  Text,
  TextInput,
  validateWith,
} from '@shared/components'
import {
  Appointment,
  ClosedNoteDetails,
  Employee,
  hasGroupRole,
  InductionNoteContent,
  InductionNoteContentForm,
  isAdmin,
  isCoordinator,
  Patient,
  PrescriptionResponse,
  ProblemListProblem,
} from '@shared/types'
import { dayjs, getVisitNoteLockedAtString, toTime, validCharacters } from '@shared/utils'
import capitalize from 'lodash/capitalize'
import isEqual from 'lodash/isEqual'
import { useCallback, useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { emrApi, visitNotesApi } from '../../../api'
import { DiagnosisSection } from '../../../components/forms/DiagnosisSection'
import { SelectItem } from '../../../components/forms/SelectItem'
import { VisitNoteSelect } from '../../../components/forms/VisitNoteSelect'
import { VisitNoteTextarea } from '../../../components/forms/VisitNoteTextArea'
import { YesOrNoRadio } from '../../../components/forms/YesOrNoRadio'
import { isAbbreviatedUsState, isRequired, isYesOrNoSelected } from '../../../utils/formValidation'
import { useFlags } from '../../../utils/hooks'
import { useMbcSession } from '../../../utils/hooks/use-mbc-session'
import {
  exportToPdf,
  inductionEmailOptions,
  medicationName2Strength,
  medicationNames,
} from '../../../utils/utils'
import { ObjectiveMeasuresSection } from '../../registry/objectiveMeasures/ObjectiveMeasuresSection'
import ObjectiveMeasuresFormCollectionDrawer from '../forms/ObjectiveMeasuresFormCollectionDrawer'
import { usePatient } from '../PPatientContext'
import {
  formatMentalHealthEvalResponses,
  MENTAL_HEALTH_EVALUATION_FORM_INIT,
  mentalHealthEvalToFormData,
  MentalHealthEvaluationFormProvider,
  useMentalHealthEvaluationForm,
} from './formHelpers'
import { HighRiskPatientAlert } from './HighRiskPatientAlert'
import { MentalHealthEvalSection } from './MentalHealthEvalSection'
import MVisitNotePrescriptionSection from './MVisitNotePrescriptionSection'
import {
  getDefaultLocationsData,
  PatientAndClinicianLocations,
} from './PatientAndClinicianLocations'
import {
  convertSudFormDataToNoteContent,
  convertSudNoteContentToFormData,
  getSubstanceUseFormValidator,
  SubstanceUseFormProvider,
  SubstanceUseSection,
  useSubstanceUseForm,
} from './SubstanceUseSection'
import UpcomingVisitsSection from './UpcomingVisitsSection'
import { VisitNoteHeader } from './VisitNoteHeaderFooter'
import { VisitNoteSection } from './VisitNoteSection'

type InductionVisitFormProps = {
  patientID: string
  notes: Partial<InductionNoteContent> | null
  editMode?: boolean
  patient?: Patient
  sidebar: boolean
  visitID?: string
  currentUser?: Employee
  closedNoteDetails?: ClosedNoteDetails | null
  visit?: Appointment
  meetingDetails?: { patientTime: number; clinicianTime: number }
  setMedicationPrescribed?: (bool: boolean) => void
  setUnlockNoteModal?: (bool: boolean) => void
  setCloseNoteModal?: (bool: boolean) => void
  patientNeedsMentalHealthEval?: boolean
  refetchMentalHealthEvals?: () => void
  handleTobaccoUseDiagnosis: (diagnoses: ProblemListProblem[]) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addendums?: Record<string, any>
  setPrescriptionBanner?: (message: string, type: 'success' | 'warning' | 'error') => void
}

// We generate a field for every diagnose based on the the full description string
const getDiagnoseKey = (description: string): `plan_${string}` =>
  `plan_${description
    ?.toLowerCase()
    .replace(/[[\].$#/]+/g, '')
    .replace(/ /g, '_')}`

export const InductionVisitForm = ({
  currentUser,
  patientID,
  visitID,
  notes,
  editMode = false,
  sidebar,
  closedNoteDetails,
  visit,
  patient,
  meetingDetails,
  setPrescriptionBanner,
  setMedicationPrescribed,
  setUnlockNoteModal,
  setCloseNoteModal,
  patientNeedsMentalHealthEval,
  refetchMentalHealthEvals,
  handleTobaccoUseDiagnosis,
  addendums,
}: InductionVisitFormProps) => {
  const { patientAndClinicianLocations, isSubstanceUseSectionEnabled } = useFlags()
  const queryClient = useQueryClient()
  const { problemsListQuery, patientId } = usePatient()

  const [savedAt, setSavedAt] = useState<dayjs.Dayjs>()
  const [medicationSentViaDosespot, setMedicationSentViaDosespot] = useState(false)
  const [dosespotMedications, setDosespotMedications] = useState<
    PrescriptionResponse[] | undefined
  >()
  const prescriptionIdQuery = useQuery(
    ['visitNotesApi.getVisitNotePrescriptions', visitID],
    () => visitNotesApi.getVisitNotePrescriptions({ visitId: Number(visitID) }),
    { enabled: Boolean(visitID) },
  )

  const hasDosespotMedication =
    medicationSentViaDosespot || Boolean(prescriptionIdQuery.data?.length)

  const skipIfMedicationNotSentViaDosespot = () => !medicationSentViaDosespot
  const skipIfNoDosespotMedication = () => !hasDosespotMedication
  const skipIfLocationsSectionNotDisplayed = () => !patientAndClinicianLocations

  function getValueOrEmpty<T extends keyof InductionNoteContent>(
    key: T,
    value: InductionNoteContent[T],
  ) {
    if (notes?.[key]) {
      return notes[key]
    }

    if (editMode) {
      return value
    }

    // No value if not editing for old/locked visit notes
  }

  const form = useForm<InductionNoteContentForm>({
    initialValues: {
      overdose_history: notes?.overdose_history ?? 'no',
      witnessed_overdose: notes?.witnessed_overdose ?? 'no',
      alcohol_use: notes?.alcohol_use ?? 'no',
      tobacco_use: notes?.tobacco_use ?? 'no',
      marijuana_use: notes?.marijuana_use ?? 'no',
      benzodiazepines_use: notes?.benzodiazepines_use ?? 'no',
      amphetamine_use: notes?.amphetamine_use ?? 'no',
      cocaine_use: notes?.cocaine_use ?? 'no',
      other_use: notes?.other_use ?? 'no',
      naloxone_supply: notes?.naloxone_supply ?? 'no',
      pdmp_consistent_with_history: notes?.pdmp_consistent_with_history ?? 'yes',
      participating_in_counseling: notes?.participating_in_counseling ?? 'no',
      participating_in_recovery_services: notes?.participating_in_recovery_services ?? 'no',
      history_of_overdose: notes?.history_of_overdose ?? 'no',
      medication_name: notes?.medication_name ?? '',
      medication_strength: notes?.medication_strength ?? '',
      medication_days_supply: notes?.medication_days_supply ?? '',
      medication_quantity: notes?.medication_quantity ?? '',
      plan: notes?.plan ?? '',
      induction_email: notes?.induction_email ?? '',
      problems_addressed: notes?.problems_addressed ?? [],
      effective_date: notes?.effective_date ?? '',
      telehealth_consent: notes?.telehealth_consent ?? true,
      history_of_present_illness: notes?.history_of_present_illness ?? '',
      medical_pmh: notes?.medical_pmh ?? '',
      psychiatric_pmh: notes?.psychiatric_pmh ?? '',
      hospitalizations_pmh: notes?.hospitalizations_pmh ?? '',
      surgical_pmh: notes?.surgical_pmh ?? '',
      active_medications_pmh: notes?.active_medications_pmh ?? '',
      allergies_and_reactions_pmh: notes?.allergies_and_reactions_pmh ?? '',
      social_and_family_pmh: notes?.social_and_family_pmh ?? '',
      opioid_history: notes?.opioid_history ?? '',
      opioid_treatment_history: notes?.opioid_treatment_history ?? '',
      overdose_history_comments: notes?.overdose_history_comments ?? '',
      witnessed_overdose_comments: notes?.witnessed_overdose_comments ?? '',
      alcohol_use_comments: notes?.alcohol_use_comments ?? '',
      tobacco_use_comments: notes?.tobacco_use_comments ?? '',
      marijuana_use_comments: notes?.marijuana_use_comments ?? '',
      amphetamine_use_comments: notes?.amphetamine_use_comments ?? '',
      benzodiazepines_use_comments: notes?.benzodiazepines_use_comments ?? '',
      cocaine_use_comments: notes?.cocaine_use_comments ?? '',
      other_use_comments: notes?.other_use_comments ?? '',
      participating_in_counseling_comments: notes?.participating_in_counseling_comments ?? '',
      participating_in_recovery_services_comments:
        notes?.participating_in_recovery_services_comments ?? '',
      observed_and_examination: notes?.observed_and_examination ?? '',
      pdmp_consistent_with_history_comments: notes?.pdmp_consistent_with_history_comments ?? '',
      treatment_goals: notes?.treatment_goals ?? '',
      assessment: notes?.assessment ?? '',
      heroin_or_fentanyl_regular_basis: getValueOrEmpty(
        'heroin_or_fentanyl_regular_basis',
        visit?.metadata?.highRiskPatientResponses?.some(
          value => value === 'heroin_or_fentanyl_use_regular_yes',
        )
          ? 'yes'
          : 'no',
      ),
      suboxone_use_last_week: getValueOrEmpty(
        'suboxone_use_last_week',
        visit?.metadata?.highRiskPatientResponses?.some(
          value => value === 'suboxone_taken_last_week',
        )
          ? 'yes'
          : 'no',
      ),
      ...(notes?.problems_addressed || []).reduce((prev: Record<string, unknown>, next) => {
        const key = getDiagnoseKey(next.full_description)
        return { ...prev, [key]: notes?.[key] }
      }, {}),
      ...getDefaultLocationsData({ patient, employee: currentUser }),
    },
    validate: {
      history_of_present_illness: validateWith(isRequired),
      medical_pmh: validateWith(isRequired),
      psychiatric_pmh: validateWith(isRequired),
      hospitalizations_pmh: validateWith(isRequired),
      surgical_pmh: validateWith(isRequired),
      active_medications_pmh: validateWith(isRequired),
      allergies_and_reactions_pmh: validateWith(isRequired),
      social_and_family_pmh: validateWith(isRequired),
      opioid_history: validateWith(isRequired),
      opioid_treatment_history: validateWith(isRequired),
      overdose_history: validateWith(isYesOrNoSelected),
      overdose_history_comments: validateWith(
        skipIfOtherField('overdose_history', 'is', 'no'),
        isRequired,
      ),
      witnessed_overdose: validateWith(isYesOrNoSelected),
      witnessed_overdose_comments: validateWith(
        skipIfOtherField('witnessed_overdose', 'is', 'no'),
        isRequired,
      ),
      naloxone_supply: validateWith(isYesOrNoSelected),
      alcohol_use: validateWith(isYesOrNoSelected),
      alcohol_use_comments: validateWith(skipIfOtherField('alcohol_use', 'is', 'no'), isRequired),
      tobacco_use: validateWith(isYesOrNoSelected),
      tobacco_use_comments: validateWith(skipIfOtherField('tobacco_use', 'is', 'no'), isRequired),
      marijuana_use: validateWith(isYesOrNoSelected),
      marijuana_use_comments: validateWith(
        skipIfOtherField('marijuana_use', 'is', 'no'),
        isRequired,
      ),
      benzodiazepines_use: validateWith(isYesOrNoSelected),
      benzodiazepines_use_comments: validateWith(
        skipIfOtherField('benzodiazepines_use', 'is', 'no'),
        isRequired,
      ),
      amphetamine_use: validateWith(isYesOrNoSelected),
      amphetamine_use_comments: validateWith(
        skipIfOtherField('amphetamine_use', 'is', 'no'),
        isRequired,
      ),
      cocaine_use: validateWith(isYesOrNoSelected),
      cocaine_use_comments: validateWith(skipIfOtherField('cocaine_use', 'is', 'no'), isRequired),
      other_use: validateWith(isYesOrNoSelected),
      other_use_comments: validateWith(skipIfOtherField('other_use', 'is', 'no'), isRequired),
      participating_in_counseling: validateWith(isYesOrNoSelected),
      participating_in_counseling_comments: validateWith(
        skipIfOtherField('participating_in_counseling', 'is', 'no'),
        isRequired,
      ),
      participating_in_recovery_services: validateWith(isYesOrNoSelected),
      participating_in_recovery_services_comments: validateWith(
        skipIfOtherField('participating_in_recovery_services', 'is', 'no'),
        isRequired,
      ),
      observed_and_examination: validateWith(isRequired),
      pdmp_consistent_with_history: validateWith(isYesOrNoSelected),
      pdmp_consistent_with_history_comments: validateWith(
        skipIfOtherField('pdmp_consistent_with_history', 'is', 'yes'),
        isRequired,
      ),
      treatment_goals: validateWith(isRequired),
      assessment: validateWith(isRequired),
      problems_addressed: validateWith(isLength({ length: 1, op: '>=' }, 'Required')),
      medication_name: validateWith(
        skipIfMedicationNotSentViaDosespot,
        isAnySelected(medicationNames(), 'Required'),
      ),
      medication_strength: validateWith(skipIfMedicationNotSentViaDosespot, (value, values) =>
        isAnySelected(medicationName2Strength(values?.medication_name), 'Required')(value),
      ),
      medication_days_supply: validateWith(skipIfMedicationNotSentViaDosespot, isRequired),
      medication_quantity: validateWith(skipIfMedicationNotSentViaDosespot, isRequired),
      induction_email: validateWith(
        skipIfNoDosespotMedication,
        isAnySelected(inductionEmailOptions, 'Required'),
      ),
      heroin_or_fentanyl_regular_basis: validateWith(isYesOrNoSelected),
      suboxone_use_last_week: validateWith(isYesOrNoSelected),
      employee_address: validateWith(skipIfLocationsSectionNotDisplayed, isRequired),
      verified_patient_location: validateWith(skipIfLocationsSectionNotDisplayed, isRequired),
      patient_city: validateWith(skipIfLocationsSectionNotDisplayed, isRequired),
      patient_state: validateWith(
        skipIfLocationsSectionNotDisplayed,
        isRequired,
        isAbbreviatedUsState,
      ),
    },
  })

  const { values, getInputProps, validate, setValues } = form

  const hasOtherStimulantUseDiagnosis = values.problems_addressed.some(
    (prob: ProblemListProblem) => prob?.code?.startsWith('F15'),
  )
  const hasCocaineUseDiagnosis = values.problems_addressed.some(
    (prob: ProblemListProblem) => prob?.code?.startsWith('F14'),
  )
  const hasOudDiagnosis = values.problems_addressed.some(
    (prob: ProblemListProblem) => prob?.code?.startsWith('F11'),
  )

  // We show the SUD section if the flag is enabled or if the section has data
  const sudSectionHasData = Boolean(
    notes?.substanceUse?.opioid?.usedSinceLastVisit !== undefined ||
      notes?.substanceUse?.cocaine?.usedSinceLastVisit !== undefined ||
      notes?.substanceUse?.otherStimulant?.usedSinceLastVisit !== undefined,
  )

  const showSudSection = isSubstanceUseSectionEnabled || (!editMode && sudSectionHasData)
  const substanceUseForm = useSubstanceUseForm({
    validate: getSubstanceUseFormValidator({
      hasOudDiagnosis,
      hasCocaineUseDiagnosis,
      hasOtherStimulantUseDiagnosis,
      sudSectionEnabled: showSudSection,
    }),
    initialValues: convertSudNoteContentToFormData(notes?.substanceUse),
  })

  useEffect(() => {
    if (notes) {
      setValues({
        overdose_history: notes?.overdose_history ?? 'no',
        witnessed_overdose: notes?.witnessed_overdose ?? 'no',
        alcohol_use: notes?.alcohol_use ?? 'no',
        tobacco_use: notes?.tobacco_use ?? 'no',
        marijuana_use: notes?.marijuana_use ?? 'no',
        benzodiazepines_use: notes?.benzodiazepines_use ?? 'no',
        amphetamine_use: notes?.amphetamine_use ?? 'no',
        cocaine_use: notes?.cocaine_use ?? 'no',
        other_use: notes?.other_use ?? 'no',
        naloxone_supply: notes?.naloxone_supply ?? 'no',
        pdmp_consistent_with_history: notes?.pdmp_consistent_with_history ?? 'yes',
        participating_in_counseling: notes?.participating_in_counseling ?? 'no',
        participating_in_recovery_services: notes?.participating_in_recovery_services ?? 'no',
        history_of_overdose: notes?.history_of_overdose ?? 'no',
        medication_name: notes?.medication_name ?? '',
        medication_strength: notes?.medication_strength ?? '',
        medication_days_supply: notes?.medication_days_supply ?? '',
        medication_quantity: notes?.medication_quantity ?? '',
        plan: notes?.plan ?? '',
        induction_email: notes?.induction_email ?? '',
        problems_addressed: notes?.problems_addressed ?? [],
        effective_date: notes?.effective_date ?? '',
        telehealth_consent: notes?.telehealth_consent ?? true,
        history_of_present_illness: notes?.history_of_present_illness ?? '',
        medical_pmh: notes?.medical_pmh ?? '',
        psychiatric_pmh: notes?.psychiatric_pmh ?? '',
        hospitalizations_pmh: notes?.hospitalizations_pmh ?? '',
        surgical_pmh: notes?.surgical_pmh ?? '',
        active_medications_pmh: notes?.active_medications_pmh ?? '',
        allergies_and_reactions_pmh: notes?.allergies_and_reactions_pmh ?? '',
        social_and_family_pmh: notes?.social_and_family_pmh ?? '',
        opioid_history: notes?.opioid_history ?? '',
        opioid_treatment_history: notes?.opioid_treatment_history ?? '',
        overdose_history_comments: notes?.overdose_history_comments ?? '',
        witnessed_overdose_comments: notes?.witnessed_overdose_comments ?? '',
        alcohol_use_comments: notes?.alcohol_use_comments ?? '',
        tobacco_use_comments: notes?.tobacco_use_comments ?? '',
        marijuana_use_comments: notes?.marijuana_use_comments ?? '',
        amphetamine_use_comments: notes?.amphetamine_use_comments ?? '',
        benzodiazepines_use_comments: notes?.benzodiazepines_use_comments ?? '',
        cocaine_use_comments: notes?.cocaine_use_comments ?? '',
        other_use_comments: notes?.other_use_comments ?? '',
        participating_in_counseling_comments: notes?.participating_in_counseling_comments ?? '',
        participating_in_recovery_services_comments:
          notes?.participating_in_recovery_services_comments ?? '',
        observed_and_examination: notes?.observed_and_examination ?? '',
        pdmp_consistent_with_history_comments: notes?.pdmp_consistent_with_history_comments ?? '',
        treatment_goals: notes?.treatment_goals ?? '',
        assessment: notes?.assessment ?? '',
      })
    }
  }, [notes])

  const mentalHealthEvalForm = useMentalHealthEvaluationForm(MENTAL_HEALTH_EVALUATION_FORM_INIT)

  const [contentsToSave, setContentsToSave] = useState<InductionNoteContentForm | null>(values)

  const updateVisitNote = useMutation(
    emrApi.getMutation('PUT /patient/:patientId/visits/:visitId/notes'),
  )
  const recordedMedication = values.medication_name
    ? {
        medication_name: values.medication_name,
        medication_strength: values.medication_strength,
        medication_quantity: values.medication_quantity,
        medication_days_supply: values.medication_days_supply,
      }
    : undefined

  const save = useCallback(async () => {
    const body = {
      type: 'induction' as const,
      content: {
        ...values,
        substanceUse: convertSudFormDataToNoteContent({ formData: substanceUseForm.values }),
      },
      ...(displayMentalHealthEvalSection(notes) && {
        mentalHealthEvaluation: formatMentalHealthEvalResponses(mentalHealthEvalForm.values),
      }),
    }

    await updateVisitNote.mutateAsync(
      {
        params: { patientId: patientID, visitId: visitID ?? '' },
        data: body,
      },
      {
        onSuccess: () => {
          setSavedAt(dayjs())
          setContentsToSave(values)
          void queryClient.invalidateQueries(mentalHealthEvalQueryKey)
          refetchMentalHealthEvals?.()
        },
      },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientID, updateVisitNote, values, visitID])

  useEffect(() => {
    if (!updateVisitNote.isLoading && editMode && !isEqual(values, contentsToSave)) {
      const interval = setTimeout(save, toTime('3 sec').ms())
      return () => clearTimeout(interval)
    }
  }, [values, editMode, contentsToSave, save, updateVisitNote.isLoading])

  useEffect(() => {
    if (values.medication_name) {
      setMedicationSentViaDosespot(true)
    }
  }, [setMedicationSentViaDosespot, values.medication_name])

  const {
    phq8Responses,
    gad7Responses,
    barc10Responses,
    objectiveMeasure,
    workflowState,
    workflowType,
    measuresQueriesAreLoading,
    workflowIsLoading,
    completedMeasures,
    sessionInfoQueriesAreLoading,
    createWorkflowSessionAndOpenDrawer,
    setRecentlySubmittedScore,
    onCloseDrawer,
  } = useMbcSession({
    patientID,
    visitID,
    notes,
    noteType: 'induction',
  })

  const isEligibleForHighRiskInduction =
    values.heroin_or_fentanyl_regular_basis === 'yes' && values.suboxone_use_last_week === 'no'
  const highRiskInductionFlag = isEligibleForHighRiskInduction ? (
    <Alert icon={<FlagIcon />} variant='success'>
      Eligible for high risk induction protocol
    </Alert>
  ) : (
    <Alert icon={<BookmarkIcon />} variant='warning'>
      Not eligible for high risk induction protocol
    </Alert>
  )

  // NOTE: We're adding the patient and clinician location section to visit notes, but want to hide it from visit notes missing this information
  const displayPatientAndClinicianLocationSection = (
    notes: Partial<InductionNoteContent> | null,
  ) => {
    let displayFields = true
    if (!notes?.locked_at) {
      return displayFields
    }

    const patientAndClinicianLocationFields = [
      'employee_address',
      'verified_patient_location',
      'patient_city',
      'patient_state',
    ]

    patientAndClinicianLocationFields.forEach(field => {
      const value = notes[field as keyof InductionNoteContent]
      if (!value) {
        displayFields = false
      }
    })

    return displayFields
  }

  // NOTE: We're adding the mental health evaluation section to visit notes, but want to hide it from visit notes missing this information
  const [mentalHealthEvalQueryKey, mentalHealthEvalQueryFunction] = emrApi.getQuery(
    'GET /visit/:visitId/mental-health-evals',
    {
      params: {
        visitId: visitID || '',
      },
    },
  )

  const mentalHealthEvalQuery = useQuery(mentalHealthEvalQueryKey, mentalHealthEvalQueryFunction, {
    enabled: Boolean(visitID),
  })

  const mentalHealthEvalInThisVisit = mentalHealthEvalQuery.data

  const displayMentalHealthEvalSection = (notes: Partial<InductionNoteContent> | null) => {
    if (sidebar) {
      return false
    }

    /*
     * If the note is not locked, we only want to display the mental health eval section if the patient has not had a mental health eval
     * or the patient's mental health eval is occuring in this visit
     */
    if (!notes?.locked_at) {
      if (mentalHealthEvalInThisVisit) {
        return true
      }
      return Boolean(patientNeedsMentalHealthEval)
    }

    // If the note is locked, we only want to display the mental health eval section if the patient was evaluated in this visit
    return Boolean(mentalHealthEvalInThisVisit)
  }

  useEffect(() => {
    if (mentalHealthEvalInThisVisit) {
      mentalHealthEvalForm.setValues(mentalHealthEvalToFormData(mentalHealthEvalInThisVisit))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentalHealthEvalInThisVisit])

  const saveDiagnoses = useMutation(emrApi.getMutation('POST /patient/:patientId/problemsList'))
  const handleSavingDiagnoses = async ({ diagnoses }: { diagnoses: ProblemListProblem[] }) => {
    await saveDiagnoses.mutateAsync(
      {
        params: { patientId },
        data: { problems: diagnoses },
      },
      {
        onSuccess: () => {
          void problemsListQuery?.refetch()
        },
      },
    )
  }

  const updateDiagnosis = useMutation(
    emrApi.getMutation('PUT /patient/:patientId/diagnosis/:diagnosisId/problemsList'),
  )
  const handleDeactivatingDiagnosis = async ({ diagnosis }: { diagnosis: ProblemListProblem }) => {
    const deactivatedDiagnosis = {
      ...diagnosis,
      status: 'inactive',
      deactivatedAt: dayjs().toISOString(),
    } as ProblemListProblem
    await updateDiagnosis.mutateAsync(
      {
        params: { patientId, diagnosisId: diagnosis.oid || '' },
        data: { diagnosis: deactivatedDiagnosis },
      },
      {
        onSuccess: () => {
          setValues({
            problems_addressed: values.problems_addressed?.filter(
              problem => problem.code !== diagnosis.code,
            ),
          })

          void problemsListQuery?.refetch()
        },
      },
    )
  }

  useEffect(() => {
    handleTobaccoUseDiagnosis(values.problems_addressed)
  }, [values.problems_addressed])

  const canExportNote = currentUser && (isAdmin(currentUser) || isCoordinator(currentUser))

  return (
    <>
      <VisitNoteHeader
        onSubmit={async () => {
          const mentalHealthFormHasErrors = displayMentalHealthEvalSection(notes)
            ? mentalHealthEvalForm.validate().hasErrors
            : false
          const noteHasErrors = validate().hasErrors

          const substanceUseFormHasErrors = substanceUseForm.validate().hasErrors
          if (mentalHealthFormHasErrors || noteHasErrors || substanceUseFormHasErrors) {
            return
          }

          if (medicationSentViaDosespot) {
            setMedicationPrescribed?.(true)
          }

          await save()
          setCloseNoteModal?.(true)
        }}
        error={
          updateVisitNote.status === 'error'
            ? 'Failed to save your last edits. Please try to save again or contact the engineering team.'
            : undefined
        }
        datetime={visit?.datetime}
        sidebar={sidebar}
        save={save}
        isSaving={updateVisitNote.isLoading}
        editMode={editMode}
        savedAt={savedAt}
        lockedAt={notes?.locked_at}
        lockedBy={notes?.locked_by_name}
        meetingDetails={meetingDetails}
        setUnlockNoteModal={setUnlockNoteModal}
        canUnlockNote={hasGroupRole(currentUser, 'engineer')}
        canExportNote={canExportNote}
        exportNoteOnClick={async () => {
          await exportToPdf({
            masterWatcher: values,
            notes,
            type: 'initial visit',
            firstName: patient?.personalData.firstName,
            lastName: patient?.personalData.lastName,
            dateOfBirth: patient?.personalData.birthday,
            userId: patientID,
            datetime: dayjs(visit?.datetime)
              .tz(visit?.timezone)
              .format('dddd, MMMM D, YYYY hh:mma z'),
            visitType: visit?.type,
            lockedByName: closedNoteDetails?.locked_by_name,
            lockedAtTime: getVisitNoteLockedAtString({
              lockedAt: closedNoteDetails?.locked_at,
              timezone: visit?.timezone,
            }),
            patientTime: meetingDetails?.patientTime,
            clinicianTime: meetingDetails?.clinicianTime,
            dosespotMedications,
            addendums,
          })
        }}
      />
      <Stack spacing='lg' mt='md'>
        {visit?.metadata?.highRiskPatient && <HighRiskPatientAlert />}
        <VisitNoteSection title='Introduction'>
          <Stack spacing='sm'>
            {displayPatientAndClinicianLocationSection(notes) && patientAndClinicianLocations && (
              <PatientAndClinicianLocations
                editMode={editMode}
                employeeAddress={{
                  ...form.getInputProps('employee_address'),
                  defaultValue: getDefaultLocationsData({ patient, employee: currentUser })
                    .employee_address,
                }}
                patientCity={form.getInputProps('patient_city')}
                patientState={form.getInputProps('patient_state')}
                verifiedPatientLocation={form.getInputProps('verified_patient_location')}
              />
            )}
            <Text>Telehealth consent</Text>
            <Text size='xs' color={({ text }) => text[1]}>
              Discussed methods, benefits and limitations of telehealth service provision
            </Text>
            <Checkbox
              label='Obtained consent to provide telehealth services'
              {...getInputProps('telehealth_consent', { type: 'checkbox' })}
              disabled={!editMode}
            />
            <VisitNoteTextarea
              label='History of present illness'
              {...getInputProps('history_of_present_illness')}
              isEditing={editMode}
              placeholder='Type here...'
            />
          </Stack>
        </VisitNoteSection>
        <VisitNoteSection title='Past medical history'>
          <Stack spacing='sm'>
            <VisitNoteTextarea
              label='Medical'
              {...getInputProps('medical_pmh')}
              isEditing={editMode}
              placeholder='Type here...'
            />
            <VisitNoteTextarea
              label='Psychiatric'
              {...getInputProps('psychiatric_pmh')}
              isEditing={editMode}
              placeholder='Type here...'
            />
            <VisitNoteTextarea
              label='Hospitalizations'
              {...getInputProps('hospitalizations_pmh')}
              isEditing={editMode}
              placeholder='Type here...'
            />
            <VisitNoteTextarea
              label='Surgical'
              {...getInputProps('surgical_pmh')}
              isEditing={editMode}
              placeholder='Type here...'
            />
            <VisitNoteTextarea
              label='Active medications'
              {...getInputProps('active_medications_pmh')}
              isEditing={editMode}
              placeholder='Type here...'
            />
            <VisitNoteTextarea
              label='Allergies and reactions'
              {...getInputProps('allergies_and_reactions_pmh')}
              isEditing={editMode}
              placeholder='Type here...'
            />
            <VisitNoteTextarea
              label='Social and family'
              {...getInputProps('social_and_family_pmh')}
              isEditing={editMode}
              placeholder='Type here...'
            />
          </Stack>
        </VisitNoteSection>
        <VisitNoteSection title='Opioid use disorder history'>
          <Stack spacing='sm'>
            <VisitNoteTextarea
              minRows={1}
              label='Use history'
              {...getInputProps('opioid_history')}
              isEditing={editMode}
              placeholder="Please include the patient's opioid use history, including quantity, frequency, route, last use, and age of first use..."
            />
            <Stack
              p='md'
              sx={({ other: { colors } }) => ({ backgroundColor: colors.background[1] })}
            >
              <Group noWrap spacing='xs'>
                <FlagIcon color={colors => colors.text[1]} />
                <Text color={colors => colors.text[1]}>High risk assessment</Text>
              </Group>
              <YesOrNoRadio
                label='Is the patient using non-prescription opioids (fentanyl) on a regular basis?'
                isEditing={editMode}
                {...getInputProps('heroin_or_fentanyl_regular_basis')}
              />
              <YesOrNoRadio
                label='Has the patient taken Suboxone in the last week?'
                isEditing={editMode}
                {...getInputProps('suboxone_use_last_week')}
              />
              {highRiskInductionFlag}
            </Stack>
            <VisitNoteTextarea
              minRows={1}
              label='Treatment history'
              {...getInputProps('opioid_treatment_history')}
              isEditing={editMode}
              placeholder='Type here...'
            />
            <YesOrNoRadio
              label='History of overdose'
              {...getInputProps('overdose_history')}
              isEditing={editMode}
            >
              <VisitNoteTextarea
                {...getInputProps('overdose_history_comments')}
                placeholder='Please include any noteworthy details...'
                autosize
                isEditing={editMode}
              />
            </YesOrNoRadio>
            <YesOrNoRadio
              label='Witnessed overdose'
              {...getInputProps('witnessed_overdose')}
              isEditing={editMode}
            >
              <VisitNoteTextarea
                {...getInputProps('witnessed_overdose_comments')}
                placeholder='Please include any noteworthy details...'
                autosize
                isEditing={editMode}
              />
            </YesOrNoRadio>
            <YesOrNoRadio
              label='Does the patient have naloxone?'
              {...getInputProps('naloxone_supply')}
              isEditing={editMode}
            />
          </Stack>
        </VisitNoteSection>
        <VisitNoteSection title='Other substance use history'>
          <Stack spacing='sm'>
            <YesOrNoRadio
              label='Alcohol use'
              {...getInputProps('alcohol_use')}
              isEditing={editMode}
            >
              <VisitNoteTextarea
                {...getInputProps('alcohol_use_comments')}
                placeholder='Please include any noteworthy details...'
                autosize
                isEditing={editMode}
              />
            </YesOrNoRadio>
            <YesOrNoRadio
              label='Tobacco use'
              {...getInputProps('tobacco_use')}
              isEditing={editMode}
            >
              <VisitNoteTextarea
                {...getInputProps('tobacco_use_comments')}
                placeholder='Please include any noteworthy details...'
                autosize
                isEditing={editMode}
              />
            </YesOrNoRadio>
            <YesOrNoRadio
              label='Marijuana use'
              {...getInputProps('marijuana_use')}
              isEditing={editMode}
            >
              <VisitNoteTextarea
                {...getInputProps('marijuana_use_comments')}
                placeholder='Please include any noteworthy details...'
                autosize
                isEditing={editMode}
              />
            </YesOrNoRadio>
            <YesOrNoRadio
              label='Benzodiazepines use'
              {...getInputProps('benzodiazepines_use')}
              isEditing={editMode}
            >
              <VisitNoteTextarea
                {...getInputProps('benzodiazepines_use_comments')}
                placeholder='Please include any noteworthy details...'
                autosize
                isEditing={editMode}
              />
            </YesOrNoRadio>
            <YesOrNoRadio
              label='Amphetamine use'
              {...getInputProps('amphetamine_use')}
              isEditing={editMode}
            >
              <VisitNoteTextarea
                {...getInputProps('amphetamine_use_comments')}
                placeholder='Please include any noteworthy details...'
                autosize
                isEditing={editMode}
              />
            </YesOrNoRadio>
            <YesOrNoRadio
              label='Cocaine use'
              {...getInputProps('cocaine_use')}
              isEditing={editMode}
            >
              <VisitNoteTextarea
                {...getInputProps('cocaine_use_comments')}
                placeholder='Please include any noteworthy details...'
                autosize
                isEditing={editMode}
              />
            </YesOrNoRadio>
            <YesOrNoRadio label='Other use' {...getInputProps('other_use')} isEditing={editMode}>
              <VisitNoteTextarea
                {...getInputProps('other_use_comments')}
                placeholder='Please include any noteworthy details...'
                autosize
                isEditing={editMode}
              />
            </YesOrNoRadio>
          </Stack>
        </VisitNoteSection>
        <VisitNoteSection title='Counseling and recovery services'>
          <Stack spacing='sm'>
            <YesOrNoRadio
              label='Participating in counseling'
              {...getInputProps('participating_in_counseling')}
              isEditing={editMode}
            >
              <VisitNoteTextarea
                {...getInputProps('participating_in_counseling_comments')}
                placeholder='Please include any noteworthy details...'
                autosize
                isEditing={editMode}
              />
            </YesOrNoRadio>
            <YesOrNoRadio
              label='Participating in recovery services'
              {...getInputProps('participating_in_recovery_services')}
              isEditing={editMode}
            >
              <VisitNoteTextarea
                {...getInputProps('participating_in_recovery_services_comments')}
                placeholder='Please include any noteworthy details...'
                autosize
                isEditing={editMode}
              />
            </YesOrNoRadio>
          </Stack>
        </VisitNoteSection>
        <VisitNoteSection title='Observation and examination'>
          <VisitNoteTextarea
            label='Observation and examination'
            {...getInputProps('observed_and_examination')}
            isEditing={editMode}
            placeholder='Type here...'
          />
        </VisitNoteSection>
        <VisitNoteSection title='PDMP'>
          <YesOrNoRadio
            label='Is the PDMP consistent with history?'
            {...getInputProps('pdmp_consistent_with_history')}
            invert
            isEditing={editMode}
          >
            <VisitNoteTextarea
              {...getInputProps('pdmp_consistent_with_history_comments')}
              placeholder='Please include any noteworthy details...'
              autosize
              isEditing={editMode}
            />
          </YesOrNoRadio>
        </VisitNoteSection>
        <VisitNoteSection title='Objective measures'>
          <ObjectiveMeasuresSection
            showCaseReviewAlert={false}
            isLoading={measuresQueriesAreLoading || sessionInfoQueriesAreLoading}
            phqResponses={phq8Responses ?? []}
            gad7Responses={gad7Responses ?? []}
            barc10Responses={barc10Responses ?? []}
            completedMeasures={completedMeasures}
            editMode={editMode}
            lockedAt={notes?.locked_at}
            addMeasureOnClick={measure => createWorkflowSessionAndOpenDrawer(measure)}
            patientId={patientId}
          />
        </VisitNoteSection>
        <VisitNoteSection title='Treatment goals'>
          <VisitNoteTextarea
            label='Treatment goals'
            {...getInputProps('treatment_goals')}
            isEditing={editMode}
            placeholder='Type here...'
          />
        </VisitNoteSection>
        {displayMentalHealthEvalSection(notes) && (
          <MentalHealthEvaluationFormProvider form={mentalHealthEvalForm}>
            <MentalHealthEvalSection
              editMode={editMode}
              onDiagnosisCompleted={diagnosis => {
                // Adds the diagnosis to the list of problems addressed if it's not already there
                const currentProblemsAddressed = [...form.values.problems_addressed]
                const diagnosisAlreadyAddressedIndex = currentProblemsAddressed.findIndex(
                  problem => problem.code === diagnosis.code,
                )
                const diagnosisFromProblemList =
                  diagnosisAlreadyAddressedIndex === -1
                    ? null
                    : currentProblemsAddressed[diagnosisAlreadyAddressedIndex]
                if (diagnosisFromProblemList) {
                  currentProblemsAddressed[diagnosisAlreadyAddressedIndex] = {
                    ...diagnosisFromProblemList,
                    chronicity: capitalize(diagnosis.chronicity),
                    progression: capitalize(diagnosis.progression),
                  }
                  showNotification({
                    message: `${diagnosis.full_description} updated`,
                    variant: 'success',
                  })
                } else {
                  const formattedDiagnosis = {
                    code: diagnosis.code,
                    full_description: diagnosis.full_description,
                    chronicity: capitalize(diagnosis.chronicity),
                    progression: capitalize(diagnosis.progression),
                    status: 'addressed',
                  } as ProblemListProblem
                  currentProblemsAddressed.push(formattedDiagnosis)
                  void handleSavingDiagnoses({ diagnoses: [formattedDiagnosis] })
                  showNotification({
                    message: `${diagnosis.full_description} added to the patient's diagnosis list`,
                    variant: 'success',
                  })
                }
                form.setFieldValue('problems_addressed', currentProblemsAddressed)
              }}
            />
          </MentalHealthEvaluationFormProvider>
        )}
        <VisitNoteSection title='Diagnoses'>
          <DiagnosisSection
            {...getInputProps('problems_addressed')}
            editable={editMode}
            saveDiagnoses={handleSavingDiagnoses}
            deactivateDiagnosis={handleDeactivatingDiagnosis}
          />
        </VisitNoteSection>
        {showSudSection && (
          <SubstanceUseFormProvider form={substanceUseForm}>
            <SubstanceUseSection
              hasOudDiagnosis={hasOudDiagnosis}
              hasCocaineUseDiagnosis={hasCocaineUseDiagnosis}
              hasOtherStimulantUseDiagnosis={hasOtherStimulantUseDiagnosis}
              isEditing={editMode}
              isIntake
            />
          </SubstanceUseFormProvider>
        )}
        <VisitNoteSection title='Assessment and plan'>
          <Stack spacing='sm'>
            {editMode && (
              <Text>
                Please indicate your assessment as well as a plan for each problem addressed. If you
                do not have a plan for a particular problem, please write N/A.
              </Text>
            )}
            <VisitNoteTextarea
              label='Assessment'
              {...getInputProps('assessment')}
              isEditing={editMode}
              placeholder='Type here...'
            />
            {values.problems_addressed.length > 0 &&
              values.problems_addressed.map(problem => (
                <VisitNoteTextarea
                  isEditing={editMode}
                  label={`Plan - ${problem.full_description}`}
                  key={problem.code}
                  placeholder='Type here...'
                  {...getInputProps(getDiagnoseKey(problem.full_description))}
                />
              ))}
          </Stack>
        </VisitNoteSection>
        <VisitNoteSection title='Medication'>
          <Stack spacing='sm'>
            <Text color={colors => colors.text[editMode ? 3 : 1]}>Medication</Text>
            {highRiskInductionFlag}
            <MVisitNotePrescriptionSection
              visitId={Number(visitID)}
              patientId={patientID}
              isLocked={Boolean(closedNoteDetails)}
              editMode={editMode}
              setMedicationPrescribed={setMedicationPrescribed}
              recordedMedication={recordedMedication}
              medicationSentViaDosespot={medicationSentViaDosespot}
              setPrescriptionBanner={setPrescriptionBanner}
              setMedicationSentViaDosespot={value => {
                setMedicationSentViaDosespot(value)
                setValues({
                  medication_name: '',
                  medication_quantity: '',
                  medication_strength: '',
                  medication_days_supply: '',
                })
              }}
              setDosespotMedications={setDosespotMedications}
            />
            {hasDosespotMedication && (
              <VisitNoteSelect
                label='Induction Email'
                {...getInputProps('induction_email')}
                isEditing={editMode}
                placeholder='Select one...'
                data={inductionEmailOptions}
                itemComponent={SelectItem}
              />
            )}
            {medicationSentViaDosespot && editMode && (
              <Grid gutter='sm'>
                <Grid.Col span={12}>
                  <Select
                    label='Medication Name'
                    {...getInputProps('medication_name')}
                    data={medicationNames()}
                    disabled={!editMode}
                    placeholder='Select one...'
                  />
                </Grid.Col>
                <Grid.Col span={12} sx={{ display: values.medication_name ? 'block' : 'none' }}>
                  <Select
                    label='Medication Strength'
                    {...getInputProps('medication_strength')}
                    data={medicationName2Strength(values.medication_name) ?? []}
                    disabled={!editMode}
                    placeholder='Select one...'
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label='Medication Quantity'
                    {...getInputProps('medication_quantity')}
                    disabled={!editMode}
                    formatter={validCharacters.numbers}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label='Medication Days Supply'
                    {...getInputProps('medication_days_supply')}
                    disabled={!editMode}
                    formatter={validCharacters.numbers}
                  />
                </Grid.Col>
              </Grid>
            )}
            <UpcomingVisitsSection isNoteClosed={Boolean(closedNoteDetails)} isSidebar={sidebar} />
          </Stack>
        </VisitNoteSection>
      </Stack>
      <ObjectiveMeasuresFormCollectionDrawer
        patientId={patientID}
        measure={objectiveMeasure}
        workflowState={workflowState}
        workflowType={workflowType}
        isLoading={workflowIsLoading}
        onClose={onCloseDrawer}
        setRecentlySubmittedScore={setRecentlySubmittedScore}
      />
    </>
  )
}
