/* eslint-disable @typescript-eslint/no-explicit-any */

import { useForm as useMantineForm } from '@mantine/form'
import {
  ClipboardIcon,
  DownloadIcon,
  Group,
  isLength,
  PrimaryButton,
  SecondaryButton,
  showNotification,
  Space,
  Stack,
  Tooltip,
  UnlockIcon,
  UserCheckIcon,
  validateWith,
} from '@shared/components'
import {
  Appointment,
  hasGroupRole,
  hasRole,
  INVALID_CPT_CODE,
  isAdmin,
  isCoordinator,
  PrescriptionResponse,
  ProblemListProblem,
  VisitNoteContent,
} from '@shared/types'
import { dayjs, getVisitNoteLockedAtString, toTime } from '@shared/utils'
import cn from 'classnames'
import capitalize from 'lodash/capitalize'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { emrApi } from '../../../api'
import ANoteCheckboxPositiveInput from '../../../components/atoms/ANoteCheckboxPositiveInput'
import ANoteDropdown from '../../../components/atoms/ANoteDropdown'
import ANoteHeader from '../../../components/atoms/ANoteHeader'
import ANoteModalityInput from '../../../components/atoms/ANoteModalityInput'
import ANoteSection from '../../../components/atoms/ANoteSection'
import ANoteSectionContent from '../../../components/atoms/ANoteSectionContent'
import ANoteSubheader from '../../../components/atoms/ANoteSubheader'
import ANoteTextArea from '../../../components/atoms/ANoteTextArea'
import ANoteTextInput from '../../../components/atoms/ANoteTextInput'
import ANoteYesNoRadioInput from '../../../components/atoms/ANoteYesNoRadioInput'
import { DiagnosisSection } from '../../../components/forms/DiagnosisSection'
import { required } from '../../../components/molecules/MDictionary'
import { useAuth } from '../../../context/auth'
import { isPatientRecommendedForCaseReview } from '../../../utils/cocmUtils'
import { isAbbreviatedUsState, isRequired } from '../../../utils/formValidation'
import { PageTrace, useEmrQuery, useFlags } from '../../../utils/hooks'
import { useMbcSession } from '../../../utils/hooks/use-mbc-session'
import { ReactHookFormErrorsObject } from '../../../utils/reactHookForm'
import {
  drugTestVariables,
  exportToPdf,
  isNotAbleToWriteToNote,
  medicationName2Strength,
  medicationNames,
  medicationVariables,
  renderDurationString,
} from '../../../utils/utils'
import { ObjectiveMeasuresSection } from '../../registry/objectiveMeasures/ObjectiveMeasuresSection'
import ObjectiveMeasuresFormCollectionDrawer from '../forms/ObjectiveMeasuresFormCollectionDrawer'
import { usePatient } from '../PPatientContext'
import { DischargeNotesAlert, mustCompleteDischargeNote } from './DischargeVisitDrawer'
import {
  formatMentalHealthEvalResponses,
  MENTAL_HEALTH_EVALUATION_FORM_INIT,
  mentalHealthEvalToFormData,
  MentalHealthEvaluationFormProvider,
  useMentalHealthEvaluationForm,
} from './formHelpers'
import { MentalHealthEvalSection } from './MentalHealthEvalSection'
import MVisitNotePrescriptionSection from './MVisitNotePrescriptionSection'
import MVisitNoteSavingHeader from './MVisitNoteSavingHeader'
import {
  getDefaultLocationsData,
  PatientAndClinicianLocations,
} from './PatientAndClinicianLocations'
import {
  convertSudFormDataToNoteContent,
  convertSudNoteContentToFormData,
  getSubstanceUseFormValidator,
  SubstanceUseFormProvider,
  SubstanceUseSection,
  useSubstanceUseForm,
} from './SubstanceUseSection'
import UpcomingVisitsSection from './UpcomingVisitsSection'
import { VisitNoteSection } from './VisitNoteSection'

const defaultValues: VisitNoteContent = {
  participating_in_counseling: 'no',
  participating_in_recovery_services: 'no',
  patient_perception_of_effectiveness: 'yes',
  patient_desire_of_dose_change: 'no',
  adherent: 'yes',
  cravings_or_withdrawal: 'no',
  concerning_behavior: 'no',
  medication_prescribed: 'yes',
  drug_screen_administered: 'no',
  drug_screen_buprenorphine_result: false,
  drug_screen_tramadol_result: false,
  drug_screen_marijuana_result: false,
  drug_screen_phencyclidine_result: false,
  drug_screen_oxycodone_result: false,
  drug_screen_opiates_result: false,
  drug_screen_methadone_result: false,
  drug_screen_methylenedioxymethamphetamine_result: false,
  drug_screen_methamphetamine_result: false,
  drug_screen_synthetic_cannabinoid_result: false,
  drug_screen_fentanyl_result: false,
  drug_screen_alcohol_result: false,
  drug_screen_cocaine_result: false,
  drug_screen_amphetamines_result: false,
  drug_screen_barbiturates_result: false,
  drug_screen_benzodiazepines_result: false,
  pdmp_consistent_with_history: 'yes',
  modality: 'video',
}

const OMaintenanceVisitForm = ({
  patientID,
  notes,
  editMode,
  sidebar,
  closedNoteDetails,
  setShowCaseReviewNote,
  setPrescriptionBanner,
  visit,
  setCloseNoteModal,
  setShowAttestationModal,
  patient,
  meetingDetails,
  setUnlockNoteModal,
  setMedicationPrescribed,
  patientNeedsMentalHealthEval,
  refetchMentalHealthEvals,
  handleTobaccoUseDiagnosis,
  addendums,
  openDischargeNotesDrawer,
  appointment,
  endPageTrace,
}: {
  setShowCaseReviewNote: (() => void) | null
  setPrescriptionBanner?: (message: string, type: 'success' | 'warning' | 'error') => void
  patientID: string
  notes: any
  editMode: boolean
  sidebar: boolean
  closedNoteDetails: any
  visit: any
  setCloseNoteModal: any
  setShowAttestationModal: any
  patient: any
  meetingDetails: any
  setUnlockNoteModal: any
  setMedicationPrescribed: any
  patientNeedsMentalHealthEval?: boolean
  refetchMentalHealthEvals?: () => void
  appointment?: Appointment
  handleTobaccoUseDiagnosis: (diagnoses: ProblemListProblem[]) => void
  openDischargeNotesDrawer: (() => void) | null
  addendums?: Record<string, any>
  endPageTrace?: PageTrace['end']
}) => {
  const { patientAndClinicianLocations, revampedCaseReviewNotes, isSubstanceUseSectionEnabled } =
    useFlags()
  const queryClient = useQueryClient()
  const { problemsListQuery, patientId } = usePatient()

  const { errors, register, handleSubmit, setValue, watch, getValues } = useForm({
    defaultValues,
  })
  const { currentUser } = useAuth()
  const [errorMessage, setErrorMessage] = useState('')
  const [savedAt, setSavedAt] = useState<string | null>(null)
  const [saving, setSaving] = useState(false)
  const [contentsToSave, setContentsToSave] = useState<any>(null)
  const [medicationSentViaDosespot, setMedicationSentViaDosespot] = useState(false)
  const [dosespotMedications, setDosespotMedications] = useState<
    PrescriptionResponse[] | undefined
  >()
  const [medicationStrengthDefaultValue, setMedicationStrengthDefaultValue] = useState('')
  const masterWatcher = watch()
  const drug_screen_administered = watch('drug_screen_administered')
  const medication_prescribed = watch('medication_prescribed')
  const participating_in_counseling = watch('participating_in_counseling')
  const participating_in_recovery_services = watch('participating_in_recovery_services')
  const patient_perception_of_effectiveness = watch('patient_perception_of_effectiveness')
  const patient_desire_of_dose_change = watch('patient_desire_of_dose_change')
  const adherent = watch('adherent')
  const cravings_or_withdrawal = watch('cravings_or_withdrawal')
  const concerning_behavior = watch('concerning_behavior')
  const medication_name = watch('medication_name')
  const medication_strength = watch('medication_strength')
  const medication_days_supply = watch('medication_days_supply')
  const medication_quantity = watch('medication_quantity')
  const pdmp_consistent_with_history = watch('pdmp_consistent_with_history')
  const modality = watch('modality')
  const plan = watch('plan')
  const problemsAddressed = getValues('problems_addressed') || []
  const { data: patientCocmItem } = useEmrQuery('GET /cocmRegistry/patient/:patientId', {
    params: {
      patientId: patientID,
    },
  })

  const skipIfLocationsSectionNotDisplayed = () => !patientAndClinicianLocations
  const locationsForm = useMantineForm({
    initialValues: getDefaultLocationsData({ patient, employee: currentUser }),
    validate: {
      employee_address: validateWith(skipIfLocationsSectionNotDisplayed, isRequired),
      verified_patient_location: validateWith(skipIfLocationsSectionNotDisplayed, isRequired),
      patient_city: validateWith(skipIfLocationsSectionNotDisplayed, isRequired),
      patient_state: validateWith(
        skipIfLocationsSectionNotDisplayed,
        isRequired,
        isAbbreviatedUsState,
      ),
    },
  })

  const diagnosesForm = useMantineForm({
    initialValues: { diagnoses: notes.problems_addressed || [] },
    validate: {
      diagnoses: validateWith(isLength({ length: 1, op: '>=' }, 'Required')),
    },
  })

  const mentalHealthEvalForm = useMentalHealthEvaluationForm(MENTAL_HEALTH_EVALUATION_FORM_INIT)

  const hasOtherStimulantUseDiagnosis = diagnosesForm.values.diagnoses.some(
    (prob: ProblemListProblem) => prob?.code?.startsWith('F15'),
  )
  const hasCocaineUseDiagnosis = diagnosesForm.values.diagnoses.some(
    (prob: ProblemListProblem) => prob?.code?.startsWith('F14'),
  )
  const hasOudDiagnosis = diagnosesForm.values.diagnoses.some(
    (prob: ProblemListProblem) => prob?.code?.startsWith('F11'),
  )

  // We show the SUD section if the flag is enabled or if the section has data
  const sudSectionHasData = Boolean(
    notes?.substanceUse?.opioid?.usedSinceLastVisit !== undefined ||
      notes?.substanceUse?.cocaine?.usedSinceLastVisit !== undefined ||
      notes?.substanceUse?.otherStimulant?.usedSinceLastVisit !== undefined,
  )

  const showSudSection = isSubstanceUseSectionEnabled || (!editMode && sudSectionHasData)

  const substanceUseForm = useSubstanceUseForm({
    validate: getSubstanceUseFormValidator({
      hasOudDiagnosis,
      hasCocaineUseDiagnosis,
      hasOtherStimulantUseDiagnosis,
      sudSectionEnabled: isSubstanceUseSectionEnabled,
    }),
    initialValues: convertSudNoteContentToFormData(notes?.substanceUse),
  })

  const updateVisitNote = useMutation(
    emrApi.getMutation('PUT /patient/:patientId/visits/:visitId/notes'),
  )

  const recordedMedication = notes.medication_name
    ? {
        updatedAt: notes.updatedAt,
        medication_name: medication_name || notes.medication_name,
        medication_strength: medication_strength || notes.medication_strength,
        medication_days_supply: medication_days_supply || notes.medication_days_supply,
        medication_quantity: medication_quantity || notes.medication_quantity,
      }
    : undefined

  useEffect(() => {
    if (!notes) {
      return
    }
    if (notes.medication_strength) {
      setMedicationStrengthDefaultValue(notes.medication_strength)
    }

    if (notes.problems_addressed) {
      diagnosesForm.setFieldValue('diagnoses', notes.problems_addressed)
    }

    for (const [key, value] of Object.entries(notes)) {
      if (drugTestVariables.includes(key)) {
        setValue(key, value === 'positive')
      } else {
        if (key === 'plan' && editMode) {
          continue
        }
        setValue(key, value)
      }
    }
  }, [notes, setValue, editMode])

  const save = useCallback(async () => {
    if (isNotAbleToWriteToNote(currentUser.calendarId, visit)) {
      return
    }

    let content = { ...masterWatcher } as any

    if (masterWatcher.drug_screen_administered === 'no') {
      for (const key of drugTestVariables) {
        content = omit(content, key) as typeof content
      }
    }

    if (masterWatcher.medication_prescribed === 'no') {
      for (const key of medicationVariables) {
        content = omit(content, key) as typeof content
      }
    }

    if (masterWatcher.drug_screen_administered === 'yes') {
      for (const key of drugTestVariables) {
        content[key] = masterWatcher[key] ? 'positive' : 'negative'
      }
    }

    content.employee_address = locationsForm.values.employee_address
    content.verified_patient_location = locationsForm.values.verified_patient_location
    content.patient_city = locationsForm.values.patient_city
    content.patient_state = locationsForm.values.patient_state
    content.problems_addressed = diagnosesForm.values.diagnoses
    content.substanceUse = convertSudFormDataToNoteContent({ formData: substanceUseForm.values })

    const body = {
      type: 'maintenance' as const,
      content,
      ...(displayMentalHealthEvalSection(notes) && {
        mentalHealthEvaluation: formatMentalHealthEvalResponses(mentalHealthEvalForm.values),
      }),
    }

    await updateVisitNote.mutateAsync(
      {
        params: {
          patientId: patientID,
          visitId: visit.id,
        },
        data: body,
      },
      {
        onSuccess: () => {
          setSavedAt(dayjs().format('MMMM Do YYYY, h:mm:ss a'))
          setErrorMessage('')
          setContentsToSave(masterWatcher)
          void queryClient.invalidateQueries(mentalHealthEvalQueryKey)
          refetchMentalHealthEvals?.()
        },
        onError: () => {
          setErrorMessage(
            'Failed to save your last edits. Please try to save again or contact the engineering team.',
          )
          setCloseNoteModal(false)
        },
      },
    )
  }, [editMode, patientID, visit, masterWatcher, currentUser, setCloseNoteModal])

  useEffect(() => {
    const interval = setTimeout(async () => {
      if (!editMode) {
        return
      }
      if (isEmpty(masterWatcher)) {
        return
      }
      if (isEqual(masterWatcher, contentsToSave)) {
        return
      }

      await save()
    }, toTime('3 sec').ms())

    return () => clearTimeout(interval)
  }, [masterWatcher, editMode, save, contentsToSave])

  useEffect(() => {
    const timer = setTimeout(() => setSaving(false), toTime('3 sec').ms())
    return () => clearTimeout(timer)
  }, [saving])

  const getRequired = (variable: any, value: any) => {
    if (variable === value) {
      return true
    }

    return false
  }

  const onSubmit = async () => {
    if (diagnosesForm.validate().hasErrors) {
      return
    }

    if (locationsForm.validate().hasErrors) {
      return
    }

    if (substanceUseForm.validate().hasErrors) {
      return
    }

    if (displayMentalHealthEvalSection(notes) && mentalHealthEvalForm.validate().hasErrors) {
      return
    }

    if (medicationSentViaDosespot && medication_name) {
      setMedicationPrescribed(true)
    }
    setCloseNoteModal(true)
    setSaving(true)
    await save()
  }

  const dateSigned = notes?.locked_at ? dayjs(notes.locked_at) : undefined
  const dateSignedFormatted = dateSigned?.isValid()
    ? dateSigned?.format('MMMM D, YYYY [at] h:mm a')
    : notes?.locked_at

  const {
    phq8Responses,
    gad7Responses,
    barc10Responses,
    objectiveMeasure,
    workflowState,
    workflowType,
    measuresQueriesAreLoading,
    workflowIsLoading,
    completedMeasures,
    sessionInfoQueriesAreLoading,
    createWorkflowSessionAndOpenDrawer,
    setRecentlySubmittedScore,
    onCloseDrawer,
  } = useMbcSession({
    patientID,
    visitID: visit?.id,
    notes,
    noteType: 'maintenance',
  })

  // NOTE: We're adding the patient and clinician location section to visit notes, but want to hide it from visit notes missing this information
  const displayPatientAndClinicianLocationSection = (notes: any) => {
    let displayFields = true
    if (!notes?.locked_at) {
      return displayFields
    }

    const patientAndClinicianLocationFields = [
      'employee_address',
      'verified_patient_location',
      'patient_city',
      'patient_state',
    ]

    patientAndClinicianLocationFields.forEach(field => {
      const value = notes[field]
      if (!value) {
        displayFields = false
      }
    })

    return displayFields
  }

  // NOTE: We're adding the mental health evaluation section to visit notes, but want to hide it from visit notes missing this information
  const [mentalHealthEvalQueryKey, mentalHealthEvalQueryFunction] = emrApi.getQuery(
    'GET /visit/:visitId/mental-health-evals',
    {
      params: {
        visitId: visit?.id || '',
      },
    },
  )

  const mentalHealthEvalQuery = useQuery(mentalHealthEvalQueryKey, mentalHealthEvalQueryFunction, {
    enabled: Boolean(visit?.id),
  })

  const mentalHealthEvalInThisVisit = mentalHealthEvalQuery.data

  const caseReviewNoteForVisitQuery = useEmrQuery(
    'GET /caseReviewNote/visit/:visitId',
    {
      params: {
        visitId: visit?.id,
      },
    },
    {
      enabled: Boolean(visit?.id),
    },
  )

  const showCaseReviewNoteButton =
    caseReviewNoteForVisitQuery.data && revampedCaseReviewNotes && !editMode

  const displayMentalHealthEvalSection = (notes: any) => {
    if (sidebar) {
      return false
    }

    /*
     * If the note is not locked, we only want to display the mental health eval section if the patient has not had a mental health eval
     * or the patient's mental health eval is occuring in this visit
     */
    if (!notes?.locked_at) {
      if (mentalHealthEvalInThisVisit) {
        return true
      }
      return patientNeedsMentalHealthEval
    }

    // If the note is locked, we only want to display the mental health eval section if the patient was evaluated in this visit
    return Boolean(mentalHealthEvalInThisVisit)
  }

  useEffect(() => {
    if (mentalHealthEvalInThisVisit) {
      mentalHealthEvalForm.setValues(mentalHealthEvalToFormData(mentalHealthEvalInThisVisit))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentalHealthEvalInThisVisit])

  const saveDiagnoses = useMutation(emrApi.getMutation('POST /patient/:patientId/problemsList'))
  const handleSavingDiagnoses = async ({ diagnoses }: { diagnoses: ProblemListProblem[] }) => {
    await saveDiagnoses.mutateAsync(
      {
        params: { patientId },
        data: { problems: diagnoses },
      },
      {
        onSuccess: () => {
          void problemsListQuery?.refetch()
        },
      },
    )
  }

  const updateDiagnosis = useMutation(
    emrApi.getMutation('PUT /patient/:patientId/diagnosis/:diagnosisId/problemsList'),
  )
  const handleDeactivatingDiagnosis = async ({ diagnosis }: { diagnosis: ProblemListProblem }) => {
    const deactivatedDiagnosis = {
      ...diagnosis,
      status: 'inactive',
      deactivatedAt: dayjs().toISOString(),
    } as ProblemListProblem
    await updateDiagnosis.mutateAsync(
      {
        params: { patientId, diagnosisId: diagnosis.oid || '' },
        data: { diagnosis: deactivatedDiagnosis },
      },
      {
        onSuccess: () => {
          diagnosesForm.setValues({
            diagnoses: diagnosesForm.values.diagnoses?.filter(
              (d: ProblemListProblem) => d.code !== diagnosis.code,
            ),
          })

          void problemsListQuery?.refetch()
        },
      },
    )
  }

  useEffect(() => {
    handleTobaccoUseDiagnosis(diagnosesForm.values.diagnoses)
  }, [diagnosesForm.values.diagnoses])

  const isCurrentUserPatientsBhcm = currentUser.oid === patientCocmItem?.employeeId

  const showCaseReviewAlert =
    !sidebar &&
    revampedCaseReviewNotes &&
    isCurrentUserPatientsBhcm &&
    isPatientRecommendedForCaseReview(patientCocmItem)

  endPageTrace?.({ pageName: 'Follow-Up Visit' })

  return (
    <>
      <form className='w-full' onSubmit={handleSubmit(onSubmit)}>
        {sidebar && notes && dateSigned && (
          <p className='mt-3 text-xs text-gray-500'>
            {`Signed on ${dateSignedFormatted} by ${notes?.locked_by_name}`}
            <br />
          </p>
        )}
        {!sidebar && (
          <div className='sticky top-0 bg-white border-b border-gray-200 h-16 w-full z-50'>
            <div className='flex flex-row h-full justify-between'>
              <div className='flex justify-start items-center flex-grow-1 flex-shrink-0'>
                <div className='flex flex-col'>
                  {errorMessage && editMode && (
                    <p className='text-xs text-red-800 font-semibold'>{errorMessage}</p>
                  )}
                  {savedAt && editMode && (
                    <p className='text-xs text-gray-500'>Last saved at: {savedAt}</p>
                  )}
                  {!editMode && closedNoteDetails && (
                    <p className='text-xs text-gray-500'>
                      {`Signed on ${dateSignedFormatted} by ${notes?.locked_by_name}`}
                      <br />
                      {renderDurationString({
                        clinicianTime: meetingDetails?.clinicianTime,
                        patientTime: meetingDetails?.patientTime,
                      })}
                    </p>
                  )}
                  {closedNoteDetails?.attestation &&
                    !closedNoteDetails.attestation.attestation_notes && (
                      <p className='text-xs text-gray-500'>
                        Attested on{' '}
                        {closedNoteDetails.attestation.attested_at.slice(
                          0,
                          closedNoteDetails.attestation.attested_at.lastIndexOf(','),
                        )}{' '}
                        at{' '}
                        {closedNoteDetails.attestation.attested_at.slice(
                          closedNoteDetails.attestation.attested_at.lastIndexOf(',') + 1,
                        )}{' '}
                        by {closedNoteDetails.attestation.attested_by_display_name}
                      </p>
                    )}
                </div>
              </div>
              {editMode && (
                <MVisitNoteSavingHeader
                  dateTime={visit.datetime}
                  saveNote={async () => {
                    setSaving(true)
                    await save()
                  }}
                  saving={saving}
                  editMode={editMode}
                  invalidPatientStatuses={['lead', 'candidate']}
                  mustCompleteDischargeNote={mustCompleteDischargeNote({
                    patient,
                    appointment,
                    visitNoteContent: notes,
                  })}
                  hasInvalidDiagnosis={problemsAddressed.some(
                    (diagnosis: any) => diagnosis.code === INVALID_CPT_CODE,
                  )}
                />
              )}
              <Group spacing='sm'>
                {showCaseReviewNoteButton && setShowCaseReviewNote && (
                  <SecondaryButton onClick={setShowCaseReviewNote} leftIcon={<ClipboardIcon />}>
                    Case review note
                  </SecondaryButton>
                )}
                {(isAdmin(currentUser) || isCoordinator(currentUser)) && !editMode && (
                  <>
                    {closedNoteDetails && hasGroupRole(currentUser, 'engineer') && (
                      <PrimaryButton
                        leftIcon={<UnlockIcon />}
                        onClick={e => {
                          e.stopPropagation()
                          setUnlockNoteModal(true)
                        }}
                      >
                        Unlock
                      </PrimaryButton>
                    )}
                    <Tooltip
                      label={closedNoteDetails ? 'Export to PDF' : 'Cannot download unlocked notes'}
                    >
                      <SecondaryButton
                        leftIcon={<DownloadIcon />}
                        onClick={async e => {
                          e.stopPropagation()
                          await exportToPdf({
                            masterWatcher,
                            notes,
                            type: 'maintenance',
                            firstName: patient.personalData.firstName,
                            lastName: patient.personalData.lastName,
                            dateOfBirth: patient?.personalData.birthday,
                            userId: patientID,
                            datetime: dayjs(visit.datetime)
                              .tz(visit.timezone)
                              .format('dddd, MMMM D, YYYY hh:mmA'),
                            visitType: visit.type,
                            lockedByName: closedNoteDetails.locked_by_name,
                            lockedAtTime: getVisitNoteLockedAtString({
                              lockedAt: closedNoteDetails?.locked_at,
                              timezone: visit?.timezone,
                            }),
                            patientTime: meetingDetails?.patientTime,
                            clinicianTime: meetingDetails.clinicianTime,
                            dosespotMedications,
                            addendums,
                          })
                        }}
                        disabled={!closedNoteDetails}
                      />
                    </Tooltip>
                  </>
                )}
              </Group>
              {!editMode &&
                closedNoteDetails &&
                !closedNoteDetails.attestation &&
                hasRole(currentUser, 'pc', 'spc') &&
                currentUser.oid !== closedNoteDetails.locked_by_user_id && (
                  <Group position='right'>
                    <PrimaryButton
                      leftIcon={<UserCheckIcon />}
                      onClick={() => setShowAttestationModal(true)}
                    >
                      Attest note
                    </PrimaryButton>
                  </Group>
                )}
            </div>
            <DischargeNotesAlert
              patient={patient}
              visitNoteContent={notes}
              appointment={appointment}
              openDischargeNotesDrawer={openDischargeNotesDrawer}
            />
          </div>
        )}
        <ANoteSection hideBorder>
          <Stack spacing='sm'>
            {displayPatientAndClinicianLocationSection(notes) && patientAndClinicianLocations && (
              <>
                <ANoteHeader text='Introduction' />
                <PatientAndClinicianLocations
                  editMode={editMode}
                  employeeAddress={{
                    ...locationsForm.getInputProps('employee_address'),
                    defaultValue: getDefaultLocationsData({ patient, employee: currentUser })
                      .employee_address,
                  }}
                  patientCity={locationsForm.getInputProps('patient_city')}
                  patientState={locationsForm.getInputProps('patient_state')}
                  verifiedPatientLocation={locationsForm.getInputProps('verified_patient_location')}
                />
              </>
            )}
          </Stack>
          <ANoteSectionContent className='grid gap-y-6 gap-x-4 grid-cols-6'>
            <ANoteModalityInput
              name='modality'
              id='modality'
              ref={register({ required: { value: true, message: required } })}
              label='How did you conduct this visit?'
              className='mt-4 col-span-6'
              disabled={!editMode}
              error={errors}
              sublabel=''
            />
            <ANoteDropdown
              name='modality_justification'
              id='modality_justification'
              className={`${modality === 'phone' ? '' : 'hidden'} ml-4`}
              ref={register({
                required: { value: getRequired(modality, 'phone'), message: required },
              })}
              label='Phone Justification'
              options={['Connectivity Issue', 'Patient Preference']}
              naValue
              disabled={!editMode}
              error={errors}
            />
          </ANoteSectionContent>
        </ANoteSection>
        <VisitNoteSection title='Objective measures'>
          <ObjectiveMeasuresSection
            showCaseReviewAlert={Boolean(showCaseReviewAlert)}
            isLoading={measuresQueriesAreLoading || sessionInfoQueriesAreLoading}
            phqResponses={phq8Responses ?? []}
            gad7Responses={gad7Responses ?? []}
            barc10Responses={barc10Responses ?? []}
            completedMeasures={completedMeasures}
            editMode={editMode}
            lockedAt={notes?.locked_at}
            addMeasureOnClick={measure => createWorkflowSessionAndOpenDrawer(measure)}
            patientId={patientId}
          />
        </VisitNoteSection>

        <ANoteSection>
          <ANoteHeader text='History of present illness' />
          <ANoteSectionContent className='grid gap-y-6 gap-x-4 grid-cols-6'>
            <ANoteTextArea
              name='history_of_present_illness'
              id='history_of_present_illness'
              minRows={2}
              ref={register({ required: { value: true, message: required } })}
              disabled={!editMode}
              error={errors as ReactHookFormErrorsObject}
              sidebar={sidebar}
            />
          </ANoteSectionContent>
        </ANoteSection>

        <ANoteSection>
          <ANoteHeader
            text='Past medical history'
            description="The total sum of a patient's health status prior to the presenting problem."
          />
          <ANoteSectionContent>
            <ANoteTextArea
              label='Medical'
              name='medical_pmh'
              id='medical_pmh'
              minRows={2}
              ref={register({ required: { value: true, message: required } })}
              className='mt-4'
              disabled={!editMode}
              error={errors as ReactHookFormErrorsObject}
              sidebar={sidebar}
            />
            <ANoteTextArea
              label='Psychiatric'
              name='psychiatric_pmh'
              id='psychiatric_pmh'
              minRows={2}
              ref={register({ required: { value: true, message: required } })}
              className='mt-4'
              disabled={!editMode}
              error={errors as ReactHookFormErrorsObject}
              sidebar={sidebar}
            />
            <ANoteTextArea
              label='Hospitalizations'
              name='hospitalizations_pmh'
              id='hospitalizations_pmh'
              minRows={2}
              ref={register({ required: { value: true, message: required } })}
              className='mt-4'
              disabled={!editMode}
              error={errors as ReactHookFormErrorsObject}
              sidebar={sidebar}
            />
            <ANoteTextArea
              label='Surgical'
              name='surgical_pmh'
              id='surgical_pmh'
              minRows={2}
              ref={register({ required: { value: true, message: required } })}
              className='mt-4'
              disabled={!editMode}
              error={errors as ReactHookFormErrorsObject}
              sidebar={sidebar}
            />
            <ANoteTextArea
              label='Active medications'
              name='active_medications_pmh'
              id='active_medications_pmh'
              minRows={2}
              ref={register({ required: { value: true, message: required } })}
              className='mt-4'
              disabled={!editMode}
              error={errors as ReactHookFormErrorsObject}
              sidebar={sidebar}
            />
            <ANoteTextArea
              label='Allergies and reactions'
              name='allergies_and_reactions_pmh'
              id='allergies_and_reactions_pmh'
              minRows={2}
              ref={register({ required: { value: true, message: required } })}
              className='mt-4'
              disabled={!editMode}
              error={errors as ReactHookFormErrorsObject}
              sidebar={sidebar}
            />
            <ANoteTextArea
              label='Social and family'
              name='social_and_family_pmh'
              id='social_and_family_pmh'
              minRows={2}
              ref={register({ required: { value: true, message: required } })}
              className='mt-4'
              disabled={!editMode}
              error={errors as ReactHookFormErrorsObject}
              sidebar={sidebar}
            />
          </ANoteSectionContent>
        </ANoteSection>
        <ANoteSection>
          <ANoteHeader
            text='Response to treatment'
            description='Response denotes the extent to which a patient improves, irrespectively of the presence or absence of symptoms.'
          />
          <ANoteSectionContent>
            <ANoteYesNoRadioInput
              name='patient_perception_of_effectiveness'
              id='patient_perception_of_effectiveness'
              ref={register({ required: { value: true, message: required } })}
              label='Patient perception of effectiveness'
              disabled={!editMode}
              error={errors as ReactHookFormErrorsObject}
            />
            <ANoteTextArea
              name='patient_perception_of_effectiveness_comments'
              id='patient_perception_of_effectiveness_comments'
              minRows={1}
              ref={register({
                required: {
                  value: getRequired(patient_perception_of_effectiveness, 'no'),
                  message: required,
                },
              })}
              className={`${
                patient_perception_of_effectiveness === 'no' ? '' : 'hidden'
              } mt-4 ml-4`}
              disabled={!editMode}
              placeholder='Please include any noteworthy details.'
              sidebar={sidebar}
              error={errors as ReactHookFormErrorsObject}
            />
            <ANoteYesNoRadioInput
              name='adherent'
              id='adherent'
              ref={register({ required: { value: true, message: required } })}
              label='Adherent'
              className='mt-4'
              disabled={!editMode}
              error={errors as ReactHookFormErrorsObject}
            />
            <ANoteTextArea
              name='adherent_comments'
              id='adherent_comments'
              minRows={1}
              ref={register({
                required: { value: getRequired(adherent, 'no'), message: required },
              })}
              className={`${adherent === 'no' ? '' : 'hidden'} mt-4 ml-4`}
              disabled={!editMode}
              placeholder='Please include any noteworthy details.'
              sidebar={sidebar}
              error={errors as ReactHookFormErrorsObject}
            />
            <ANoteYesNoRadioInput
              name='patient_desire_of_dose_change'
              id='patient_desire_of_dose_change'
              ref={register({ required: { value: true, message: required } })}
              label='Patient desire of dose change'
              className='mt-4'
              disabled={!editMode}
              error={errors as ReactHookFormErrorsObject}
            />
            <ANoteTextArea
              name='patient_desire_of_dose_change_comments'
              id='patient_desire_of_dose_change_comments'
              minRows={1}
              ref={register({
                required: {
                  value: getRequired(patient_desire_of_dose_change, 'yes'),
                  message: required,
                },
              })}
              className={`${patient_desire_of_dose_change === 'yes' ? '' : 'hidden'} mt-4 ml-4`}
              disabled={!editMode}
              placeholder='Please include any noteworthy details.'
              sidebar={sidebar}
              error={errors as ReactHookFormErrorsObject}
            />
            <ANoteYesNoRadioInput
              name='cravings_or_withdrawal'
              id='cravings_or_withdrawal'
              ref={register({ required: { value: true, message: required } })}
              label='Cravings or withdrawal'
              className='mt-4'
              disabled={!editMode}
              error={errors as ReactHookFormErrorsObject}
            />
            <ANoteTextArea
              name='cravings_or_withdrawal_comments'
              id='cravings_or_withdrawal_comments'
              minRows={1}
              ref={register({
                required: { value: getRequired(cravings_or_withdrawal, 'yes'), message: required },
              })}
              className={`${cravings_or_withdrawal === 'yes' ? '' : 'hidden'} mt-4 ml-4`}
              disabled={!editMode}
              placeholder='Please include any noteworthy details.'
              sidebar={sidebar}
              error={errors as ReactHookFormErrorsObject}
            />
            <ANoteYesNoRadioInput
              name='concerning_behavior'
              id='concerning_behavior'
              ref={register({ required: { value: true, message: required } })}
              label='Concerning behavior'
              className='mt-4'
              disabled={!editMode}
              error={errors as ReactHookFormErrorsObject}
            />
            <ANoteTextArea
              name='concerning_behavior_comments'
              id='concerning_behavior_comments'
              minRows={1}
              ref={register({
                required: { value: getRequired(concerning_behavior, 'yes'), message: required },
              })}
              className={`${concerning_behavior === 'yes' ? '' : 'hidden'} mt-4 ml-4`}
              disabled={!editMode}
              placeholder='Please include any noteworthy details.'
              sidebar={sidebar}
              error={errors as ReactHookFormErrorsObject}
            />
          </ANoteSectionContent>
        </ANoteSection>
        <ANoteSection>
          <ANoteHeader
            text='Counseling and recovery services'
            description='Helping build better lives through behavioral health and substance abuse services.'
          />
          <ANoteSectionContent>
            <ANoteYesNoRadioInput
              name='participating_in_counseling'
              id='participating_in_counseling'
              ref={register({ required: { value: true, message: required } })}
              label='Participating in counseling'
              sublabel='Psychiatry, psychotherapy, etc.'
              disabled={!editMode}
              error={errors as ReactHookFormErrorsObject}
            />
            <ANoteTextArea
              name='participating_in_counseling_comments'
              id='participating_in_counseling_comments'
              minRows={1}
              ref={register({
                required: {
                  value: getRequired(participating_in_counseling, 'yes'),
                  message: required,
                },
              })}
              className={`${participating_in_counseling === 'yes' ? '' : 'hidden'} mt-4 ml-4`}
              disabled={!editMode}
              placeholder='Please include any noteworthy details.'
              sidebar={sidebar}
            />
            <ANoteYesNoRadioInput
              name='participating_in_recovery_services'
              id='participating_in_recovery_services'
              ref={register({ required: { value: true, message: required } })}
              label='Participating in recovery services'
              sublabel='AA/NA, OUD education classes, etc.'
              className='mt-4'
              disabled={!editMode}
              error={errors as ReactHookFormErrorsObject}
            />
            <ANoteTextArea
              name='participating_in_recovery_services_comments'
              id='participating_in_recovery_services_comments'
              minRows={1}
              ref={register({
                required: {
                  value: getRequired(participating_in_recovery_services, 'yes'),
                  message: required,
                },
              })}
              className={`${
                participating_in_recovery_services === 'yes' ? '' : 'hidden'
              } mt-4 ml-4`}
              disabled={!editMode}
              placeholder='Please include any noteworthy details.'
              sidebar={sidebar}
            />
          </ANoteSectionContent>
        </ANoteSection>
        <ANoteSection>
          <ANoteHeader text='Observation and examination' />
          <ANoteSectionContent className='grid gap-y-6 gap-x-4 grid-cols-6'>
            <ANoteTextArea
              name='observed_and_examination'
              id='observed_and_examination'
              minRows={2}
              ref={register({ required: { value: true, message: required } })}
              disabled={!editMode}
              error={errors as ReactHookFormErrorsObject}
              sidebar={sidebar}
            />
          </ANoteSectionContent>
        </ANoteSection>
        <ANoteSection>
          <ANoteHeader text='Urine drug assessment' />
          <ANoteSectionContent className='grid gap-y-2 grid-cols-6'>
            <ANoteYesNoRadioInput
              name='drug_screen_administered'
              id='drug_screen_administered'
              ref={register({ required: { value: true, message: required } })}
              label='Was a drug screening administered?'
              disabled={!editMode}
              error={errors as ReactHookFormErrorsObject}
              className='col-span-6'
            />
            <ANoteSubheader
              text='Please mark which substances were positive.'
              className={`${drug_screen_administered === 'yes' ? '' : 'hidden'} col-span-6`}
            />
            <ANoteCheckboxPositiveInput
              name='drug_screen_buprenorphine_result'
              id='drug_screen_buprenorphine_result'
              ref={register()}
              label='Buprenorphine (BUP)'
              disabled={!editMode}
              className={`${drug_screen_administered === 'yes' ? '' : 'hidden'} col-span-6 my-4`}
            />
            <ANoteCheckboxPositiveInput
              name='drug_screen_alcohol_result'
              id='drug_screen_alcohol_result'
              ref={register()}
              label='Alcohol (ETG)'
              disabled={!editMode}
              className={`${drug_screen_administered === 'yes' ? '' : 'hidden'} col-span-3`}
            />
            <ANoteCheckboxPositiveInput
              name='drug_screen_amphetamines_result'
              id='drug_screen_amphetamines_result'
              ref={register()}
              label='Amphetamines (AMP)'
              disabled={!editMode}
              className={`${drug_screen_administered === 'yes' ? '' : 'hidden'} col-span-3`}
            />
            <ANoteCheckboxPositiveInput
              name='drug_screen_barbiturates_result'
              id='drug_screen_barbiturates_result'
              ref={register()}
              label='Barbiturates (BAR)'
              disabled={!editMode}
              className={`${drug_screen_administered === 'yes' ? '' : 'hidden'} col-span-3`}
            />
            <ANoteCheckboxPositiveInput
              name='drug_screen_benzodiazepines_result'
              id='drug_screen_benzodiazepines_result'
              ref={register()}
              label='Benzodiazepines (BZO)'
              disabled={!editMode}
              className={`${drug_screen_administered === 'yes' ? '' : 'hidden'} col-span-3`}
            />
            <ANoteCheckboxPositiveInput
              name='drug_screen_cocaine_result'
              id='drug_screen_cocaine_result'
              ref={register()}
              label='Cocaine (COC)'
              disabled={!editMode}
              className={`${drug_screen_administered === 'yes' ? '' : 'hidden'} col-span-3`}
            />
            <ANoteCheckboxPositiveInput
              name='drug_screen_fentanyl_result'
              id='drug_screen_fentanyl_result'
              ref={register()}
              label='Fentanyl (FEN)'
              disabled={!editMode}
              className={`${drug_screen_administered === 'yes' ? '' : 'hidden'} col-span-3`}
            />
            <ANoteCheckboxPositiveInput
              name='drug_screen_marijuana_result'
              id='drug_screen_marijuana_result'
              ref={register()}
              label='Marijuana (THC)'
              disabled={!editMode}
              className={`${drug_screen_administered === 'yes' ? '' : 'hidden'} col-span-3`}
            />
            <ANoteCheckboxPositiveInput
              name='drug_screen_methadone_result'
              id='drug_screen_methadone_result'
              ref={register()}
              label='Methadone (MTD)'
              disabled={!editMode}
              className={`${drug_screen_administered === 'yes' ? '' : 'hidden'} col-span-3`}
            />
            <ANoteCheckboxPositiveInput
              name='drug_screen_methamphetamine_result'
              id='drug_screen_methamphetamine_result'
              ref={register()}
              label='Methamphetamine (mAMP)'
              disabled={!editMode}
              className={`${drug_screen_administered === 'yes' ? '' : 'hidden'} col-span-3`}
            />
            <ANoteCheckboxPositiveInput
              name='drug_screen_methylenedioxymethamphetamine_result'
              id='drug_screen_methylenedioxymethamphetamine_result'
              ref={register()}
              label='Methylenediozymethamphetamine (MDMA)'
              disabled={!editMode}
              className={`${drug_screen_administered === 'yes' ? '' : 'hidden'} col-span-3`}
            />
            <ANoteCheckboxPositiveInput
              name='drug_screen_opiates_result'
              id='drug_screen_opiates_result'
              ref={register()}
              label='Opiates (OPI)'
              disabled={!editMode}
              className={`${drug_screen_administered === 'yes' ? '' : 'hidden'} col-span-3`}
            />
            <ANoteCheckboxPositiveInput
              name='drug_screen_oxycodone_result'
              id='drug_screen_oxycodone_result'
              ref={register()}
              label='Oxycodone (OXY)'
              disabled={!editMode}
              className={`${drug_screen_administered === 'yes' ? '' : 'hidden'} col-span-3`}
            />
            <ANoteCheckboxPositiveInput
              name='drug_screen_phencyclidine_result'
              id='drug_screen_phencyclidine_result'
              ref={register()}
              label='Phencyclidine (PCP)'
              disabled={!editMode}
              className={`${drug_screen_administered === 'yes' ? '' : 'hidden'} col-span-3`}
            />
            <ANoteCheckboxPositiveInput
              name='drug_screen_synthetic_cannabinoid_result'
              id='drug_screen_synthetic_cannabinoid_result'
              ref={register()}
              label='Synthetic Cannabinoid (K2)'
              disabled={!editMode}
              className={`${drug_screen_administered === 'yes' ? '' : 'hidden'} col-span-3`}
            />
            <ANoteCheckboxPositiveInput
              name='drug_screen_tramadol_result'
              id='drug_screen_tramadol_result'
              ref={register()}
              label='Tramadol (TRAM)'
              disabled={!editMode}
              className={`${drug_screen_administered === 'yes' ? '' : 'hidden'} col-span-3`}
            />
            <ANoteTextArea
              name='drug_screen_comments'
              id='drug_screen_comments'
              minRows={1}
              ref={register()}
              disabled={!editMode}
              error={errors as ReactHookFormErrorsObject}
              className={`${drug_screen_administered === 'yes' ? '' : 'hidden'} mt-4`}
              sidebar={sidebar}
              placeholder='Please include any noteworthy details.'
            />
          </ANoteSectionContent>
        </ANoteSection>
        <ANoteSection>
          <ANoteHeader text='PDMP' />
          <ANoteSectionContent className='grid gap-y-6 gap-x-4 grid-cols-6'>
            <ANoteYesNoRadioInput
              name='pdmp_consistent_with_history'
              id='pdmp_consistent_with_history'
              ref={register({ required: { value: true, message: required } })}
              label='Is the PDMP consistent with history?'
              className='col-span-6'
              disabled={!editMode}
              error={errors as ReactHookFormErrorsObject}
            />
            <ANoteTextArea
              name='pdmp_consistent_with_history_comments'
              id='pdmp_consistent_with_history_comments'
              minRows={1}
              ref={register({
                required: {
                  value: getRequired(pdmp_consistent_with_history, 'no'),
                  message: required,
                },
              })}
              className={`${pdmp_consistent_with_history === 'no' ? '' : 'hidden'} ml-4`}
              disabled={!editMode}
              placeholder='Please include any noteworthy details.'
              error={errors as ReactHookFormErrorsObject}
              sidebar={sidebar}
            />
          </ANoteSectionContent>
        </ANoteSection>
        <ANoteSection>
          <ANoteHeader text='Treatment goals' />
          <ANoteSectionContent className='grid gap-y-6 gap-x-4 grid-cols-6'>
            <ANoteTextArea
              name='treatment_goals'
              id='treatment_goals'
              minRows={2}
              ref={register({ required: { value: true, message: required } })}
              disabled={!editMode}
              error={errors as ReactHookFormErrorsObject}
              sidebar={sidebar}
            />
          </ANoteSectionContent>
          {displayMentalHealthEvalSection(notes) && (
            <MentalHealthEvaluationFormProvider form={mentalHealthEvalForm}>
              <MentalHealthEvalSection
                editMode={editMode}
                onDiagnosisCompleted={diagnosis => {
                  // Adds the diagnosis to the list of problems addressed if it's not already there
                  const currentProblemsAddressed = [...diagnosesForm.values.diagnoses]
                  const diagnosisAlreadyAddressedIndex = currentProblemsAddressed.findIndex(
                    problem => problem.code === diagnosis.code,
                  )
                  const diagnosisFromProblemList =
                    diagnosisAlreadyAddressedIndex === -1
                      ? null
                      : currentProblemsAddressed[diagnosisAlreadyAddressedIndex]
                  if (diagnosisFromProblemList) {
                    currentProblemsAddressed[diagnosisAlreadyAddressedIndex] = {
                      ...diagnosisFromProblemList,
                      chronicity: capitalize(diagnosis.chronicity),
                      progression: capitalize(diagnosis.progression),
                    }
                    showNotification({
                      message: `${diagnosis.full_description} updated`,
                      variant: 'success',
                    })
                  } else {
                    const formattedDiagnosis = {
                      code: diagnosis.code,
                      full_description: diagnosis.full_description,
                      chronicity: capitalize(diagnosis.chronicity),
                      progression: capitalize(diagnosis.progression),
                      status: 'addressed',
                    } as ProblemListProblem
                    currentProblemsAddressed.push(formattedDiagnosis)
                    diagnosesForm.setValues({
                      diagnoses: currentProblemsAddressed,
                    })
                    void handleSavingDiagnoses({ diagnoses: [formattedDiagnosis] })
                    showNotification({
                      message: `${diagnosis.full_description} added to the patient's diagnosis list`,
                      variant: 'success',
                    })
                  }
                  diagnosesForm.setFieldValue('problems_addressed', currentProblemsAddressed)
                }}
              />
            </MentalHealthEvaluationFormProvider>
          )}
        </ANoteSection>
        <VisitNoteSection title='Diagnoses'>
          <DiagnosisSection
            {...diagnosesForm.getInputProps('diagnoses')}
            editable={editMode}
            saveDiagnoses={handleSavingDiagnoses}
            deactivateDiagnosis={handleDeactivatingDiagnosis}
          />
        </VisitNoteSection>
        <Space h='md' />

        {showSudSection && (
          <SubstanceUseFormProvider form={substanceUseForm}>
            <SubstanceUseSection
              isEditing={editMode}
              hasOudDiagnosis={hasOudDiagnosis}
              hasCocaineUseDiagnosis={hasCocaineUseDiagnosis}
              hasOtherStimulantUseDiagnosis={hasOtherStimulantUseDiagnosis}
            />
          </SubstanceUseFormProvider>
        )}
        <ANoteSection>
          <ANoteHeader text='Assessment and plan' />
          <ANoteSectionContent className='grid gap-y-6 gap-x-4 grid-cols-6'>
            <ANoteSubheader
              text='Please indicate your assessment as well as a plan for each problem addressed. If you do not have a plan for a particular problem, please write N/A.'
              className='col-span-6'
            />
            <ANoteTextArea
              name='assessment'
              id='assessment'
              minRows={2}
              ref={register({ required: { value: true, message: required } })}
              disabled={!editMode}
              error={errors as ReactHookFormErrorsObject}
              sidebar={sidebar}
              label='Assessment'
            />
            <ANoteTextArea
              name='plan'
              id='plan'
              minRows={2}
              ref={register()}
              disabled={!editMode}
              error={errors as ReactHookFormErrorsObject}
              sidebar={sidebar}
              label='General Plan'
              className={`${plan ? '' : 'hidden'}`}
            />
            {diagnosesForm.values.diagnoses.length > 0 &&
              diagnosesForm.values.diagnoses.map((problem: ProblemListProblem) => {
                const key = `plan_${problem.full_description
                  .toLowerCase()
                  .replace(/[[\].$#/]+/g, '')
                  .replace(/ /g, '_')}`
                return (
                  <ANoteTextArea
                    onMount={() => {
                      setValue(key, notes?.[key] ?? '')
                    }}
                    name={key}
                    id={key}
                    minRows={1}
                    ref={register({ required: { value: true, message: required } })}
                    disabled={!editMode}
                    error={errors as ReactHookFormErrorsObject}
                    sidebar={sidebar}
                    label={`Plan - ${problem.full_description}`}
                    key={problem.code}
                  />
                )
              })}
          </ANoteSectionContent>
        </ANoteSection>
        <ANoteSection>
          <ANoteHeader text='Medication' />
          <MVisitNotePrescriptionSection
            visitId={visit?.id}
            patientId={patientID}
            editMode={editMode}
            isLocked={Boolean(closedNoteDetails)}
            setPrescriptionBanner={setPrescriptionBanner}
            setMedicationPrescribed={setMedicationPrescribed}
            recordedMedication={recordedMedication}
            medicationSentViaDosespot={medicationSentViaDosespot}
            setMedicationSentViaDosespot={setMedicationSentViaDosespot}
            setDosespotMedications={setDosespotMedications}
          />

          <div className={cn({ hidden: !medicationSentViaDosespot })}>
            <ANoteSectionContent className='grid gap-y-6 gap-x-4 grid-cols-6'>
              <ANoteDropdown
                name='medication_name'
                id='medication_name'
                ref={register({
                  required: {
                    value: medicationSentViaDosespot,
                    message: required,
                  },
                })}
                label='Medication Name'
                options={medicationNames()}
                disabled={!editMode}
                naValue
                error={errors}
                className={cn('col-span-6', {
                  hidden: medication_prescribed !== 'yes' && !medicationSentViaDosespot,
                })}
              />
              {medication_name ? (
                <ANoteDropdown
                  name='medication_strength'
                  id='medication_strength'
                  ref={register({
                    required: {
                      value: medicationSentViaDosespot,
                      message: required,
                    },
                  })}
                  label='Medication Strength'
                  options={medicationName2Strength(medication_name as string | undefined) ?? []}
                  disabled={!editMode}
                  error={errors}
                  defaultValue={medicationStrengthDefaultValue}
                  naValue
                  className={cn('col-span-6', {
                    hidden: medication_prescribed !== 'yes' && !medicationSentViaDosespot,
                  })}
                />
              ) : null}
              <ANoteTextInput
                name='medication_quantity'
                id='medication_quantity'
                ref={register({
                  required: {
                    value: medicationSentViaDosespot,
                    message: required,
                  },
                })}
                label='Medication Quantity'
                error={errors as ReactHookFormErrorsObject}
                disabled={!editMode}
                sidebar={sidebar}
                type='number'
                className={cn({
                  hidden: medication_prescribed !== 'yes' && !medicationSentViaDosespot,
                })}
              />
              <ANoteTextInput
                name='medication_days_supply'
                id='medication_days_supply'
                ref={register({
                  required: {
                    value: medicationSentViaDosespot,
                    message: required,
                  },
                })}
                label='Medication Days Supply'
                error={errors as ReactHookFormErrorsObject}
                disabled={!editMode}
                sidebar={sidebar}
                type='number'
                className={cn({
                  hidden: medication_prescribed !== 'yes' && !medicationSentViaDosespot,
                })}
              />
            </ANoteSectionContent>
          </div>
        </ANoteSection>
        <UpcomingVisitsSection isNoteClosed={Boolean(closedNoteDetails)} isSidebar={sidebar} />
      </form>
      <ObjectiveMeasuresFormCollectionDrawer
        patientId={patientID}
        measure={objectiveMeasure}
        workflowState={workflowState}
        workflowType={workflowType}
        isLoading={workflowIsLoading}
        onClose={onCloseDrawer}
        setRecentlySubmittedScore={setRecentlySubmittedScore}
      />
    </>
  )
}

export default OMaintenanceVisitForm
