import {
  ClockIcon,
  DownloadIcon,
  FeatherIcon,
  Group,
  LockIcon,
  PrimaryButton,
  SecondaryButton,
  Stack,
  Text,
  Tooltip,
  UnlockIcon,
} from '@shared/components'
import { dayjs, toTime } from '@shared/utils'
import { useEffect, useState } from 'react'
import { renderDurationString } from '../../../utils/utils'

export type VisitNoteHeaderFooterProps = {
  isSaving: boolean
  datetime?: string
  editMode: boolean
  save: () => void
  submitText?: 'Sign & lock' | 'Finish'
  savedAt?: dayjs.Dayjs
  lockedAt: string | undefined
  lockedBy: string | undefined
  setUnlockNoteModal: ((bool: boolean) => void) | undefined
  canUnlockNote: boolean
  canExportNote?: boolean
  exportNoteOnClick?: () => void
  meetingDetails?: { patientTime: number; clinicianTime: number }
  onSubmit: () => void
  error?: string
  sidebar: boolean
  hasInvalidDiagnosis?: boolean
}

const getFormattedDateString = (iso: string | dayjs.Dayjs): string => {
  const date = dayjs(iso)

  // There are some invalid formatted dates in older visits
  if (typeof iso === 'string' && !date.isValid()) {
    return iso
  }

  if (date.isToday()) {
    return `today at ${date.format('h:mma z')}`
  }

  return date.format('dddd, MMMM D, YYYY hh:mma z')
}

export const VisitNoteHeader = ({
  isSaving,
  editMode,
  datetime,
  save,
  submitText = 'Sign & lock',
  savedAt,
  lockedAt,
  canUnlockNote = false,
  setUnlockNoteModal,
  canExportNote = false,
  exportNoteOnClick,
  meetingDetails,
  lockedBy,
  error,
  onSubmit,
  sidebar = false,
  hasInvalidDiagnosis = false,
}: VisitNoteHeaderFooterProps) => {
  const MINUTES_TO_SUBTRACT = 10
  // let notes be locked 10 min before, so that ECs can take calls early
  const [futureNote, setFutureNote] = useState<boolean>(
    dayjs(datetime).subtract(MINUTES_TO_SUBTRACT, 'minutes').isAfter(dayjs()),
  )

  useEffect(() => {
    const interval = setInterval(() => {
      if (datetime) {
        setFutureNote(dayjs(datetime).subtract(MINUTES_TO_SUBTRACT, 'minutes').isAfter(dayjs()))
      }
    }, toTime('1 min').ms())

    return () => clearTimeout(interval)
  }, [datetime])

  return (
    <Group
      position='apart'
      align='flex-start'
      spacing='sm'
      p='sm'
      sx={({ other: { colors } }) => ({
        position: editMode ? 'sticky' : 'relative',
        top: editMode ? 0 : 'auto',
        zIndex: editMode ? 5 : 'auto',
        backgroundColor: colors.background[0],
        borderBottomWidth: editMode ? 1 : 0,
        borderBottomColor: colors.background[3],
      })}
    >
      <Stack spacing='sm'>
        {editMode ? (
          <>
            {error && (
              <Text size='xs' color={colors => colors.error[0]}>
                {error}
              </Text>
            )}
            {savedAt && (
              <Text size='xs' color={colors => colors.text[1]}>
                Last saved {getFormattedDateString(savedAt)}
              </Text>
            )}
          </>
        ) : (
          lockedAt && (
            <Group spacing='xs'>
              <LockIcon color={colors => colors.text[1]} />
              <Text size='xs' color={colors => colors.text[1]}>
                {`Locked ${getFormattedDateString(lockedAt)}`}
              </Text>
            </Group>
          )
        )}
        {!editMode && lockedBy && (
          <Group spacing='xs'>
            <FeatherIcon color={colors => colors.text[1]} />
            <Text size='xs' color={colors => colors.text[1]}>
              Signed by {lockedBy}
            </Text>
          </Group>
        )}
        {!editMode && Boolean(meetingDetails?.patientTime) && (
          <Group spacing='xs'>
            <ClockIcon color={colors => colors.text[1]} />
            <Text size='xs' color={colors => colors.text[1]}>
              {renderDurationString({
                clinicianTime: meetingDetails?.clinicianTime ?? 0,
                patientTime: meetingDetails?.patientTime ?? 0,
              })}
            </Text>
          </Group>
        )}
      </Stack>
      {!sidebar && (
        <Group spacing='sm'>
          {editMode ? (
            <>
              <SecondaryButton size='sm' onClick={save} loading={isSaving}>
                Save
              </SecondaryButton>
              <Tooltip
                label={
                  hasInvalidDiagnosis
                    ? 'You cannot lock a note with an invalid diagnosis'
                    : 'Future notes cannot be locked'
                }
                disabled={!futureNote && !hasInvalidDiagnosis}
              >
                <PrimaryButton
                  size='sm'
                  type='submit'
                  onClick={onSubmit}
                  disabled={futureNote || isSaving || hasInvalidDiagnosis}
                  leftIcon={<FeatherIcon />}
                >
                  {submitText}
                </PrimaryButton>
              </Tooltip>
            </>
          ) : (
            <>
              {lockedAt && canUnlockNote && setUnlockNoteModal && (
                <SecondaryButton
                  size='sm'
                  onClick={() => setUnlockNoteModal(true)}
                  leftIcon={<UnlockIcon />}
                >
                  Unlock
                </SecondaryButton>
              )}
              {lockedAt && canExportNote && exportNoteOnClick && (
                <PrimaryButton size='sm' onClick={exportNoteOnClick} leftIcon={<DownloadIcon />} />
              )}
            </>
          )}
        </Group>
      )}
    </Group>
  )
}
