import {
  BetterModal,
  Group,
  ModalContent,
  ModalFooter,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
  Text,
} from '@shared/components'
import { EmrLunaApi } from '@shared/types'
import { useLunaMutation } from '../utils/hooks'

export const MutationModal = <OpheliaRoute extends keyof EmrLunaApi>({
  header,
  opened,
  onClose,
  description,
  callToAction,
  endpoint,
  payload,
  onSuccess = () => onClose(),
  cancelText = 'Cancel',
}: {
  header: string
  opened: boolean
  onClose: () => void
  callToAction: string
  description: React.ReactNode
  endpoint: OpheliaRoute
  payload: Partial<EmrLunaApi[OpheliaRoute]['req']>
  onSuccess?: () => void
  cancelText?: string
}) => {
  const actionMutation = useLunaMutation(endpoint, {
    onSuccess: () => {
      onSuccess()
    },
  })

  const onCloseWrapper = () => {
    actionMutation.reset()
    onClose()
  }

  return (
    <BetterModal opened={opened} onClose={onCloseWrapper}>
      <ModalHeader onClose={onCloseWrapper}>{header}</ModalHeader>
      <ModalContent>
        {description}
        {actionMutation.error && (
          <Text color={c => c.error[0]} mt={4}>
            {actionMutation.error.message}
          </Text>
        )}
      </ModalContent>
      <ModalFooter>
        <Group position='right' noWrap>
          <SecondaryButton disabled={actionMutation.isLoading} onClick={onCloseWrapper}>
            {cancelText}
          </SecondaryButton>
          <PrimaryButton
            loading={actionMutation.isLoading}
            onClick={() => {
              actionMutation.mutate(payload)
            }}
          >
            {callToAction}
          </PrimaryButton>
        </Group>
      </ModalFooter>
    </BetterModal>
  )
}
