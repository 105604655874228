import { useHover } from '@mantine/hooks'
import {
  Accordion,
  Card,
  DeleteIcon,
  Flex,
  Group,
  LayersIcon,
  Pill,
  RefreshIcon,
  SecondaryButton,
  SendIcon,
  Skeleton,
  Stack,
  TertiaryButton,
  Text,
  useBanner,
} from '@shared/components'
import { DispenseUnitType, PrescriptionResponse, hasRole } from '@shared/types'
import { dayjs } from '@shared/utils'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { emrApi } from '../../../api'
import { useAuth } from '../../../context/auth'
import {
  PRESCRIPTION_QUEUER_ROLES,
  canDeletePrescription,
  canReOrderPrescription,
  canSendPrescription,
  sortPrescriptions,
} from '../../../utils/prescriptionUtils'
import { usePatient } from '../PPatientContext'
import { PrescriptionDeleteModal } from '../PrescriptionDeleteModal'
import { PrescriptionDetailDrawer } from '../PrescriptionDetailDrawer'
import { PrescriptionReviewSendDrawer } from '../PrescriptionReviewSendModal'
import { StatusIcon } from '../journey/content/PrescriptionContent'
import { PrescriptionReorderDrawer } from '../prescriptions/PrescriptionReorderDrawer'
import { SidebarEmptyState } from './SidebarEmptyState'

export const PrescriptionsContent = ({ active }: { active: boolean }) => {
  const { patientQuery, patientId } = usePatient()
  const patient = patientQuery?.data

  const prescriptionsQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/prescriptions', {
      query: { useDoseSpot: 'true' },
      params: { patientId },
    }),
    { enabled: active },
  )

  const prescriptions = sortPrescriptions(prescriptionsQuery.data || [])

  if (prescriptionsQuery.isLoading || prescriptionsQuery.isError) {
    return (
      <Stack spacing='sm' px='md'>
        <Skeleton h='3rem' />
        <Skeleton h='3rem' />
        <Skeleton h='3rem' />
      </Stack>
    )
  }

  if (!prescriptions.length) {
    return (
      <Flex px='md'>
        <SidebarEmptyState
          pillMessage='No prescriptions'
          textMessage={`Prescriptions sent for ${
            patient?.personalData.preferredName || patient?.personalData.firstName
          } will appear here`}
        />
      </Flex>
    )
  }

  return (
    <Stack spacing='sm' px='md'>
      {prescriptions.map(prescription => (
        <PresciptionCard key={prescription.prescription_id} prescription={prescription} />
      ))}
    </Stack>
  )
}

const PresciptionCard = ({ prescription }: { prescription: PrescriptionResponse }) => {
  const { hovered, ref } = useHover<HTMLDivElement>()
  const [value, setValue] = useState<'options' | ''>('')
  const { currentUser } = useAuth()
  const [modal, setModal] = useState<'cancel' | 'details' | 'reorder' | 'send' | null>(null)
  const { showBanner } = useBanner()
  const { patientId } = usePatient()

  useEffect(() => {
    setValue(hovered ? 'options' : '')
  }, [hovered, setValue])

  const medicationQuantityString =
    prescription.medication_quantity && prescription.dispense_unit_id
      ? `${prescription.medication_quantity} (${DispenseUnitType[prescription.dispense_unit_id]})`
      : ''

  const onClose = () => setModal(null)

  return (
    <Card
      styles={({ warning }) => ({
        borderColor: prescription.status === 'Queued' ? warning[0] : undefined,
      })}
      ref={ref}
    >
      <Stack spacing='sm'>
        <Group noWrap position='apart' align='flex-start'>
          <Group spacing='xs' position='left' noWrap>
            <Text size='xs' bold lineClamp={1}>
              {prescription.medication_name.replace(prescription.medication_strength, '').trim()}
            </Text>
            {prescription.type === 'bridge' && (
              <Pill variant='filled' status='warning'>
                Bridge
              </Pill>
            )}
          </Group>
          <StatusIcon withColor {...prescription} />
        </Group>
        <Text size='xs'>
          {`${dayjs(`${prescription.timestamp}Z`).format('MM/DD/YYYY')} | ${
            prescription.medication_strength
          } - ${medicationQuantityString} - ${prescription.medication_days_supply} days`}
        </Text>
        <Accordion value={value}>
          <Accordion.Item value='options'>
            <Accordion.Panel>
              <Group position='right' spacing='sm'>
                {canDeletePrescription(prescription, currentUser) && (
                  <TertiaryButton onClick={() => setModal('cancel')} leftIcon={<DeleteIcon />}>
                    Cancel
                  </TertiaryButton>
                )}
                <TertiaryButton
                  disabled={!prescription.prescription_id}
                  onClick={() => setModal('details')}
                  leftIcon={<LayersIcon />}
                >
                  Details
                </TertiaryButton>
                {canReOrderPrescription(prescription, currentUser) && (
                  <SecondaryButton
                    size='xs'
                    onClick={() => setModal('reorder')}
                    leftIcon={<RefreshIcon color={({ actions }) => actions[0]} />}
                  >
                    Re-order
                  </SecondaryButton>
                )}
                {canSendPrescription(prescription, currentUser) && (
                  <SecondaryButton
                    size='xs'
                    onClick={() => setModal('send')}
                    leftIcon={<SendIcon color={({ actions }) => actions[0]} />}
                  >
                    {hasRole(currentUser, ...PRESCRIPTION_QUEUER_ROLES) ? 'Edit' : 'Review & send'}
                  </SecondaryButton>
                )}
              </Group>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Stack>
      <PrescriptionDeleteModal
        onClose={onClose}
        opened={modal === 'cancel'}
        patientId={patientId}
        prescriptionId={prescription.prescription_id ?? 0}
        setPrescriptionBanner={(message, type) =>
          showBanner({ type, label: message, dismissable: true })
        }
      />
      <PrescriptionDetailDrawer
        onClose={onClose}
        opened={modal === 'details'}
        patientId={patientId}
        prescriptionId={prescription.prescription_id ?? 0}
        setPrescriptionBanner={(message, type) =>
          showBanner({ type, label: message, dismissable: true })
        }
      />
      <PrescriptionReorderDrawer
        patientId={patientId}
        onClose={onClose}
        opened={modal === 'reorder'}
        prescription={prescription}
        setPrescriptionBanner={(message, type) =>
          showBanner({ type, label: message, dismissable: true })
        }
      />
      <PrescriptionReviewSendDrawer
        onClose={onClose}
        opened={modal === 'send'}
        prescriptionId={prescription.prescription_id ?? 0}
        setPrescriptionBanner={(message, type) =>
          showBanner({ type, label: message, dismissable: true })
        }
        editOnly={hasRole(currentUser, ...PRESCRIPTION_QUEUER_ROLES)}
        patientId={patientId}
      />
    </Card>
  )
}
