import { useLocalStorage, useToggle } from '@mantine/hooks'
import {
  Box,
  Center,
  Divider,
  FilterIcon,
  Group,
  Indicator,
  Loader,
  Pill,
  RotateCwIcon,
  ScrollArea,
  SecondaryButton,
  SendIcon,
  Stack,
  Switch,
  SwitchGroup,
  TertiaryButton,
  Text,
  Timeline,
  TitleThree,
  Tooltip,
  XIcon,
  useBanner,
} from '@shared/components'
import { PatientHistoricalChange, hasRole, isClinician } from '@shared/types'
import { dayjs } from '@shared/utils'
import max from 'lodash/max'
import min from 'lodash/min'
import orderBy from 'lodash/orderBy'
import Lottie from 'lottie-react'
import { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { emrApi } from '../../../api'
import { useAuth } from '../../../context/auth'
import { useLunaQuery } from '../../../utils/hooks'
import { useLottieAnimation } from '../../../utils/hooks/use-lottie-animation'
import { PRESCRIPTION_QUEUER_ROLES } from '../../../utils/prescriptionUtils'
import { DropInClinicBanner } from '../DropInClinicBanner'
import { JourneyItemContent, JourneyItemType, usePatient } from '../PPatientContext'
import { PrescriptionReorderDrawer } from '../prescriptions/PrescriptionReorderDrawer'
import { JourneyGroup, JourneyItem, PatientJourneyError } from './PatientJourneyContent'
import { journeyLabels } from './PatientJourneyContext'
import { WinbackCallAlert } from './content/WinbackCallAlert'

type JourneyFilter = JourneyItemType | `${JourneyItemType}.${string}`
const ALL_FILTERS = [
  'appointment',
  'appointment.canceled',
  'task',
  'prescription',
  'non-visit-event',
  'discharge-note',
  'ineligible-note',
  'drug-screen',
  'case-review-note',
] as const

export const PatientJourney = () => {
  const { patientId, patientQuery } = usePatient()

  const [appliedFilters, setAppliedFilters] = useLocalStorage({
    key: 'patient-journey-filters',
    defaultValue: [...ALL_FILTERS],
  })

  const { currentUser } = useAuth()
  const [showFilters, toggleShowFilters] = useToggle([true, false])

  const appointmentsQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/appointments', {
      params: { patientId },
    }),
    { enabled: Boolean(patientId) },
  )

  const nonVisitEventsQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/nonVisitEvents', {
      params: { patientId },
    }),
    { enabled: Boolean(patientId) },
  )

  const caseReviewNotesQuery = useQuery(
    ...emrApi.getQuery('GET /caseReviewNote/patient/:patientId', {
      params: { patientId },
      query: { isLocked: 'true' },
    }),
    { enabled: Boolean(patientId) },
  )

  const historicalChangesQuery = useQuery<unknown, unknown, PatientHistoricalChange<'status'>[]>(
    ...emrApi.getQuery('GET /patient/:patientId/historicalChanges', {
      params: { patientId },
      query: { property: 'status' },
    }),
    { enabled: Boolean(patientId) },
  )

  const tasksQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/issues', {
      params: { patientId },
    }),
    { enabled: Boolean(patientId) },
  )

  const prescriptionsQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/prescriptions', {
      params: { patientId },
      query: { useDoseSpot: 'true' },
    }),
    { enabled: Boolean(patientId) },
  )

  const ineligibleNotesQuery = useQuery(
    ...emrApi.getQuery('GET /ineligibleNotes', {
      query: { patientId },
    }),
    { enabled: Boolean(patientId) },
  )

  const dischargeNotesQuery = useQuery(
    ...emrApi.getQuery('GET /dischargeNotes', {
      query: { patientId },
    }),
    { enabled: Boolean(patientId) },
  )

  const drugScreensQuery = useQuery(
    ...emrApi.getQuery('GET /patient/:patientId/drugScreens', { params: { patientId } }),
    {
      enabled: Boolean(patientId),
    },
  )

  const visitHoldsQuery = useLunaQuery(
    'GET /scheduling/patients/:patientId/holds',
    {
      params: {
        patientId,
      },
    },
    {
      enabled: Boolean(patientId),
    },
  )

  const isLoading =
    appointmentsQuery.isLoading ||
    nonVisitEventsQuery.isLoading ||
    caseReviewNotesQuery.isLoading ||
    historicalChangesQuery.isLoading ||
    tasksQuery.isLoading ||
    prescriptionsQuery.isLoading ||
    ineligibleNotesQuery.isLoading ||
    dischargeNotesQuery.isLoading ||
    drugScreensQuery.isLoading ||
    visitHoldsQuery.isLoading

  const { pastJourney, futureJourney, errors } = useMemo(() => {
    const now = dayjs()
    const futureJourneyMap: Map<string, JourneyItem[]> = new Map()
    const pastJourneyMap: Map<string, JourneyItem[]> = new Map()
    const statuses = historicalChangesQuery.data ?? []
    const errors: JourneyItemType[] = []

    function add<T extends JourneyItemType>({
      datetime,
      type,
      content,
      id,
    }: {
      datetime: string
      type: T
      content: JourneyItemContent<T>
      id: string
    }) {
      const key = dayjs(datetime).format('YYYY-MM-DD')

      if (now.isAfter(datetime)) {
        if (pastJourneyMap.has(key)) {
          pastJourneyMap.get(key)?.push({ type, content, id, datetime })
        } else {
          pastJourneyMap.set(key, [{ type, content, id, datetime }])
        }
      } else if (futureJourneyMap.has(key)) {
        futureJourneyMap.get(key)?.push({ type, content, id, datetime })
      } else {
        futureJourneyMap.set(key, [{ type, content, id, datetime }])
      }
    }

    function getTimelineGroupPatientStatus(datetime: string) {
      for (const status of statuses) {
        if (dayjs(datetime).isSameOrAfter(status.changedAt)) {
          return status.value
        }
      }
    }

    function addYearDividers(journey: JourneyGroup[], omitCurrentYear?: boolean) {
      const years = journey.map(({ datetime }) => dayjs(datetime).format('YYYY'))
      for (const year of dayjs(min(years)).getBetween(max(years), 'years')) {
        if (!omitCurrentYear || !now.isSame(year, 'year')) {
          const startOfYear = dayjs(year).startOf('year').toISOString()

          journey.push({
            datetime: startOfYear,
            type: 'year',
            status: getTimelineGroupPatientStatus(startOfYear),
            id: year,
            content: undefined,
          })
        }
      }
    }

    function createJourneyFromMap(journeyMap: Map<string, JourneyItem[]>) {
      const journey: JourneyGroup[] = []

      journeyMap.forEach((content, key) => {
        const sortedContent = orderBy(content, ({ datetime: time }) => dayjs(time).unix(), ['desc'])
        // Use the latest datetime of the group
        const { datetime } = sortedContent[0]!
        journey.push({
          id: key,
          datetime,
          type: 'cards',
          content: sortedContent,
          status: getTimelineGroupPatientStatus(datetime),
        })
      })

      return journey
    }

    function sortJourney(journey: JourneyGroup[]) {
      return orderBy(journey, ({ datetime: time }) => dayjs(time).unix(), ['desc'])
    }

    if (appointmentsQuery.data?.length) {
      if (appliedFilters.includes('appointment')) {
        for (const appointment of appointmentsQuery.data) {
          const datetime = dayjs(appointment.datetime)
            .add(Number(appointment.duration), 'minutes')
            .toISOString()

          if (
            !appliedFilters.includes('appointment.canceled') &&
            (appointment.status === 'canceled' || appointment.status === 'late-cancellation')
          ) {
            continue
          }

          add({
            datetime,
            type: 'appointment',
            content: appointment,
            id: appointment.oid,
          })
        }
      }
    } else if (appointmentsQuery.isError) {
      errors.push('appointment')
    }

    if (nonVisitEventsQuery.data?.length) {
      if (appliedFilters.includes('non-visit-event')) {
        // Filter out non visit events related to a task to dedup data
        for (const nonVisitEvent of nonVisitEventsQuery.data.filter(({ taskId }) => !taskId)) {
          const datetime = nonVisitEvent.createdAt
          add({
            datetime,
            type: 'non-visit-event',
            content: nonVisitEvent,
            id: nonVisitEvent.oid,
          })
        }
      }
    } else if (nonVisitEventsQuery.isError) {
      errors.push('non-visit-event')
    }

    if (caseReviewNotesQuery.data?.length) {
      if (appliedFilters.includes('case-review-note')) {
        for (const caseReviewNote of caseReviewNotesQuery.data) {
          const datetime = caseReviewNote.lockedAt ?? caseReviewNote.createdAt
          add({
            datetime,
            type: 'case-review-note',
            content: caseReviewNote,
            id: caseReviewNote.oid,
          })
        }
      }
    } else if (caseReviewNotesQuery.isError) {
      errors.push('case-review-note')
    }

    if (tasksQuery.data?.length) {
      if (appliedFilters.includes('task')) {
        for (const task of tasksQuery.data) {
          const datetime = task.updatedAt
          add({
            datetime,
            type: 'task',
            content: task,
            id: task.oid,
          })
        }
      }
    } else if (tasksQuery.isError) {
      errors.push('task')
    }

    if (visitHoldsQuery.data?.data?.length) {
      for (const visitHold of visitHoldsQuery.data.data) {
        const intendedDay = dayjs(visitHold.intendedDay).toISOString()
        add({
          datetime: intendedDay,
          type: 'visit-hold',
          content: visitHold,
          id: visitHold.oid,
        })
      }
    }

    if (prescriptionsQuery.data?.length) {
      if (appliedFilters.includes('prescription')) {
        for (const prescription of prescriptionsQuery.data) {
          const datetime = `${prescription.timestamp}Z`

          add({
            datetime,
            type: 'prescription',
            content: prescription,
            id: prescription.oid ?? String(prescription.prescription_id),
          })
        }
      }
    } else if (prescriptionsQuery.isError) {
      errors.push('prescription')
    }

    if (ineligibleNotesQuery.data?.length) {
      if (appliedFilters.includes('ineligible-note')) {
        for (const ineligibleNote of ineligibleNotesQuery.data) {
          const datetime = dayjs(ineligibleNote.timestamp).toISOString()
          add({
            datetime,
            type: 'ineligible-note',
            content: ineligibleNote,
            id: ineligibleNote.id,
          })
        }
      }
    } else if (ineligibleNotesQuery.isError) {
      errors.push('ineligible-note')
    }

    if (dischargeNotesQuery.data?.length) {
      if (appliedFilters.includes('discharge-note')) {
        for (const dischargeNote of dischargeNotesQuery.data) {
          const datetime = dayjs(dischargeNote.timestamp).toISOString()
          add({
            datetime,
            type: 'discharge-note',
            content: dischargeNote,
            id: dischargeNote.id,
          })
        }
      }
    } else if (dischargeNotesQuery.isError) {
      errors.push('discharge-note')
    }

    if (drugScreensQuery.data?.length) {
      if (appliedFilters.includes('drug-screen')) {
        for (const drugScreen of drugScreensQuery.data) {
          const datetime = drugScreen.administeredAt || drugScreen.createdAt

          add({
            datetime,
            type: 'drug-screen',
            content: drugScreen,
            id: drugScreen.oid,
          })
        }
      }
    } else if (drugScreensQuery.isError) {
      errors.push('drug-screen')
    }

    const pastJourney: JourneyGroup[] = createJourneyFromMap(pastJourneyMap)
    const futureJourney: JourneyGroup[] = createJourneyFromMap(futureJourneyMap)

    // Only add extra data if some of the main data is loaded
    if (pastJourney.length) {
      if (historicalChangesQuery.data?.length) {
        for (const historicalChange of historicalChangesQuery.data) {
          const datetime = historicalChange.changedAt
          pastJourney.push({
            datetime,
            type: 'patient-status',
            content: historicalChange,
            id: historicalChange.oid,
            status: historicalChange.value,
          })
        }
      } else if (historicalChangesQuery.isError) {
        errors.push('patient-status')
      }

      addYearDividers(pastJourney)
    }

    // Only add extra data if some of the main data is loaded
    if (futureJourney.length) {
      addYearDividers(futureJourney, true)
    }

    const sortedFutureJourney = sortJourney(futureJourney)
    const sortedPastJourney = sortJourney(pastJourney)

    return {
      futureJourney: sortedFutureJourney,
      pastJourney: sortedPastJourney,
      errors,
    }
  }, [
    historicalChangesQuery.data,
    historicalChangesQuery.isError,
    appliedFilters,
    appointmentsQuery.data,
    appointmentsQuery.isError,
    nonVisitEventsQuery.data,
    nonVisitEventsQuery.isError,
    caseReviewNotesQuery.data,
    caseReviewNotesQuery.isError,
    tasksQuery.data,
    tasksQuery.isError,
    prescriptionsQuery.data,
    prescriptionsQuery.isError,
    ineligibleNotesQuery.data,
    ineligibleNotesQuery.isError,
    dischargeNotesQuery.data,
    dischargeNotesQuery.isError,
    drugScreensQuery.data,
    drugScreensQuery.isError,
    visitHoldsQuery.data,
    visitHoldsQuery.isError,
  ])

  const { showBanner } = useBanner()
  const [showPrescriptionDrawer, setShowPrescriptionDrawer] = useState(false)
  const canPrescribe = isClinician(currentUser) || hasRole(currentUser, 'engineer')

  const mainFilters = ALL_FILTERS.filter(value => !value.includes('.'))
  const isAllFiltersOff = mainFilters.every(value => !appliedFilters.includes(value))

  return (
    <>
      <PrescriptionReorderDrawer
        patientId={patientId}
        opened={showPrescriptionDrawer}
        onClose={() => setShowPrescriptionDrawer(false)}
        setPrescriptionBanner={(message, type) => showBanner({ label: message, type })}
      />
      <Stack pt='md' sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
        <Stack px='md'>
          <DropInClinicBanner />
          <PatientJourneyError errors={errors} />
          <Group position='apart' spacing='sm'>
            <Box>
              {isLoading && (
                <Group spacing='sm' noWrap>
                  <Loader size='sm' />
                  <Text size='xs'>Loading patient care journey...</Text>
                </Group>
              )}
            </Box>
            <Group spacing='sm' noWrap>
              {canPrescribe && (
                <Tooltip
                  label={
                    hasRole(currentUser, ...PRESCRIPTION_QUEUER_ROLES)
                      ? 'Queue prescription'
                      : 'Send prescription'
                  }
                >
                  <SecondaryButton
                    leftIcon={<SendIcon />}
                    onClick={() => setShowPrescriptionDrawer(true)}
                  />
                </Tooltip>
              )}
              {!showFilters && (
                <Tooltip label='Filter'>
                  <Indicator
                    disabled={ALL_FILTERS.length === appliedFilters.length}
                    position='middle-end'
                    label={appliedFilters.length}
                  >
                    <SecondaryButton
                      leftIcon={<FilterIcon />}
                      onClick={() => toggleShowFilters()}
                    />
                  </Indicator>
                </Tooltip>
              )}
            </Group>
          </Group>
          <WinbackCallAlert
            patient={patientQuery?.data}
            tasks={tasksQuery.data}
            isTasksFetched={tasksQuery.isFetched}
          />
        </Stack>
        <Journey
          isAllFiltersOff={isAllFiltersOff}
          pastJourney={pastJourney}
          futureJourney={futureJourney}
        />
      </Stack>
      <FilterDrawer
        opened={showFilters}
        onClose={toggleShowFilters}
        appliedFilters={appliedFilters}
        setAppliedFilters={setAppliedFilters}
      />
    </>
  )
}

const Journey = ({
  pastJourney,
  futureJourney,
  isAllFiltersOff,
}: {
  pastJourney: JourneyGroup[]
  futureJourney: JourneyGroup[]
  isAllFiltersOff?: boolean
}) => {
  const animation = useLottieAnimation('spider-animation')

  if (!pastJourney.length && !futureJourney.length) {
    return (
      <Center
        mx='md'
        mb='md'
        sx={({ other: { colors }, radius }) => ({
          flex: 1,
          background: colors.background[1],
          borderRadius: radius.sm,
        })}
      >
        <Stack align='center'>
          {isAllFiltersOff && <Lottie style={{ height: 200 }} animationData={animation} />}
          <Pill variant='filled' status='none'>
            {isAllFiltersOff ? 'All filters off' : 'No results'}
          </Pill>
          <Text>
            {isAllFiltersOff
              ? 'Quick! Turn on some filters before the spider takes over your EMR... eek!'
              : 'Try turning on some additional filters'}
          </Text>
        </Stack>
      </Center>
    )
  }

  return (
    <ScrollArea px='md' constrict type='always'>
      <Stack pb='md'>
        {Boolean(futureJourney.length) && (
          <Timeline frameColor={({ background }) => background[3]}>
            {futureJourney.map((group, index) => {
              return (
                <JourneyGroup
                  index={index}
                  key={`${group.type}-${group.id}`}
                  group={group}
                  header={
                    index === 0 ? (
                      <Group spacing='xs' noWrap>
                        <RotateCwIcon />
                        <Text bold>Upcoming visits</Text>
                      </Group>
                    ) : undefined
                  }
                />
              )
            })}
          </Timeline>
        )}
        {Boolean(pastJourney.length) && (
          <Timeline>
            {pastJourney.map((group, index) => (
              <JourneyGroup index={index} key={`${group.type}-${group.id}`} group={group} />
            ))}
          </Timeline>
        )}
      </Stack>
    </ScrollArea>
  )
}

const FilterDrawer = ({
  opened,
  onClose,
  appliedFilters = [],
  setAppliedFilters,
}: {
  opened: boolean
  onClose: () => void
  appliedFilters: JourneyFilter[]
  setAppliedFilters: (value: (typeof ALL_FILTERS)[number][]) => void
}) => {
  const isAllFiltersApplied = ALL_FILTERS.every(filter =>
    appliedFilters.some(value => value === filter),
  )

  if (!opened) {
    return null
  }

  return (
    <>
      <Divider orientation='vertical' />

      <Stack spacing={0}>
        <Group m='md' noWrap position='apart' align='flex-start'>
          <TitleThree>Filters</TitleThree>
          <TertiaryButton onClick={onClose} leftIcon={<XIcon />} />
        </Group>
        <Divider />
        <ScrollArea miw='16rem'>
          <Stack m='md' spacing='sm'>
            <Switch
              size='sm'
              label='All'
              checked={isAllFiltersApplied}
              onClick={() => setAppliedFilters(isAllFiltersApplied ? [] : [...ALL_FILTERS])}
            />
            <Divider />
            <SwitchGroup
              spacing='sm'
              value={appliedFilters}
              onChange={value => setAppliedFilters(value as (typeof ALL_FILTERS)[number][])}
            >
              {ALL_FILTERS.map(value => {
                const isSubFilter = value.includes('.')
                const hidden =
                  isSubFilter && !appliedFilters.some(filter => filter === value.split('.')[0])

                if (hidden) {
                  return null
                }

                return (
                  <Switch
                    key={value}
                    value={value}
                    size='sm'
                    label={journeyLabels[value]}
                    ml={isSubFilter ? 'md' : 0}
                  />
                )
              })}
            </SwitchGroup>
          </Stack>
        </ScrollArea>
      </Stack>
    </>
  )
}
