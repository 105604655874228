import {
  hasGroupRole,
  hasRole,
  isAdmin,
  isCareCoordinator,
  isClinician,
  isCoordinator,
  isEnrollmentCoordinator,
} from '@shared/types'
import { useAuth } from '../../context/auth'

export const useAccess = () => {
  const { currentUser } = useAuth()

  const clinicianWorkingHours = isAdmin(currentUser)

  const consultationCallQueueWorkingHours =
    isAdmin(currentUser) || isEnrollmentCoordinator(currentUser)

  const completeWelcomeFlow = isAdmin(currentUser) || isEnrollmentCoordinator(currentUser)

  const dropInClinic =
    isAdmin(currentUser) || isClinician(currentUser) || isCareCoordinator(currentUser)

  const workingHours =
    isAdmin(currentUser) ||
    isEnrollmentCoordinator(currentUser) ||
    hasRole(currentUser, 'stn', 'tn', 'ncm_tn')

  const canScheduleVisitHolds =
    isClinician(currentUser) || isAdmin(currentUser) || isCareCoordinator(currentUser)

  const outboundSms =
    isAdmin(currentUser) || isCoordinator(currentUser) || hasRole(currentUser, 'tn', 'ncm_tn')

  const canCopyPatientZoomLink = isAdmin(currentUser) || isCoordinator(currentUser)

  /**
   *  @slaCountdownExperiment - 2024-08-09
   */
  const canSeeSlaCountdown = hasGroupRole(
    currentUser,
    'engineer',
    'leadEnrollmentCoordinator',
    'enrollmentCoordinator',
  )

  return {
    clinicianWorkingHours,
    completeWelcomeFlow,
    consultationCallQueueWorkingHours,
    dropInClinic,
    workingHours,
    canScheduleVisitHolds,
    outboundSms,
    canCopyPatientZoomLink,
    canSeeSlaCountdown,
  }
}
