import { Employee, hasEmployeeStatus } from '@shared/types'
import {
  GoogleAuthProvider,
  User,
  signInWithCredential,
  signInWithCustomToken,
} from 'firebase/auth'
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { firebaseAuthApi, lunafirebaseAuthApi } from '../api'
import { auth } from '../firebase/app'
import { useEmrQuery } from '../utils/hooks'

type SignInWithGoogleIdToken = (idToken: string) => Promise<void>
type Logout = () => Promise<void>
const SIGN_IN_TOKEN_PARAM_NAME = 'token'

const logOut: Logout = async () => {
  await auth.signOut()
  /**
   * After signout, reload the page so potentially stale clients
   * are forced to fetch the latest version of the app (ie, JS bundle)
   * before signing back in again on the same browser session.
   * The goal is to cut down on long running sessions of stale app code.
   */
  window.location.reload()
}

const signInWithGoogleIdToken: SignInWithGoogleIdToken = async token => {
  const credential = GoogleAuthProvider.credential(token)
  await signInWithCredential(auth, credential)
}

export type Context = {
  isLoading: boolean
  isAuthenticated: boolean
  isAuthorized: boolean
  logOut: Logout
  signInWithGoogleIdToken: SignInWithGoogleIdToken
  currentUser: Employee
  firebaseUser: User | null | undefined
}

const initialContext: Context = {
  isLoading: true,
  isAuthenticated: false,
  isAuthorized: false,
  currentUser: {} as Employee,
  logOut,
  signInWithGoogleIdToken,
  firebaseUser: null,
}

const AuthContext = createContext<Context>(initialContext)

export const AuthProvider = ({ children }: PropsWithChildren<unknown>) => {
  const queryClient = useQueryClient()
  const [searchParams] = useSearchParams()

  const [firebaseUser, setFirebaseUser] = useState<User | null>()
  const uid = firebaseUser?.uid || ''
  const isAuthenticated = Boolean(firebaseUser)
  const loginToken = searchParams.get(SIGN_IN_TOKEN_PARAM_NAME)
  const signInFirebaseAuth = useMutation(({ token }: { token: string }) =>
    signInWithCustomToken(auth, token),
  )
  const navigate = useNavigate()
  const isProdOrigin = window.location.origin === 'https://emr.ophelia.com'

  useEffect(() => {
    // No reason to use custom sign in token at the production origin
    if (isProdOrigin) {
      return
    }

    if (signInFirebaseAuth.isLoading) {
      return
    }

    if (loginToken) {
      // Remove token search param and update URL
      searchParams.delete(SIGN_IN_TOKEN_PARAM_NAME)
      navigate(`/login?${searchParams.toString()}`)
      signInFirebaseAuth.mutate({ token: loginToken })
    }
  }, [navigate, loginToken, searchParams, signInFirebaseAuth, isProdOrigin])

  const employeeQuery = useEmrQuery(
    'GET /employee/:employeeId',
    { params: { employeeId: uid } },
    { enabled: isAuthenticated },
  )

  const currentUser = employeeQuery.data

  const hasEmployeeDoc = Boolean(currentUser)
  const isAuthorized = hasEmployeeDoc && hasEmployeeStatus(currentUser, 'currentEmployee')

  const isLoading = firebaseUser === undefined || employeeQuery.isLoading

  const signIn = useCallback<SignInWithGoogleIdToken>(async idToken => {
    await signInWithGoogleIdToken(idToken)
  }, [])

  // Enusre that the user is fundamentally auth'd
  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged(user => {
      if (user) {
        setFirebaseUser(user)
        firebaseAuthApi.setUser(user)
        lunafirebaseAuthApi.setUser(user)
      } else {
        setFirebaseUser(null)
        firebaseAuthApi.setUser(null)
        lunafirebaseAuthApi.setUser(null)
        queryClient.clear()
      }
    })

    return () => {
      unsubscribe()
    }
  }, [])

  const context = {
    isLoading,
    isAuthenticated,
    isAuthorized,
    currentUser: currentUser || ({} as Employee),
    firebaseUser,
    logOut,
    signInWithGoogleIdToken: signIn,
  }

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
