import {
  CheckCircleIcon,
  ClockIcon,
  DeleteIcon,
  ExternalLinkText,
  Group,
  LayersIcon,
  PaperClipIcon,
  PhoneOutgoingIcon,
  Pill,
  RefreshIcon,
  SecondaryButton,
  SendIcon,
  ShoppingBagIcon,
  SlashIcon,
  TertiaryButton,
  Text,
  TimelineCard,
  TimelineCardTitle,
  Tooltip,
  useBanner,
} from '@shared/components'
import { DispenseUnitType, hasRole, readablePrescriptionStatusMap } from '@shared/types'
import { dayjs, phone } from '@shared/utils'
import startCase from 'lodash/startCase'
import { useState } from 'react'
import { Config } from '../../../../config'
import { useAuth } from '../../../../context/auth'
import { useGetPharmacy } from '../../../../utils/hooks/use-get-pharmacy'
import {
  PRESCRIPTION_QUEUER_ROLES,
  canDeletePrescription,
  canReOrderPrescription,
  canSendPrescription,
} from '../../../../utils/prescriptionUtils'
import { JourneyItemContent, usePatient } from '../../PPatientContext'
import { PrescriptionDeleteModal } from '../../PrescriptionDeleteModal'
import { PrescriptionDetailDrawer } from '../../PrescriptionDetailDrawer'
import { PrescriptionReviewSendDrawer } from '../../PrescriptionReviewSendModal'
import { PrescriptionReorderDrawer } from '../../prescriptions/PrescriptionReorderDrawer'
import { FieldSkeleton, FooterContentSection } from './SharedContent'

export const StatusIcon = ({
  status,
  prescription_queued_by,
  withColor,
}: JourneyItemContent<'prescription'> & { withColor?: boolean }) => {
  switch (status) {
    case 'Entered':
    case 'Edited':
    case 'Queued':
      return (
        <Tooltip label={`Queued by ${prescription_queued_by}`}>
          <PaperClipIcon />
        </Tooltip>
      )
    case 'Cancelled':
      return (
        <Tooltip label={readablePrescriptionStatusMap[status]}>
          <SlashIcon color={colors => (withColor ? colors.error[0] : undefined)} />
        </Tooltip>
      )
    case 'Cancel Denied':
    case 'EPCS Error':
    case 'EpcsError':
    case 'Error':
      return (
        <Tooltip label={readablePrescriptionStatusMap[status]}>
          <SlashIcon color={colors => (withColor ? colors.error[0] : undefined)} />
        </Tooltip>
      )
    case 'Cancel Requested':
    case 'Cancel Pending':
    case 'eRxSent':
    case 'EpcsSigned':
    case 'EPCS Signed':
      return (
        <Tooltip label={readablePrescriptionStatusMap[status]}>
          <SendIcon color={colors => (withColor ? colors.warning[0] : undefined)} />
        </Tooltip>
      )
    case 'Completed':
    case 'Pharmacy Verified':
    case 'PharmacyVerified':
      return (
        <Tooltip label={readablePrescriptionStatusMap[status]}>
          <CheckCircleIcon color={colors => (withColor ? colors.success[0] : undefined)} />
        </Tooltip>
      )
    case 'FaxSent':
    case 'Printed':
    case 'Ready to Sign':
    case 'ReadyToSign':
    case 'Requested':
    case 'Sending':
      return (
        <Tooltip label={readablePrescriptionStatusMap[status]}>
          <SendIcon color={colors => (withColor ? colors.warning[0] : undefined)} />
        </Tooltip>
      )
    case 'Deleted':
      return (
        <Tooltip label={readablePrescriptionStatusMap[status]}>
          <SlashIcon color={colors => (withColor ? colors.error[0] : undefined)} />
        </Tooltip>
      )
    case undefined:
      return null
    default:
      return null
  }
}

export const PrescriptionContent = ({
  prescription,
}: {
  prescription: JourneyItemContent<'prescription'>
}) => {
  const { currentUser } = useAuth()
  const { patientQuery } = usePatient()
  const datetime = dayjs(`${prescription.timestamp}Z`)
  const { patientId } = usePatient()
  const [modal, setModal] = useState<'cancel' | 'details' | 'reorder' | 'send' | null>(null)
  const { showBanner } = useBanner()

  const medicationQuantityString =
    prescription.medication_quantity && prescription.dispense_unit_id
      ? `${prescription.medication_quantity} (${DispenseUnitType[prescription.dispense_unit_id]})`
      : ''

  /*
   * @dosespotV2Migration
   * Need to send the patientId so that we know whether to use the v1 or v2 API
   */
  const { getPharmacyQuery } = useGetPharmacy({ pharmacyId: prescription.pharmacyId, patientId })
  const pharmacyName = getPharmacyQuery.data?.StoreName
  const pharmacyAddress = startCase(getPharmacyQuery.data?.Address1.toLowerCase())
  const pharmacyCity = startCase(getPharmacyQuery.data?.City.toLowerCase())
  const pharmacyState = getPharmacyQuery.data?.State
  const pharmacyZipcode = getPharmacyQuery.data?.ZipCode.substring(0, 5)
  const pharmacyPhone = getPharmacyQuery.data?.PrimaryPhone
  const isInIframe = window !== window.parent

  const twilioLink = new URL(`${Config.TWILIO_FLEX_URL}/conversations`)
  twilioLink.searchParams.append('pharmacyName', pharmacyName ?? '')
  twilioLink.searchParams.append('pharmacyPhone', phone(pharmacyPhone).normalized)
  twilioLink.searchParams.append(
    'phone',
    phone(patientQuery?.data?.personalData.phone ?? pharmacyPhone).normalized,
  )

  const onClose = () => setModal(null)

  return (
    <TimelineCard
      leftSide={
        <TimelineCardTitle
          title={prescription.medication_name.replace(prescription.medication_strength, '').trim()}
        >
          <StatusIcon {...prescription} />
        </TimelineCardTitle>
      }
      styles={({ background, warning }) => ({
        backgroundColor: background[2],
        borderColor: prescription.status === 'Queued' ? warning[0] : undefined,
      })}
      rightSide={
        <Group spacing='sm'>
          {prescription.type === 'bridge' && (
            <Pill variant='filled' status='warning'>
              Bridge
            </Pill>
          )}
        </Group>
      }
    >
      <Text>
        {`${prescription.medication_strength} | ${prescription.medication_days_supply} days | ${medicationQuantityString} | ${prescription.refills} refills`}
      </Text>
      <FooterContentSection
        right={
          <Group noWrap>
            {pharmacyPhone && (
              <Tooltip label={phone(pharmacyPhone).arealessFormat}>
                <ExternalLinkText
                  onClick={() => {
                    if (isInIframe) {
                      window.parent.postMessage(
                        {
                          type: 'call_pharmacy',
                          phone: phone(pharmacyPhone).normalized,
                          pharmacyName,
                        },
                        Config.TWILIO_FLEX_URL,
                      )
                    }
                  }}
                  href={isInIframe ? undefined : twilioLink.href}
                >
                  <TertiaryButton leftIcon={<PhoneOutgoingIcon />}>Call pharmacy</TertiaryButton>
                </ExternalLinkText>
              </Tooltip>
            )}
            {canDeletePrescription(prescription, currentUser) && (
              <TertiaryButton onClick={() => setModal('cancel')} leftIcon={<DeleteIcon />}>
                Cancel
              </TertiaryButton>
            )}
            <TertiaryButton
              disabled={!prescription.prescription_id}
              onClick={() => setModal('details')}
              leftIcon={<LayersIcon />}
            >
              Details
            </TertiaryButton>
            {canReOrderPrescription(prescription, currentUser) && (
              <SecondaryButton
                size='xs'
                onClick={() => setModal('reorder')}
                leftIcon={<RefreshIcon color={({ actions }) => actions[0]} />}
              >
                Re-order
              </SecondaryButton>
            )}
            {canSendPrescription(prescription, currentUser) && (
              <SecondaryButton
                size='xs'
                onClick={() => setModal('send')}
                leftIcon={<SendIcon color={({ actions }) => actions[0]} />}
              >
                {hasRole(currentUser, ...PRESCRIPTION_QUEUER_ROLES) ? 'Edit' : 'Review & send'}
              </SecondaryButton>
            )}
          </Group>
        }
      >
        <FooterContentSection.Item>
          <ClockIcon color={({ text }) => text[1]} />
          <Text style={{ whiteSpace: 'nowrap' }} size='xs' color={({ text }) => text[0]}>
            {dayjs(datetime).format('h:mma z')}
          </Text>
        </FooterContentSection.Item>
        {Boolean(prescription.pharmacyId) && (
          <FooterContentSection.Item>
            <ShoppingBagIcon color={({ text }) => text[1]} />
            <FieldSkeleton isLoading={getPharmacyQuery.isLoading} isError={false}>
              <Tooltip
                disabled={getPharmacyQuery.isError}
                label={`${pharmacyAddress}, ${pharmacyCity}, ${pharmacyState} ${pharmacyZipcode}`}
              >
                <Text style={{ whiteSpace: 'nowrap' }} size='xs' color={({ text }) => text[0]}>
                  {getPharmacyQuery.isError ? `Pharmacy #${prescription.pharmacyId}` : pharmacyName}
                </Text>
              </Tooltip>
            </FieldSkeleton>
          </FooterContentSection.Item>
        )}
      </FooterContentSection>
      <PrescriptionDeleteModal
        onClose={onClose}
        opened={modal === 'cancel'}
        patientId={patientId}
        prescriptionId={prescription.prescription_id ?? 0}
        setPrescriptionBanner={(message, type) =>
          showBanner({ type, label: message, dismissable: true })
        }
      />
      <PrescriptionDetailDrawer
        onClose={onClose}
        opened={modal === 'details'}
        patientId={patientId}
        prescriptionId={prescription.prescription_id ?? 0}
        setPrescriptionBanner={(message, type) =>
          showBanner({ type, label: message, dismissable: true })
        }
      />
      <PrescriptionReorderDrawer
        patientId={patientId}
        onClose={onClose}
        opened={modal === 'reorder'}
        prescription={prescription}
        setPrescriptionBanner={(message, type) =>
          showBanner({ type, label: message, dismissable: true })
        }
      />
      <PrescriptionReviewSendDrawer
        onClose={onClose}
        opened={modal === 'send'}
        prescriptionId={prescription.prescription_id ?? 0}
        setPrescriptionBanner={(message, type) =>
          showBanner({ type, label: message, dismissable: true })
        }
        editOnly={hasRole(currentUser, ...PRESCRIPTION_QUEUER_ROLES)}
        patientId={patientId}
      />
    </TimelineCard>
  )
}
