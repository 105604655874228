import { Stack, Text, Textarea, TextareaProps } from '@shared/components'

export type VisitNoteTextareaProps = TextareaProps & { isEditing: boolean }

export const VisitNoteTextarea = ({
  isEditing,
  autosize = true,
  ...props
}: VisitNoteTextareaProps) => {
  if (isEditing) {
    return <Textarea autosize={autosize} minRows={5} maxRows={32} {...props} />
  }

  return (
    <Stack spacing='xs' sx={{ overflowWrap: 'anywhere', whiteSpace: 'break-spaces' }}>
      {props.label && <Text color={colors => colors.text[1]}>{props.label}</Text>}
      <Text component='p'>{props.value}</Text>
    </Stack>
  )
}
