import { hasGroupRole, hasRole } from '@shared/types'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/auth'
import HeaderTab from '../care_team/irq/HeaderTab'

export type BillingHeaderContentProps = {
  selectedPage: 'Insurance review' | 'Encounters' | 'Insurance matching' | 'Bundle configuration'
}

const BillingHeaderContent = ({ selectedPage }: BillingHeaderContentProps) => {
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  return (
    <div className='hidden sm:ml-6 sm:flex sm:space-x-6'>
      <HeaderTab
        onClick={() => navigate('/billing/irq')}
        selected={selectedPage === 'Insurance review'}
        label='Insurance review'
      />
      {hasGroupRole(currentUser, 'admin') && (
        <HeaderTab
          onClick={() => navigate('/billing/encounters/unsubmitted')}
          selected={selectedPage === 'Encounters'}
          label='Encounters'
        />
      )}
      {hasRole(currentUser, 'financialCounselor', 'admin', 'engineer') && (
        <HeaderTab
          onClick={() => navigate('/billing/payer-configs')}
          selected={selectedPage === 'Bundle configuration'}
          label='Bundle configuration'
        />
      )}
      {hasGroupRole(currentUser, 'admin') && (
        <HeaderTab
          onClick={() => navigate('/billing/insurance-matching')}
          selected={selectedPage === 'Insurance matching'}
          label='Insurance matching'
        />
      )}
    </div>
  )
}

export default BillingHeaderContent
