import * as FullStory from '@fullstory/browser'
import { Config } from '../config'

export const { setUserVars, identify, log, getCurrentSessionURL } = FullStory

export type LogLevel = 'log' | 'info' | 'warn' | 'error' | 'debug'

export const init = () => {
  FullStory.init({
    orgId: Config.FULLSTORY_ORG_ID,
    namespace: 'EMR',
    devMode: Config.ENV !== 'production',
  })

  setUserVars({
    environment: Config.ENV,
  })
}

type FullStoryEventName =
  | 'Idle User Logged Out'
  | 'Consultation Call or Intake Visit Note Signed'
  | 'EMR ErrorBoundary Rendered'
  | 'Viewed case review note'
  | 'Case review recommendation shown from visit note'
  | 'Case review recommendation shown from sign and lock'
  | 'Care Pathway Submitted'
  | 'EMR Call Ringing'
  | 'EMR Call Accepted'
  | 'EMR Call Ended'
  | 'EMR Call Canceled'
  | 'Clinician Pulled Appointment Forward'
  | 'Clinician Selected To Pull Appointment Forward'
  | 'Clinician Pushed Appointment Back'
  | 'Clinician Selected To Push Appointment Back'
  | 'Clinician Pulling Appointment Forward With Insufficient Time'
  | 'Drop In Clinic Banner Shown'
  | 'Delayed scheduling patient'
  | 'Clinician is over capacity'

export const event = (
  eventName: FullStoryEventName,
  eventProperties: { [key: string]: string | number } = {},
) => {
  FullStory.event(eventName, eventProperties)
}

export const page = (props?: { [key: string]: string | number }) => {
  FullStory.setVars('page', props)
}
