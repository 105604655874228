import { Alert, AlertIcon } from '@shared/components'
import { dayjs, template } from '@shared/utils'
import { useLunaQuery } from '../../utils/hooks'
import { usePatient } from './PPatientContext'

export const DropInClinicBanner = () => {
  const { patientQuery } = usePatient()

  const nextDropInClinic = patientQuery?.data?.nextDropInClinic

  const clinicDetailsQuery = useLunaQuery(
    'GET /drop-in-clinics/:clinicId',
    {
      params: {
        clinicId: nextDropInClinic?.clinicId || '',
      },
    },
    {
      enabled: Boolean(nextDropInClinic?.clinicId),
    },
  )

  const clinic = clinicDetailsQuery.data?.data

  if (!clinic) {
    return null
  }

  const isConfirmed = nextDropInClinic?.confirmedAt
  const isUpcoming = dayjs(clinic?.datetimeEnd).isAfter(dayjs())

  const patientName = patientQuery?.data?.personalData.firstName
  const dayOfWeek = dayjs(clinic.datetimeStart)
    .tz('America/New_York')
    .format('MMM D YYYY')
    .toUpperCase()
  const startHour = dayjs(clinic.datetimeStart).tz('America/New_York').format('h:mma')
  const endHour = dayjs(clinic.datetimeEnd).tz('America/New_York').format('h:mma')
  const timezone = dayjs(clinic.datetimeEnd).tz(clinic.timezone).format('z')

  if (clinic && isUpcoming && !isConfirmed) {
    const upcomingMessage = template(
      '{patientName} is eligible for the drop-in clinic on {dayOfWeek} between {startHour}-{endHour} {timezone}. They must still confirm their attendance.',
      {
        patientName,
        dayOfWeek,
        startHour,
        endHour,
        timezone,
      },
    )

    return (
      <Alert icon={<AlertIcon />} variant='warning'>
        {upcomingMessage}
      </Alert>
    )
  }

  if (clinic && isUpcoming && isConfirmed) {
    const confirmedMessage = template(
      '{patientName} has confirmed their attendance for the drop-in clinic on {dayOfWeek} between {startHour}-{endHour} {timezone}.',
      {
        patientName,
        dayOfWeek,
        startHour,
        endHour,
        timezone,
      },
    )

    return (
      <Alert icon={<AlertIcon />} variant='warning'>
        {confirmedMessage}
      </Alert>
    )
  }

  return null
}
