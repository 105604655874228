import { EncounterStatuses, hasGroupRole } from '@shared/types'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useAuth } from '../../context/auth'
import PIRQ from '../care_team/irq/PIRQ'
import BillingEncounters from './encounters/BillingEncounters'
import BillingInsuranceMatching from './insurance/BillingInsuranceMatching'
import { PayerConfigs } from './payerConfigs'

const PBilling = () => {
  const { currentUser } = useAuth()

  const defaultPage = hasGroupRole(currentUser, 'enrollmentCoordinator')
    ? '/billing/irq'
    : `/billing/encounters/${EncounterStatuses.Unsubmitted}`

  return (
    <Routes>
      <Route path='/irq' element={<PIRQ />} />
      <Route path='/encounters/:status/:encounterId' element={<BillingEncounters />} />
      <Route path='/encounters/:status' element={<BillingEncounters />} />
      <Route path='/payer-configs' element={<PayerConfigs />} />
      <Route path='/insurance-matching' element={<BillingInsuranceMatching />} />
      <Route path='*' element={<Navigate replace to={defaultPage} />} />
    </Routes>
  )
}

export default PBilling
