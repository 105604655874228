import { createFormContext } from '@mantine/form'
import { Employee, EmrApi, EMRTaskPod, hasGroupRole, hasRole } from '@shared/types'

export type PodFilterValue = EMRTaskPod | '' | 'assigned_to_me' | 'opened_by_me'

const POD_FILTER_OPTIONS: { value: PodFilterValue; label: string }[] = [
  // An empty string is used to represent the 'View all' option
  { value: '', label: 'View all' },
  { value: 'assigned_to_me', label: 'View assigned to me' },
  { value: 'opened_by_me', label: 'View opened by me' },

  { value: 'pes', label: 'View PES' },
  { value: 'pss', label: 'View PSS' },
  { value: 'ec', label: 'View EC' },
  { value: 'lec', label: 'View lead EC' },
  { value: 'fc', label: 'View FC' },
  { value: 'lcc', label: 'View LCC' },
  { value: 'scc', label: 'View SCC' },
  { value: 'tn', label: 'View triage nurses' },
  { value: 'rn', label: 'View resource navigation' },
  { value: 'legal', label: 'View legal' },
  { value: 'engineer', label: 'View engineering' },
  { value: 'ocp', label: 'View OCP' },
]

export const getPodFilterOptions = () => {
  return POD_FILTER_OPTIONS
}

type TaskFilters = EmrApi['GET /tasks/v2']['req']['query']
type FrontendTaskFilters = Omit<TaskFilters, 'pod'> & { pod: PodFilterValue }

export type OrderBy = {
  orderBy: TaskFilters['orderBy']
  orderByDirection: TaskFilters['orderByDirection']
}

export const getDefaultPod = (employee: Employee): PodFilterValue => {
  if (employee.role === 'financialCounselor') {
    return 'fc'
  }
  if (hasRole(employee, 'stn', 'tn', 'ncm_tn')) {
    return 'tn'
  }
  if (hasGroupRole(employee, 'enrollmentCoordinator')) {
    return 'assigned_to_me'
  }
  if (hasGroupRole(employee, 'patientEngagementSpecialist')) {
    return 'pes'
  }
  if (hasGroupRole(employee, 'patientSupportSpecialist')) {
    return 'pss'
  }
  if (hasGroupRole(employee, 'engineer')) {
    return 'engineer'
  }
  // Clinicians should see tasks 'Assigned to me' by default
  if (hasGroupRole(employee, 'clinician')) {
    return 'assigned_to_me'
  }
  return ''
}

export const getInitialFilters = (
  filters: Partial<FrontendTaskFilters>,
): Partial<FrontendTaskFilters> => ({
  employeeId: undefined,
  endBefore: undefined,
  startAfter: undefined,
  orderBy: 'priority',
  orderByDirection: 'desc',
  types: undefined,
  ...filters,
})

export const [FiltersFormProvider, useFiltersFormContext, useFiltersForm] =
  createFormContext<Partial<FrontendTaskFilters>>()
